.opp_main_section_cont{
    display: flex;
    justify-content: space-between;

}

.opp_filters_comp{
    width: 330px;
}

.opp_main_sec{
    margin-inline: auto;
    width: 70%;
    padding: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.opp_filters_comp_resp, .filter_title_resp{
    display: none;
}

@media only screen and (max-width: 768px)
{
    .filter_title_resp{
        display: block !important;
    }
.opp_filters_comp_resp{
    display: block !important;
    width: 330px;
    position: absolute;
}

.opp_filters_comp{
    display: none;
}

.filter_title_resp{
    font-family: bold;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    padding: 15px;
    position: absolute;
}

.filters_close{
    position: absolute;
    display: block;
    color: var(--primary);
    display: flex;
    align-items: center;
    cursor: pointer;
right: 0.5rem;
top: 8px !important;
}

.filters_header{
    margin-top: 2rem;
}

.opp_main_sec{
    margin-top: 3rem;
}

}

@media only screen and (max-width: 440px)
{
    .opp_main_sec{
        width: 95%;
    }
    .opp_card_main_cont{

        width: 100%;
    }.upper_section_title{
    font-size: 2rem;
}
}


@media only screen and (max-width: 330px)
{
.opp_filters_comp_resp{
    width: 100%;
}

.opp_card_main_cont{
    padding: 0 20px 10px 20px;
}
.opp_card_header_cont{
    align-items: center;
flex-direction: column;
}
.opp_card_header_title{
    width: fit-content;
    text-align: center;
}



.upper_section_sub{
    font-size: 0.9rem;
}
}