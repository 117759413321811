.step1_cont_header {
    font-size: 1rem;
}

.create_opp_step1_cont {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-family: bold;


}

.text_notes {
    color: var(--pink);

}

.create_opp_input {
    font-family: regular;
}

.create_opp_step1 .modal .modal-content {
    width: 40%;
    padding: 20px;
}

.create_opp_step1 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.create_opp_step1_cont1 {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.create_opp_body_step {
    display: flex;
    justify-content: space-between;
    min-height: 350px;
    width: 90%;
    margin-bottom: 2rem;

}

.create_opp_note {
    color: var(--primary);
    font-size: 0.8rem;
    margin-top: 5px;
    cursor: pointer;
    font-family: regular;

}

.create_opp_radio_main {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.create_opp_radio_main input[type="radio"] {
    cursor: pointer;

    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;

}

.create_opp_radio_main input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--primary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;

}

.create_opp_radio_comp {
    display: flex;
}

.create_opp_radio_label {
    margin-top: -6px;
    margin-inline: 0.5rem;
    font-family: regular;
}