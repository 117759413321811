.donate_main_con .modal .modal-content {
    width: 700px;
    padding: 0;
}

.donate_main_con .modal .modal-content .close {
    margin-right: 20px;
}

.donate_pop_cont {
    position: relative;
    width: 100%;
    background-color: white;
    padding: 5px 25px 25px 25px;
    z-index: 1;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    min-height: 300px;
}

.donate_popup_header {
    font-family: bold;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
}

.donate_popup_body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.amount_input {
    width: 100%;
}

.currency_dropdown {
    background-color: white;
    border: 2px solid var(--border);
    border-radius: 10px;
    padding: 6px 15px;
    margin-bottom: 1rem;
    width: 100%;
}

.donate_footer_slider {
    z-index: 0;
    height: 20px;
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
}


.donate_button_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.donate_button_acess {
    background-color: var(--primary);
    color: white;
    border-radius: 8px;
    padding: 10px;
    font-size: 1.1rem;
    font-family: var(--bold);
    margin-top: 1rem;
    border: none;
    cursor: pointer;


}

.confirm_div {
    color: var(--pink);
    margin-right: 1rem;
}

.confirm_main_div {
    display: flex;
    align-items: center;
}

.donate_failed_img {
    display: flex;
    justify-content: center;
}

.sad_img {
    width: 120px;
}

@media only screen and (max-width: 700px) {
    .donate_main_con .modal .modal-content {
        width: 90%;
    }
}