.complete_step6_org_all_avail input[type="radio"] {
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}


.complete_step6_org_all_avail input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--primary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
.inner {
  display: flex;
  gap:0.5rem
}
.complete_step6_org_all_avail {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
}
