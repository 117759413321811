.vol-manager-title {
  padding-top: 8rem !important;
  font-family: bold;
  font-weight: strong;
  font-size: 3rem;
  width: fit-content;
  margin: auto;
  text-align: center;
}

.video-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.vol-manager-container {
  width: 100%;
}

.title-2 {
  margin-top: 5rem;
  font-family: bold;
  font-weight: strong;
  font-size: 2rem;
  text-align: center;
}

.help-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: auto;
}

.help-cont p {
  opacity: 0.75;
}

.pink-hr {
  width: 6rem;
  background-color: var(--pink);
  height: 6px;
  border-radius: 8px;
  border: none;
}

.vol-manager-container {
  background-image: url("../../assets/images/mayor_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.vol-manager-video {
  width: 60%;
  margin-top: 3rem;
  border-radius: 12px;
}

@media only Screen and (max-width: 768px) {
  .vol-manager-video {
    width: 85%;
  }

  .help-cont {
    flex-direction: column;
  }
}