.blogs_container{
    width: 75%;
    margin: auto;
    margin-top: 4rem;

    display: flex;
    justify-content: space-between;
    gap: 2rem 1rem;
    flex-wrap: wrap;
}

.vol_spotlight_container{
    width: 75%;
    margin: auto;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem 1rem;
    flex-wrap: wrap;
}
.blog_videos_container{
    width: 75%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
}
.blog_video{
    display: flex;
    flex-direction: row;
    width: 49%;
}
.blogs_recent{
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.blog_main_page{
    width: 60%;
    margin: auto;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.blog_main_header{
    font-family: bold;
    width: fit-content;

}

.blog_divider{
    margin-top: -10px;
    border-bottom: 5px solid var(--pink);
    border-radius: 10px;
    width: 100px;
    margin-bottom: 2rem;
    
}

.spot_header{
    width: 90%;
    margin: auto;
    font-family: bold;
}
.spotlight_list{
    margin:auto;
    width: 90%;
}

.blogs_media_video{
    margin-top: 6rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
   opacity: 1;
   box-shadow: 0px 0px 20px #72C09540 !important;
}
.blog_video{
    flex-direction: column;
    position: relative;
    height:300px;
}

.video_title{
    margin: 10px 0px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: var(--px-18);
}
.home_VideoPlayButton{
    position: absolute;
    width: 100px;
    height:100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%)
}
@media (max-width: 770px) {
    .blog_videos_container{
        flex-direction : column
    }
    .blog_video{
        width:100% !important
    }
    .blogs_recent{
        flex-direction: column;
    }
}