.mayor_impact_main_cont {
  width: 100%;
  background-image: url("../../assets/images/mayor_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.mayor_impact_title {
  padding-top: 8rem !important;
  font-family: bold;
  font-weight: strong;

  font-size: 3rem;
  width: 50%;
  margin: auto;
  text-align: center;
}

.mayor_impact_1st_cont {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: auto;

}

.mayor_impact_img {
  width: 85%;
}

/* .font_arabic{
    font-size: 3rem !important;
    font-weight: 900;
  } */

.mayor_impact_button1_pink {
  background-color: var(--pink);
  color: white;
  border: none;
  outline: none;
  padding: 15px 25px;
  border-radius: 10px;
  position: absolute;
  top: 88%;
  font-family: bold;
  font-size: 1.5rem;
  cursor: pointer;
}

.mayor_impact_button_pink {
  background-color: var(--pink);
  color: white;
  border: none;
  outline: none;
  padding: 15px 25px;
  border-radius: 10px;
  font-family: regular;
  font-size: 1.1rem;
  margin-top: 2rem;
  cursor: pointer;
}

.mayor_impact_2nd_main_cont {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.mayor_impact_2nd_container {
  background-color: white;
  padding: 30px 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.08) !important;
  width: 330px;
  border-radius: 15px;
  flex-wrap: wrap;
  margin: 10rem 10px 0 10px;
}

.mayor_2nd_cont_img {
  margin-bottom: 2rem;
  width: 35%;
  height: 100px;
}

.mayor_impact_2nd_cont_text {
  font-family: regular;
  text-align: center;
}

.mayor_impact_3rd_main_cont,
.mayor_impact_4th_main_cont {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mayor_impact_header2 {
  width: fit-content;
  margin: auto;
  font-family: bold;
  font-size: 2.5rem;
  width: 60%;
  margin: auto;
  text-align: center;
}

.mayor_underline {
  width: 180px;
  margin: auto;
  border-bottom: 10px solid var(--pink);
  border-radius: 20px;
}

.mayor_impact_3rd_sub {
  font-size: 1rem;
  font-family: regular;
  width: 50%;
  margin: auto;
  margin-top: 1.5rem;
  text-align: center;
}

.mayor_impact_image_cont {
  margin-top: 2.5rem;

  justify-content: space-between;
  display: flex;
  width: 65%;
}

.mayor_impact_4th_image {
  width: 250px;
}


@media only screen and (max-width: 1080px) {
  .mayor_impact_title {
    font-size: 2.2rem;
  }

  .mayor_impact_header2 {
    font-size: 1.8rem;
  }

  .mayor_impact_4th_image {
    width: 180px;

  }
}

@media only screen and (max-width: 850px) {

  .mayor_impact_4th_image {
    width: 130px;

  }

  .mayor_impact_2nd_main_cont {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
  }

  .mayor_impact_2nd_container {
    margin: 20px;
  }
}


@media only screen and (max-width: 620px) {
  .mayor_impact_title {
    font-size: 1.5rem;
  }

  .mayor_impact_button1_pink {
    font-size: 1rem;
  }

  .mayor_impact_header2 {
    font-size: 1.5rem;
  }

  .mayor_impact_4th_image {
    margin-bottom: 20px;

  }

  .mayor_impact_image_cont {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .mayor_impact_2nd_cont_text {
    font-size: 1rem;

  }

  .mayor_impact_button_pink {
    font-size: 1rem;
  }

  .mayor_2nd_cont_img {

    width: 20%;
    margin-bottom: 1rem;
    height:auto !important

  }


  .mayor_impact_img {
    width: 100%;
  }

  .mayor_impact_button1_pink,
  .mayor_impact_button_pink {
    padding: 9px 15px;
  }

}

.mayor_impact_title_arabic {
  font-size: 3rem !important;
  font-weight: bold;
}

.mayor_impact_2nd_cont_text_arabic {
  font-size: 1.3rem !important;
}

.mayor_impact_header2_arabic {
  font-size: 3rem !important;
  font-weight: bold;
}

.mayor_impact_3rd_sub_arabic {
  font-size: 1.5rem !important;
  width: 65%;
}