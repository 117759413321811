.blog_green_header_container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 40px 0px;
    align-items: center;
}
.my_opp_main_cont{
    margin: auto;
    width: 90%;
}
.opp_main_cont{
    margin: auto;
}

.blog_green_header_container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 40px 0px;
    align-items: center;
}

.blog_green_header .dotter_spacer {
    display: none
}

.opportunities_title {
    text-align: center;
}

.disabled-header-button {
    color: var(--grey);
}

.disabled-header-button:hover {
    color: var(--white);
}

.my_opportunities_status_container,
.my_opportunities_status_container_ar {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding-top: 30px;
}

.my_opportunities_container {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding-top: 30px;
}

.opportunities_status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem
}

.opportunities_status_btn {
    background-color: white;
    color: black;
    border: 1px solid var(--primary) ;
    outline: none;
    cursor: pointer;
    padding: 0.2rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 30px;
    margin-top: 1.5rem;
}

.opportunities_status_btn_active {
    background-color: var(--primary)  ;
    color:  white;
    border:  none !important;
}

.all_opportunities {
    margin-inline: auto;
    width: 90% !important;
    padding: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

}


@media (max-width: 768px) {
    .blog_green_header .dotter_spacer {
        display: block;
        border-bottom: dotted 5px var(--grey);
        margin: 1rem 0;
        width: 100%
    }

    .opportunities_status {
        flex-direction: column;
    }

    .opp_card_main_cont .grey_vertical_border {
        display: inline;
    }

    .opportunities_status_btn {
        margin-top: 0.5rem;
    }

    .my_opportunities_status_container,
    .my_opportunities_status_container_ar {
        width: 70%
    }
}

@media (max-width: 900px) {
    .my_opp_main_cont .modal-content{
        width: 80%;
    }
}