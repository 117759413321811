.apply_main_con .modal .modal-content {
    width: 700px;
    padding: 0;
}

.apply_main_con .modal .modal-content .close {
    margin-right: 20px;
}

.apply_pop_cont {
    position: relative;
    width: 100%;
    background-color: white;
    padding: 5px 50px 25px 50px;
    z-index: 1;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

.apply_header {
    font-family: bold;
    font-size: 2rem;
}

.apply_subtitle {
    margin-top: -0.2rem;
    font-size: 1rem;
}

.apply_footer_slider {
    height: 20px;
    background-color: var(--border);
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
  }

  .apply_footer_slider_green{
    height: 100%;
    background-color: var(--primary);
    position: relative;
    border-bottom-left-radius: 8px;
  }
  
  .apply_footer_slider_green_ar{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 8px;
  
  
  }
  
.css-26l3qy-menu{
    margin-top: -1rem !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    z-index: 3 !important;
  }

  .apply_main_con .modal .modal-content {
    width: 700px;
    padding: 0;
}

@media (max-width: 786px) {
  .apply_main_con .modal .modal-content {
    width: 80% !important;
}
.apply_pop_cont {
  padding: 5px 20px 25px 20px;
}


}
