.master_cl__main_con .modal .modal-content {
    width: 700px;
    padding: 0;
}

.master_cl__main_con .modal .modal-content .close {
    margin-right: 20px;
}

.master_cl__pop_cont {
    position: relative;
    width: 100%;
    background-color: white;
    padding: 5px 25px 25px 25px;
    z-index: 1;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    min-height: 300px;
}

.master_cl__footer_slider {
    z-index: 0;
    height: 20px;
    background-color: var(--border);
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
}

.master_cl__header {
    font-family: bold;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
}

.master_cl__subtitle {
    margin-top: -0.2rem;
    font-size: 1rem;
}

.master_cl__input {
    background-color: white;
    border: 2px solid var(--border);
    border-radius: 10px;
    padding: 6px 15px;
    width: 100%;
}

.master_cl__input:hover {
    border: 2px solid var(--secondary) !important;
}

.master_cl__input ::placeholder {
    color: var(--border);
}

.complete_date_input {
    cursor: pointer;
    width: 100% !important;
}

.master_cl__footer_slider_green {
    height: 100%;
    background-color: var(--primary);
    position: relative;
    z-index: 3;
    border-bottom-left-radius: 8px;
}

.master_cl__footer_slider_green_ar {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 8px;
}

@media only screen and (max-width: 700px) {
    .master_cl__main_con .modal .modal-content {
        width: 90%;
    }

    .master_cl__step1_cont1 {
        gap: 1.5rem !important;
    }

    .master_cl__body_step {
        flex-direction: column;
        margin-bottom: 2rem;
        gap: 1.5rem;
    }

    .master_cl__step2_main_cont {
        flex-direction: column;
        gap: 1.5rem;
        margin-bottom: 2rem;

    }

    .master_cl__step3_main_cont {
        width: 100% !important;
        margin-bottom: 2rem;

    }

    .master_cl__step4_main_cont,
    .master_cl__step5_main_cont {
        margin-bottom: 2rem;

        flex-wrap: nowrap !important;
        min-height: 350px !important;
        height: auto !important;
    }

    .master_cl__step5_main_cont .master_cl__step1_cont .master_cl__input {
        width: 100% !important;
    }

    .master_cl__step5_header .datepicker_cont {

        left: -145px !important;
    }

    .master_cl__step5_header .DatePicker {
        width: 100% !important;
    }

    .datepicker_cont2 {
        top: 400px !important;

        left: 0px !important;

    }

    .master_cl__step6_main_cont {
        width: 100% !important;
        margin-bottom: 2rem;

    }

    .master_cl__textarea {
        width: 95% !important;
    }
}