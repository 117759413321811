.apply_step8 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.apply_step8 .apply_img {
    width: 240px;
}


.apply_step8 .dotter_spacer {
    display: block;
    border-bottom: dotted 5px var(--grey);
    margin: 1rem 0;
    width: 100%
}

.app_step8_next_button {
    width: 30%;
    align-self: flex-end;
}

.apply_step8 .apply_primary_button {
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    padding: 7px 12px;
    border: none;
    outline: none;
    cursor: pointer;
}

.app_step8_next_button,
.app_step8_apply_later_button {
    width: 50%;
    align-self: flex-end;
}

.app_step8_apply_later_button,
.app_step8_apply_later_button_resp {
    background-color: var(--pink) !important;
    margin-right: 20px;
    margin-left: 20px;
}

.apply_step8 .complete_step_footer_button {
    display: flex;
    justify-content: right;
    width: 100%;
}
.apply_step8 .div_buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
}

.questions_div{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
    .app_step8_next_button_resp {
        width: fit-content;
        display: flex;
        align-items: center;
        align-self: flex-end;
    }
}
@media only screen and (max-width: 644px) {
    .apply_step8 .div_buttons{
        width : 100%
    }
    }