.partners{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: auto;
    padding-top: 8rem;
}
.Partner_title{
    font-size: 3rem;
    font-family: var(--bold);
    margin: 5rem auto 3rem auto;
}
.partners_green_text{
    color: var(--primary);
}
.partners_navbar{
    width: 100%;
    font-size: 1.3rem;
    text-align: center;
}
.partners_navbar ul{
    width: 100%;
    list-style: none;
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    text-align: center;
    padding: 0;
    padding-bottom: 5px;
    cursor: pointer;
    gap: 2rem;
    flex-wrap: wrap;


    
}
.partners_navbar ul li:hover{
    color: var(--pink);
    border-bottom: 5px solid var(--pink);
    transition: all 0.5s ease;}

.img1 img{
    width: 70%;
    margin: 4rem 0 1rem 0;
}

.navbar_titles_eng{
    width: fit-content;
}
.red_text{
    color: var(--pink);
}
.transportation_title{
    font-size: 2.5rem;
}
.transp_underline{
    width: 50px;
    margin: auto;
    border-bottom: 8px solid var(--primary);
    border-radius: 20px;
}
.transportation_partner{
    width: 80%;
    margin: auto;
    text-align: center;
}
.carousel{
    width: 80%;
    margin: auto;
}
.carousel_item{
    box-shadow: 0px 0px 25px -6px rgba(0,0,0,0.1) !important;
    background-color: white;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    padding: 50px;
}

.carousel_item img{
height: 180px;
width: 180px;
object-fit: cover;
}
.academic_title{
    font-size: 2.5rem;
}
.academic_underline{
    width: 50px;
    margin: auto;
    border-bottom: 8px solid var(--pink);
    border-radius: 20px;
}
.green_text{
    color: var(--primary);
}
.img2{
    margin-top: 10rem;
}
.academic_partner{
    width: 80%;
    margin: auto;
    text-align: center;
}
.community_partner{
    width: 80%;
    margin: auto;
    text-align: center;
}
.community_title{
    font-size: 2.5rem;
}
.community_underline{
    width: 50px;
    margin: auto;
    border-bottom: 8px solid var(--pink);
    border-radius: 20px;


}

.bottom_div{
    width: 50%;
    margin: auto;
}
.Partner_title_arabic{
        font-size: 3rem;
        font-weight: bold;
}
.academic_title_arabic{
    font-size: 3rem;
    font-weight: bold;
}
.community_title_arabic{
    font-size: 3rem;
    font-weight: bold;
}
.bottom_div_arabic{
    font-size: 1.4rem;
    width: 50%;
}
.navbar_titles{
    font-size: 2rem;
}

.transp_underline{
    margin-top: 0.5rem;
    }
@media only screen and (max-width: 768px) {
    .partners{
        width: 90%;
    }
    .partners_navbar ul{
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {

    .transportation_title,
    .Partner_title,.academic_title ,.community_title{
        font-size: 2rem;
        text-align: center;
    }

    .partners_navbar ul{
        font-size: 1rem;
    }
.transportation_partner{
    width: 95%;
}

.carousel_item ,.bottom_div{
    width: 70%;
}



}