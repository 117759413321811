
.horizontal-slider {
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    height: 12px;
    margin: 10px 6px;
    padding: 4px 0;
    touch-action: none;
  }
  .horizontal-slider .example-track {
    top: 20px;
    height: 4px;
  }
  .horizontal-slider .example-thumb {
    top: 14px;
    width: 20px;
    outline: none;
    height: 20px;
    line-height: 38px;
  }
  .example-thumb {
    position: absolute;
    z-index: 100;
    background: var(--primary);
    border: 5px solid var(--primary);
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  }
  
  .example-track {
    position: relative;
    background: #ddd;
  }
  
  .example-track.example-track-0 {
    background: var(--primary)
  }
  
  .horizontal-slider .example-track {
    top: 20px;
    height: 10px;
    border-radius: 8px;
  }