.apply_step2 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.apply_step2 .apply_img {
    width: 160px;
}

.complete_radio_comp {
    display: flex;
    align-items: center;
    gap: 0.5rem
}

.association_name_input {
    background-color: white;
    border: 2px solid var(--border);
    border-radius: 10px;
    padding: 6px 15px;
    margin-bottom: 1rem;
    width: 50%;
}

.complete_radio_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30%;
}

input[type=radio].association_radio {
    height: 20px;
    width: 20px;
    accent-color: #858585;
}

.apply_step2 .dotter_spacer {
    display: block;
    border-bottom: dotted 5px var(--grey);
    margin: 1rem 0;
    width: 100%
}

.app_step2_next_button {
    width: 30%;
    align-self: flex-end;
}

.apply_step2 .complete_step_footer_button {
    display: flex;
    justify-content: right;
    width: 100%;
}
.apply_step2 .div_buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
}
.empty_footer_div {
    align-self: flex-end;
    width: 40%;
}
.app_step2_next_button,
.app_step2_apply_later_button {
    width: 50%;
    align-self: flex-end;
}


.app_step2_apply_later_button,
.app_step2_apply_later_button_resp {
    background-color: var(--pink) !important;
    margin-right: 20px;
    margin-left: 20px;
}
.gray-btn{
    background-color: #858585 !important;
}

.div_buttons_left{
    justify-content: left !important;
}
.complete_radio_comp input[type='radio']:checked:after {
    width: 12px;
    height: 12px;
    border-radius: 15px;
    top: 1px;
    left: 2px;
    right:2px;
    position: relative;
    background-color: var(--primary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

@media only screen and (max-width: 768px) {
    .app_step2_next_button_resp {
        width: fit-content;
        display: flex;
        align-items: center;
        align-self: flex-end;
    }
    .div_buttons {
        width: auto
    }
    .app_step2_next_button_resp,
    .app_step2_apply_later_button {
        width: fit-content;
        display: flex;
        align-items: center;
        align-self: flex-end;
    }
}
@media only screen and (max-width: 590px) {
    .complete_radio_container{
        width: 70%;
    }
    .association_name_input{
        width:90%
    }
}
@media only screen and (max-width: 644px) {
.apply_step2 .div_buttons{
    width : 100%
}
}