.my_notifications_container,
.my_notifications_container_ar {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding-top: 30px;
}

.notifications_tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem
}

.notification_btn {
    display: flex;
    background-color: white;
    color: black;
    border: 1px solid var(--primary);
    outline: none;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    font-size: 1.1rem;
    border-radius: 30px;
    margin-top: 1.5rem;
}

.notification_container_button {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-direction: row-reverse;
}

.have_a_look_btn {
    display: flex;
    background-color: var(--purple);
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.2rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 7px;
    margin-top: 1.5rem;
    width: fit-content
}

.read_more_btn {
    display: flex;
    background-color: var(--primary);
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.2rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 7px;
    margin-top: 1.5rem;
    width: fit-content
}

.notification_btn_active {
    background-color: var(--primary);
    color: white;
    border: none !important;
}

.notification_circle {
    height: 25px;
    width: 25px;
    background-color: var(--pink);
    color: white;
    border-radius: 50%;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
}

.my_notifications_container_data {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding-top: 30px;
    width: 50%
}

.notification_card {
    background-color: white;
    padding: 30px 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 8%) !important;
    width: 100%;
    border-radius: 15px;
    flex-wrap: wrap;
    margin: 2rem 10px 0 10px
}

.all_notifications_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.notif_container {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 8%) !important;
    width: 100%;
    border-radius: 15px;
    flex-wrap: wrap;
    margin: 2rem 10px 0 10px
}

.notif_container:hover{
background-color: #e1f7fa !important;
}

.notification_message {
    margin-bottom: 15px;
}

.notification_date_time {
    font-size: var(--px-10) !important;
    color: var(--bottom-grey);
}

.purple_btn {
    background-color: var(--purple) !important;
}

.blue_btn {
    background-color: var(--primaryBlue) !important;
}

.notification_circle_purple {
    background-color: white !important;
    color: var(--purple) !important;
}

.notification_circle_blue {
    background-color: white !important;
    color: var(--primaryBlue) !important;
}

@media (max-width: 768px) {

    .my_notifications_container,
    .my_notifications_container_ar {
        width: 70%
    }

    .notifications_tabs {
        flex-direction: column;
    }

    .notification_btn {
        margin-top: 0.5rem;
    }

    .my_notifications_container_data {
        width: 100%;
    }
}