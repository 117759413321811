.create_opp_step7_main_cont{
    display: flex;
    flex-direction: column;
    width: 90%;
    min-height: 350px;
    gap:2rem;
    margin-bottom: 1.5rem;
}

.create_opp_step7_main_cont .create_opp_step1_cont .complete_profile_select{
    width: 50% !important;
}

.marginTop0{
    margin-top: 0px !important;
    margin-bottom: 30px !important;
}
