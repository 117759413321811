.Main_opp_card_div {
    margin: auto;
    margin-top: 4rem;
    width: 75%;

}

.opp_goback_div {
    display: flex;
    width: 75%;
    margin: auto;
    margin-top: 7rem;
    color: var(--dark-grey);
    align-items: center;
    cursor: pointer;
}

.opp_card_imng8 {
    width: 50px;
    height: 50px;

}

.opp_goback_icon {
    rotate: 90deg;
    margin-right: 5px;
}

.opp_header_card {
    display: flex;
    margin-bottom: 2rem;
}

.opp_card_img {
    width: 150px;
    height: 150px;
    border-radius: 12px;
    object-fit: cover;
}

.opp_card_text_container {
    margin-left: 2rem;
}

.opp_card_header_subtitle {
    font-size: 12px;
    color: var(--primary);
    margin-top: 10px;
}

.opp_card_flag_comp_1 {
    height: 40px;
    /* height: auto; */
    margin-right: 10px;
}

.saved_opp_btn {
    color: var(--pink);

}

.opp_card_header_title {
    margin-top: 10px;
    font-size: 30px;
    font-family: var(--bold);
}

.opp_card_header_flags_cont {

    margin-top: 30px;
}

.opp_card_save_btn_cont {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    border-right: 2px solid var(--grey);
    width: 150px;
}

.opp_card_save_btn_cont_2 {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    border-right: 2px solid var(--grey);
    width: 150px;
}

.divider {
    border-left: 2px solid var(--grey);
    margin-left: 26px;
}

.opp_card_share_btn_cont {
    margin-left: 1rem;
    display: flex;

}

.opp_card_share_btn_cont_ar {
    margin-right: 1rem;
    display: flex;

}

.opp_card_info_conts {
    border-top: 2px solid var(--grey);
    ;
    border-bottom: 2px solid var(--grey);
    ;
    border-spacing: 2px solid var(--grey);
    height: 75px;
    display: flex;
}

.opp_card_logo {
    margin-top: 4px;

}

.greeny {
    color: var(--primary);
}

.pinky {
    color: var(--pink);
}

.opp_card_logo_2 {
    color: var(--primary);
    margin-top: 4px;
    width: 30px;
    height: 30px;
    margin-right: 4px;
}

.opp_card_save_text {
    font-size: 18px;
    font-family: var(--bold);
    margin-left: 10px;


}

.opp_card_save_text_ar {
    font-size: 18px;
    font-family: var(--bold);
    margin-right: 10px;
}

.opp_card_apply_btn_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.opp_card_apply_btn {
    width: 100px;
    height: 40px;
    background-color: var(--primary);
    color: white;
    font-size: 18px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
}

.opp_card_line1_cont {
    border-bottom: 2px solid var(--grey);
    ;
    min-height: 100px;
    display: flex;
    flex-direction: row;
}

.opp_card_line1_text {

    padding-top: 10px;
    width: 350px;
    border-right: 2px solid var(--grey);

}

.opp_card_line1_text_ar {

    padding-top: 10px;
    width: 350px;
    border-left: 2px solid var(--grey);

}

.opp_card_line1_text_2 {

    padding-top: 10px;
    width: 350px;


}

.opp_card_line1_text_3 {

    padding-top: 10px;
    width: 350px;


}

.opp_card_line1_text_4 {

    padding-top: 10px;
    width: 350px;
    border-right: 2px solid var(--grey);

}

.opp_card_line1_text_4_ar {

    padding-top: 10px;
    width: 350px;
    border-left: 2px solid var(--grey);

}

.opp_card_line1_text_5 {
    padding-top: 10px;
    width: 350px;

}

.opp_card_line1_text_title {
    font-size: 18px;
    font-family: var(--bold);
    /* margin-left: 10px; */

}

.opp_card_line1_text_subtitle {
    width: 80%;
}

.opp_card_containers,
.title_opp_container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.opp_card_line1_text_subtitle {
    margin-top: 4px;
    margin-left: 10px;
}

.opp_card_line1_text_subtitle_ar {
    margin-top: 4px;
    margin-right: 10px;
}

.opp_card_containers_2 {
    display: flex;
    margin-left: 1rem;
    align-items: center;
    margin-bottom: 10px;
}

.opp_card_containers_2_ar {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    margin-bottom: 10px;
}



.opp_card_line2_text_subtitle {
    margin-left: 34px;
    width: 60%;
}

.opp_card_from_container {
    margin-top: 15px;
    display: flex;
    align-items: center;
}

.divider_right {
    border-left: 2px solid var(--grey);
    margin-right: 1rem;

}

.opp_card_line2_cont {
    display: flex;

}

.opp_card_column1_cont,
.opp_card_column2_cont,
.opp_card_column3_cont {
    width: 30%;
    margin-top: 10px;
}

.green_opp_section {
    background-color: var(--primary);
    padding: 80px 20px;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: center;
    gap: 3rem 1rem;

}

.green_img_logo {
    width: 35px;
    height: 35px;
    margin-right: 1rem;
}

.green_opp_section_container {
    display: flex;
    margin-top: 5rem;
    margin-left: 8rem;
    align-items: center;
}

.green_opp_section_container_2 {
    display: flex;
    margin-top: 5rem;
    margin-left: 8rem;
    align-items: center;
    margin-bottom: 8rem;

}

.green_opp_section_text_title {
    color: white;
    font-family: var(--bold);
    font-size: 20px;
}

.green_opp_section_text_ar {
    margin-right: 10px;
}

.green_opp_section_text_subtitle {
    color: white;
    font-size: 14px;
    margin-top: 4px;
}

.green_img_logo_2 {
    width: 35px;
    height: 35px;
    margin-right: 1rem;

}

.green_opp_section_icon {
    display: flex;
    align-items: center;
}

.green_contents_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px
}

.opp_card_bottom_div {
    width: 75%;
    margin: auto;
    margin-top: 4rem;
}

.opp_card_bottom_title {
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-family: var(--bold);

}

.opp_card_bottom_verified_icon {
    color: var(--primary);
    display: flex;
    align-items: center;
}

.opp_card_bottom_subtitle {
    font-size: 18px;
    margin-top: 18px;
}

.mapping_box_bottom {
    padding-bottom: 3rem;
    margin-top: 1rem;
}

.opp_card_bottom_div_line {
    display: flex;
    margin: unset !important;
    justify-content: space-between;

    border-bottom: 4px solid var(--pink);
}

.arrow-icon {
    cursor: pointer;
    color: var(--pink);
}

.opp_card_bottom_description_container {
    margin-top: 1rem;
    width: 70%;
}

.arrow-hover {
    animation: rotation 1s linear;
}

.arrow-icon-rotate {
    transform: rotate(180deg);
    cursor: pointer;
    color: var(--pink);
}

.opp_deatil_ngo_desc {
    font-size: 1.3rem;
    font-family: var(--bold);
}

.arrow {
    margin-top: 3rem;
}

.title_opp_container_2 {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
}

.opp_card_location_container {
    display: flex;
    align-items: center;

}

.popup_title {
    display: flex;
    flex-direction: column;
    font-family: bold;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    font-size: 1.5rem;
    text-align: center;
}


.popup_title .opp_card_apply_btn {
    width: fit-content;
}

.pop_up_sub {
    font-size: 1rem;
    color: var(--primary);
}

.home_popup_body_link {
    font-family: var(--bold);
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 8px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 2px solid var(--grey);
    margin-top: 1rem;
    width: 400px;
    outline: none;
}

.div_share{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.div_share .home_popup_btn{
    width: 50%;
}
.opp_card_info_conts .modal-content{
    width:auto !important
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}



@media (max-width:850px) {
    .home_popup_body_link {
        width: 100%;
    }
    .opp_card_info_conts .modal-content{
        width: 90% !important;
    }
    .opp_card_info_conts{
        justify-content: flex-start !important;
    }
}

@media (max-width:550px) {
    .opp_card_line1_cont {
        flex-direction: column;
    }

    .opp_card_line1_text {
        border-right: none;
        border-bottom: 2px solid var(--grey);
        width: 100%;
    }

    .opp_card_line1_text_3 {
        display: none;
    }

    .opp_card_containers_2 {
        margin-left: 0rem;
    }

    .opp_card_line1_text_4 {
        border-right: none;
        border-bottom: none;
        width: 100%;
    }
}

@media (max-width:460px) {

    .opp_header_card {
        display: flex;
        margin-bottom: 2rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .opp_card_info_conts {
        border-top: 2px solid var(--grey);
        border-bottom: 2px solid var(--grey);
        border-spacing: 2px solid var(--grey);
        height: 129px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .opp_card_save_btn_cont {
        border-bottom: 2px solid var(--grey);
        padding: 1rem;
    }

    .opp_card_share_btn_cont {
        margin-left: 0rem;

    }

    .opp_card_save_btn_cont_2 {
        border-right: none;
        border-bottom: 2px solid var(--grey);
        padding: 1rem;
    }



}

@media (max-width:350px) {
    .opp_card_info_conts {
        height: 200px;
    }
}

.home_popup_btn {
    background-color: var(--primary);
    color: white;
    border: none;
    padding: 6px 20px;
    border-radius: 8px;
    font-size: 1rem;
    font-family: var(--bold);
    cursor: pointer;
    margin-top: 1rem;
    margin-left: 1rem;


}


.modal {
    display: flex;
    justify-content: center;
}