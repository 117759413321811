.note_msg{
    font-size: 0.8rem;
    width: 30%;
}


.complete_step1_body1 .react-tel-input .form-control {
    width: -webkit-fill-available !important; 
    border: 2px solid var(--border) !important;
    border-radius: 10px;
  }
  
  .complete_step1_body1 .react-tel-input {
    width: 30%  !important;
    margin-bottom: 1rem !important;
  }
  
  .complete_step1_body1 .react-tel-input .flag-dropdown {
    border: 2px solid var(--border);
    border-radius: 10px 0 0 10px;
  }
  
  @media only screen and (max-width: 768px){
    .complete_step1_body1 .react-tel-input {
    width: 45% !important;
  }
  }
  
  @media only screen and (max-width: 590px) {
  
    .complete_step1_body1 .react-tel-input {
      width: auto  !important;
    }
   
    .complete_step1_body1 .react-tel-input .flag-dropdown {
      height: 40px !important
  }
  .complete_step1_body1 .react-tel-input{
    width: 95%  !important;
  }
  
  }
  