.Main_feedbacks_page {
    position: relative;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */

    margin-top: 4rem;
}

.feedbacks_page_white {

    background-color: #fefefe;
    margin: auto;
    border: 1px solid #888;
    width: 50%;
    border-radius: 8px;
    margin-bottom: 4rem;

}


.feedbacks_page {
    padding: 0 40px;

}

.feedbacks_page_title_container {
    font-size: 1.7rem;
    font-weight: var(--bold);
}

.feedbacks_page_title_container2 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1rem;
}

/* .feedbacks_page_emojies_container {
    margin-top: 2rem;
} */

.feedbacks_page_subtitle {
    font-size: 0.9rem;
    font-weight: var(--bold);
    margin-top: 1rem;
}

.feedbacks_page_emojies {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    flex-wrap: wrap;
}

.emojie_logo {
    width: 50px;
    height: 50px;
    cursor: pointer;
    opacity: 0.5;

}

.emojie_logo:hover,
.active_emojie {
    opacity: 1 !important;
}

.emojie_logo_selected {
    width: 50px;
    height: 50px;
    cursor: pointer;
    opacity: 1;
}

.feedbacks_page_emojies_row_1_text {

    margin-top: 2rem;
    font-size: 0.9rem;

}

.feedbacks_page_emojies_row_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.feedbacks_page_experience_comments {
    margin-top: 2rem;
}

.feedbacks_page_title_comment {
    font-size: 0.9rem;

    width: 90%;
}

.feedbacks_page_input_comment_1 {
    width: 90%;
    height: 250px;
    margin-top: 1rem;
    border-radius: 8px;
    border: 2px solid var(--grey);
    resize: none;
    padding: 10px 10px;

}

.feedbacks_page_input_comment_2 {
    width: 90%;
    height: 150px;
    margin-top: 1rem;
    border-radius: 8px;
    border: 2px solid var(--grey);
    resize: none;
    padding: 10px 10px;

}

.feedbacks_page_input_comment_2-1 {
    width: 90%;
    height: 100px;
    margin-top: 1rem;
    border-radius: 8px;
    border: 2px solid var(--grey);
    resize: none;
    padding: 10px 10px;

}

.feedbacks_page_skill_development {
    margin-top: 2rem;
}

.feedbacks_page_title_skill {
    font-size: 1rem;
    font-weight: var(--bold);
    width: 90%;
}

.radio_buttons_container_feedbacks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
}

.radio_buttons_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.feedbacks_page_radio_text {
    font-size: 0.9rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.feedback_select {
    width: 50%;
    margin-top: 10px;
}

.radio_buttons_container_numbers{
    width:70%
}
.feedbacks_page_radio {
    width: 20px;
    height: 20px;
    cursor: pointer;

}

.feedbacks_page_radio:checked {
    background-color: var(--primary);
}

.feedbacks_page_skill_developement_comments {
    margin-top: 2rem;
}

.feedbacks_page_submit_button_text {

    width: 25%;
    height: 40px;
    border-radius: 8px;
    background-color: var(--primary);
    color: white;
    font-size: 1rem;
    font-weight: var(--bold);
    cursor: pointer;
    border: none;
}

.feedbacks_page_submit_button {
    display: flex;
    margin: auto;
    margin-top: 4rem;
    justify-content: flex-end;
}

@media (max-width:800px) {
    .feedbacks_page_submit_button_text {
        width: fit-content;

    }

    .feedbacks_page_emojies_row_1 {
        margin-left: 1rem;
    }

    .feedbacks_page_title_comment {
        width: 100%;
    }

    .feedbacks_page_input_comment_1,
    .feedbacks_page_input_comment_2 {
        display: flex;
        margin: auto;
        margin-top: 1rem;
        width: 100%;
    }

    .feedbacks_page_input_comment_1 {
        width: 100%;
    }

    .radio_buttons_container_feedbacks {
        width: 70%;
    }
}

@media (max-width:510px) {
    .feedbacks_page_emojies {
        flex-direction: column;
    }

    .radio_buttons_container_feedbacks {
        width: 100%;
    }

}

@media (max-width:280px) {
    .feedbacks_page_white {
        width: 80%;
    }
}