.evaluation_feedback_container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  padding-top: 8rem;
}

.evaluation_feedback_title {
  padding-top: 20px;
  padding-bottom: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.evaluation_feedback_title2 {
  padding-bottom: 20px;
  font-size: var(--px-14) !important;
}

.evaluation_feedback_container .text_field_getintouch {
  margin-top: 0px !important;
  padding: 10px;
  border-color: rgba(118, 118, 118, 0.3);
  height: 50px;
  width:80%
}

.evaluation_feedback_container .feedbacks_page_emojies_container {
  width: 65%;
}

.evaluation_feedback_container .feedbacks_page_emojies {
  margin-top: 0px !important;
}

.evaluation_feedback_container .input-icons {
  width: 50% !important;
}

.evaluation_feedback_container .right-50 {
  right: 50% !important;
}

.evaluation_feedback_container .left-50 {
  left: 50% !important;
}


.evaluation_feedback_title {
  font-size: 20px !important;
  font-family: var(--bold) !important;
  padding-top: 50px !important;
  padding-bottom: 15px !important;
}



.evaluation_feedback_container .filter_list_check {
  margin-top: 0px !important;
}

.organizationRole_div {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.filter_list_comp {
  font-size: var(--px-16) !important;
}

.edit-icon {
  padding: 10px;
  min-width: 50px;
  text-align: center;
  height: 35px;
  background-image: url("../../assets/images/icons/edit.svg");
  background-repeat: no-repeat;
  margin-top: 7px;
}

.quantify_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.role_input{
  width:40% !important
}
.quantify_div_inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.evaluation_feedback_container .goback_div {
  display: flex;
  width: 75%;
  color: var(--dark-grey);
  align-items: center;
  cursor: pointer;
  margin-bottom: 40px;
}

.padding-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.evaluation_feedback_container .checkbox_div {
  display: flex;
  flex-direction: row;
}

.evaluation_feedback_container .evaluation_button {
  width: 20%;
  margin: 0px !important;
}

.submit_div {
  margin-top: 50px;
}

.hide {
  display: none;
}

.evaluation_button:disabled {
  background-color: #ccc;
  /* Change the background color */
  color: #999;
  /* Change the text color */
  cursor: not-allowed;
  /* Change the cursor style to not-allowed */
  /* Add other styles as needed */
}

.quantify_div_input_label {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .evaluation_feedback_container .input-icons {
    width: 90% !important;
  }

  .evaluation_feedback_container .right-50 {
    right: 20% !important;
  }

  .evaluation_feedback_container .left-50 {
    left: 20% !important;
  }

  .quantify_div_inputs {
    flex-direction: column;
  }

  .evaluation_feedback_container .checkbox_div {
    flex-direction: column;
  }

  .evaluation_feedback_container .evaluation_button {
    width: 40%;
    margin: 0px !important;
  }
}