.main-footer {
  width: 100%;
  background-color: white;
  padding: 35px 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../../assets/images/footer_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.1) !important;
  margin-top: 5rem;
}

.main-footer-arabic {
  width: 100%;
  background-color: white;
  padding: 35px 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../../assets/images/footer_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.1) !important;
  margin-top: 5rem;

}

.mailto_div {
  text-decoration: none !important;
  color: black;
}

.mailto_div:hover {
  color: var(--primary) !important;
}

.footer_image {
  height: 80px;
}

.footer_list li {
  width: fit-content;
}

.footer_info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer_list {
  list-style-type: none;
  font-weight: bold;
  font-size: 16px;
}

.footer_list li {
  margin-bottom: 20px;
  width: 240px;
  margin: 0.5rem;
}

.footer_link:hover {
  color: var(--primary) !important;
}

.footer_link_arabic:hover {
  color: var(--primary) !important;
}

.footer_link {
  color: var(--secondary) !important;
  text-decoration: none;
}

.footer_link_arabic {
  color: var(--secondary) !important;
  text-decoration: none;
  font-size: 23px;
}

.footer_link_Contact {
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
  font-size: 1.7rem;
}

/* .footer-email{
    font-size: 1rem;
  }

  .footer-num{
    font-size: 1rem;
  } */

.footer_link_cont {
  align-items: center;
  display: flex;
  font-size: 0.9rem;
}

.footer-email:hover {
  color: var(--primary) !important;
}

.footer-num:hover {
  color: var(--primary) !important;
}

.footer_icons_active {
  color: var(--secondary);
  cursor: pointer;
}

.footer_icons_active:hover {
  color: var(--primary);
}

.footer-social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2.5rem;
  gap: 10px;
}

.footer-social-arabic {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2.5rem;
  gap: 10px;
}

.footer_rights {
  font-size: 0.8rem;
  font-family: Arial;
  font-weight: 700;
}

.footer_rights_arabic {
  font-size: 1rem;
  font-family: Arial;
  font-weight: 700;
}

.footer-logo {
  margin-inline: auto;
  margin-top: 25px;
}

@media (max-width:300px) {


  .footer_list li {
    width: 90%;
    margin: 0;
  }

  .footer_link_cont {
    font-size: 0.7rem;
  }
}