.deletecontainer{
    margin-top: 7rem;
    display: flex;

flex-direction: column;
gap: 2rem;
padding-left: 10%;
}
.deleteheader{
    font-size: 2rem;
}