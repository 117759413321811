.complete_step3_main_body{
    display: flex;
    flex-direction: column;
    min-height: 438px;
    justify-content: space-between;
    gap:1rem
  }

  
.complete_step3_subheader{
    font-family: regular;
    font-size: 0.9rem;
}



.complete_step3_search{
    width: 45%;
}

.css-1rhbuit-multiValue{
    display: none !important;
}

.complete_step3_skills_cont{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 2rem;
}
.complete_step3_skills_input_cont{
    display: flex;
    align-items: baseline;
    align-items: center;
}
.complete_step3_skills_input{
background: transparent;
border: 2px solid var(--primary);
border-radius: 10px;
padding: 0.2rem 1rem;
}