.privacy-page {
   align-items: center;
   height: 100%;
}


.privacy-green-block {
   background-color: var(--primary);
   padding: 10px;
}

.privacy-green-block-arabic {
   background-color: var(--primary);
   padding: 10px;
}

.privacy-green-block p {
   font-family: var(--bold);
   color: var(--white);
   font-size: var(--px-50);
   padding-left: 17%;
   padding-top: 4%;
}

.privacy-green-block-arabic p {
   font-family: var(--bold);
   color: var(--white);
   font-size: var(--px-50);
   padding-right: 17%;
   padding-top: 4%;
}

.privacy-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
}

.privacy-big-img {
   margin: auto;
   width: 100%;
   margin-top: 2rem;
   margin-bottom: 2rem;
   border-radius: 8px;
}

.privacy-mapping-box {
   padding-bottom: 1rem;
   margin-left: 17.5%;
}

.privacy-mapping-box-arabic {
   padding-bottom: 3rem;
   margin-right: 17.5%;
}

.block {
   display: flex;
   width: 79%;
   flex-direction: column;
}

.pink_underline {
   width: 85px;
   border-bottom: 5px solid var(--pink);
   border-radius: 8px;
}

.text_privacy_en {
   margin: unset !important;
   font-size: 30px;
   font-weight: bold;
}

.privacy_texts_ar {
   font-size: 1.5rem;
}

.text_privacy_english {
   font-size: 1rem;
}

.text {
   width: 79%;
}

.privacy-pink-block {
   background: var(--pink);
   width: 65%;
   margin: auto;
   height: 150px;
   color: var(--white);
   margin-bottom: 5%;
   padding: 2%;
}

.pink-content h2 {

   font-weight: bold;
   color: white;
}

.sub-content {
   display: flex;
   gap: 0.5rem;
}

.privacy-img {
   width: 75%;
   margin: auto;
}

.sub-content-arabic {
   display: flex;
   gap: 0.5rem;
   font-size: 17px;
   font-weight: bold;
}

.contact-button {
   width: 15%;
   height: 40px;
   border-radius: 10px;
   background-color: #72c095;
   font-size: var(--px-14);
   border-color: transparent;
   text-align: center;
   color: white;
   margin-top: 0.5%;
   cursor: pointer;
}

.text_privacy_ar {
   font-size: 2rem;
   font-weight: bold;
}

.contact-button-arabic {
   width: 10%;
   height: 40px;
   border-radius: 10px;
   background-color: #72c095;
   font-size: var(--px-16);
   font-weight: bold;
   border-color: transparent;
   text-align: center;
   color: white;
   margin-top: 0.5%;
   cursor: pointer;
}

@media only screen and (max-width:1040px) {
   .sub-content {
      display: inline-block;

   }

   .privacy-pink-block {
      height: 210px;
   }

   .contact-button {
      width: 40%;
   }
}

@media (max-width:450px) {
   .contact-button {
      width: fit-content;
   }
}