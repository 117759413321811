.Main_masteclass_div {
    margin-top: 4rem;
}

.masterclass_body_first_div {
    width: 30%;
    margin: auto;
    /* justify-content: center; */
    display: flex;
    flex-direction: column;
}

.masterclass_body_first_div_text {
    font-size: 2.3rem;
    width: 100%;
    font-family: var(--bold);
    margin-top: 3rem;
}

.masterclass_body_video_cont {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 2rem;
}



.emails_div {
    width: 280px;
    height: 40px;
    border-radius: 10px;
    position: relative;
    font-size: var(--px-14);
    border-color: transparent;
    background-color: var(--primary);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    color: white;
}

.input_area_master {
    width: 100%;
    padding: 15px;
    margin-top: 1rem;
    border-radius: 8px;
    border: 2px solid var(--grey);
}

.masterclass_body_first_div_subtext {
    display: flex;
}

.masterclass_body_first_div_subtext_text {
    font-size: 0.9rem;
    color: var(--primary);
    margin-top: 1rem;
    margin-left: 8px;
}

.checkbox_master {
    width: 15px;
    margin-top: 1rem;
    color: var(--primary);
}

.masterclass_body_video {
    width: 500px;

    border-radius: 12px;
}

.masterclass_body_first_div_container {
    display: flex;

}

.masterclass_body_video_conatainer {
    width: 50%;
    margin-top: 3rem;
}

.masterclass_body_video_design {
    width: fit-content;

    box-shadow: 0px 0px 20px #72c09540 !important;
}

.masterclass_body_second_div {
    margin-top: 5rem;
    /* background-color: #2C2C2C; */
    display: flex;
    justify-content: center;
    padding: 40px 10px 40px 10px;
}

.masterclass_image {
    width: 300px
}

/* .masterclass_body_second_image_container {
    margin-left: 1rem;
} */

.masterclass_body_second_text {

    font-size: 2rem;
    font-family: var(--bold);
    width: 100%;
    margin-top: 40px;
}

.masterclass_body_second_text_container {
    width: 45%;
    margin-left: 5rem;
}

.masterclass_body_second_text_2 {

    font-size: 1.2rem;
    font-family: var(--bold);
    width: 100%;
    margin-top: 1rem;
}

.masterclass_button {
    background-color: var(--pink);
    color: white;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
    font-size: 1.1rem;
    font-family: var(--bold);
    margin-top: 1rem;
    border: none;
    cursor: pointer;
}

.masterclass_body_third_div {
    width: 75%;
    margin: auto;
}

.masterclass_body_third_text {
    font-size: 2.5rem;
    font-family: var(--bold);
    width: 65%;
    margin-top: 5rem;
}

.masterclass_body_third_pink_underline {
    width: 150px;
    height: 10px;
    background-color: var(--pink);
    margin-top: 1rem;
    border-radius: 8px;
}

.masterclass_body_number_cont {
    border: 1px solid var(--pink);
    background-color: var(--pink);
    color: white;
    border-radius: 50%;
    width: 35px;
    height: 30px;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-left: 8px;
}

.masterclass_body_third_texts_container {
    margin-top: 2rem;
}

.masterclass_body_third_texts_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.masterclass_body_third_container {
    display: flex;
    align-items: center;
    width: 45%;
    margin-top: 2rem;
    margin-left: 1rem;

}

.masterclass_Acess_pop1 {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.masterclass_Acess_pop_texts_bundles {
    font-size: 1.5rem;
    font-family: var(--bold);
    margin-top: 1rem;
}

.masterclass_Acess_pop_container_2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    gap: 4rem;
}

.masterclass_body_third_text_cont {
    width: 100%;
}

@media (max-width: 1000px) {
    .masterclass_body_first_div_container {
        flex-direction: column;
    }

    .masterclass_body_first_div {
        width: 75%;
    }

    .input_area_master {
        width: 60%;
    }

    .masterclass_body_video_conatainer {
        margin: auto;
        margin-top: 3rem;
    }
}

@media (max-width:700px) {
    .masterclass_body_video {
        width: 350px;
    }

    .masterclass_body_second_div {
        flex-direction: column;
        align-items: center;
    }

    .masterclass_body_second_text_container {
        width: 80%;
    }

    .masterclass_body_second_text_2 {

        font-size: 1rem;
    }

    .masterclass_body_second_text {

        font-size: 2rem;
    }

    .masterclass_body_third_texts_container {
        flex-direction: column;
    }

    .masterclass_body_third_container {
        width: 100%;
    }
}

@media (max-width:450px) {
    /* .masterclass_body_video {
        width: 80%;
    } */

    .masterclass_body_video_conatainer {
        width: 90%;
    }
}

@media (max-width:380px) {
    .masterclass_body_video {
        width: 80%;
    }

    .masterclass_body_video_conatainer {
        width: 90%;
    }

    .masterclass_body_video_design {
        box-shadow: none !important;
    }

    .masterclass_image {
        width: 200px;
    }
}

.masterclass_Acess_pop_img {
    width: 150px;
    height: auto;
}

.masterclass_Acess_pop_img_2 {
    width: 80px;


}

.masterclass_Acess_pop_text {
    display: flex;
    flex-direction: column;
    width: 45%;
    align-items: center;
}

.masterclass_Acess_pop_input {
    margin-top: 1rem;

}

.masterclass_Acess_pop_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.masterclass_Acess_pop_label {

    margin-left: 8px;
}

.masterclass_Acess_step1_container_tooltip {
    width: 100%;
    text-align: center;
}

.masterclass_button_acess {
    background-color: var(--primary);
    color: white;
    border-radius: 8px;
    padding: 10px;
    font-size: 1.1rem;
    font-family: var(--bold);
    margin-top: 1rem;
    border: none;
    cursor: pointer;


}

.masterclass_button_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.masterclass_Acess_step1_input {
    width: 250px;
    padding: 7px;
    border-radius: 8px;
    border: 2px solid var(--grey);
    margin-top: 2rem;
}

.masterclass_Acess_step1_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-evenly; */
    margin-top: 2rem;
}

.masterclass_body_first_div {
    background-color: white;
    padding: 1.5rem 1.5rem;
    height: fit-content;
    width: 100%;
    box-shadow: 10px 0px 18px -3px rgb(114 192 149 / 35%) !important;
    transition: height 0.5s ease-in;
}

.masterclass_body_left_div {
    width: 400px;
}

.master_subtext {
    color: var(--primary);
    font-family: var(--bold);
}

.barCompleted {
    background-color: var(--primary) !important;

}

.masterclass_left_progress_bar_text {
    margin-bottom: 1rem;
}

.master_videos_container {
    margin-top: 1rem;
}

.master_list_check {
    display: flex;
    flex-direction: column;
    padding: 0 0 40px 0;
}


.master_list_check_cont input[type=checkbox] {
    accent-color: var(--primary);
    cursor: pointer;
    margin-inline: 0.5rem;
    scale: 120%;
}

.masterclass_body_second {
    width: 60%;
    margin: auto;
    margin-top: 2rem;


}

.masterclass_body_divs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;


}

.master_video_ep {
    border-radius: 12px;
    box-shadow: 10px 0px 18px -3px rgb(114 192 149 / 35%) !important;

}

.tooltip-icon {
    margin-left: 10px;
    margin-right: 10px;
}

.styles-module_tooltip__mnnfp {
    width: initial !important;
    left: initial !important
}
.left-align{
    justify-content: left !important;
}