.newsletter_cont {
  background-color: rgb(240, 106, 128, 0.8);
  padding: 30px 50px;
  border-radius: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4%;
}

.newsletter_cont_arabic {
  background-color: rgb(240, 106, 128, 0.8);
  padding: 30px 50px;
  border-radius: 20px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4%;
}

.newsletter-title {
  color: #fff;
  font-family: var(--bold);
  font-size: var(--px-25);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.newsletter-title-arabic {
  color: #fff;
  font-family: var(--bold);
  font-size: var(--px-25);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.newsletter_subtitle {
  color: #fff;
  font-size: var(--px-12);
}

.newsletter_subtitle_arabic {
  color: #fff;
  font-size: var(--px-13);
}

.input-form:focus,
.input-form:active {
  background: transparent;
  color: white
}

.home_subscription{
  display: flex;
  flex-direction: column;
}
.newsletter_form {
  color: white;
  background-color: transparent;
  border: 2px solid #fff !important;
  border-radius: 10px !important;
  align-items: center;
  width: 100% !important;
  /* margin-left: 17rem; */
}

.newsletter_form_arabic {
  color: white;
  background-color: transparent;
  border: 2px solid #fff !important;
  border-radius: 10px !important;
  align-items: center;
  width: 30% !important;
}

.input-form {
  background-color: transparent !important;
  width: 100%;
  border: none;
  background-image: none;
  line-height: 1.5715;
  background-color: inherit;
  font-size: 14px;
  height: 40px;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-indent: 10px;
}

.input-form-arabic {
  width: 100%;
  border: none;
  background-image: none;
  line-height: 1.5715;
  background-color: inherit;
  font-size: 20px;
  height: 40px;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-indent: 10px;
}

textarea:focus,
input:focus {
  outline: none;
}

::placeholder {
  color: #fff;
}

.footer-button-form {
  cursor: pointer;
  background-color: #fff !important;
  color: var(--pink) !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  font-family: bold;
  border-color: inherit;
  border-width: 1px;
  display: flex;
  align-items: center;
}

.footer-button-form-arabic {

  cursor: pointer;

  background-color: #fff !important;
  color: var(--pink) !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  font-family: bold;
  border-color: inherit;
  border-width: 1px;
  display: flex;
  align-items: center;
}

.footer_btn_cont {
  margin-left: 1rem;
}

@media only screen and (max-width: 1000px) {
  .newsletter_cont {
    flex-direction: column;
    width: 100%;
  }

  .newsletter_cont_arabic {
    flex-direction: column;
  }

  .main-footer,
  .main-footer-arabic {
    padding: 10px;
  }

}

@media only screen and (max-width: 1100px) {
  .newsletter_cont {
    width: 85%;
    flex-direction: column;
  }

  .newsletter_cont_arabic {
    flex-direction: column;
  }

  .newsletter_form {
    width: 100% !important;
  }

  .newsletter_form_arabic {
    width: 100% !important;
  }

  .footer-button-form {
    margin-top: 1rem;
  }

  .footer-button-form-arabic {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 660px) {
  .newsletter-title {
    font-size: 14px;
    text-align: center;
  }

  .newsletter-title-arabic {
    font-size: 16px;
    text-align: center;
  }

  .newsletter_subtitle {
    font-size: 12px;
    text-align: center;
  }

  .newsletter_subtitle_arabic {
    font-size: 14px;
    text-align: center;
  }

  .footer-button-form {
    margin-top: 1rem;
    /* width: 70%; */
    text-align: center;
  }

  .footer-button-form-arabic {
    margin-top: 1rem;
    /* width: 70%; */
    text-align: center;
  }

  .newsletter_cont {
    width: 90%;
    margin-block: 2rem;
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 390px) {
  .footer-button-form {
    margin-top: 1rem;
    text-align: center;
  }

  .footer-button-form-arabic {
    margin-top: 1rem;
    text-align: center;
  }

  .input-form-arabic {
    width: 90%;
  }
}

@media (max-width: 350px) {
  .newsletter-title {
    font-size: 13px;
  }
}