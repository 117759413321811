.complete_step6_avail{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.complete_step6_all_avail{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    margin-top: 15px;
    margin-bottom: 15px;
}


.complete_step6_all_avail input[type="radio"]
{
    cursor: pointer;

        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    
}

.complete_step6_all_avail input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--primary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.complete_profile_6_textarea{
    border: 2px solid var(--primary);
    border-radius: 10px;
    width: 50%;
    padding: 0.5rem;
    resize: none;

}

