.create_opp_step4_main_cont {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 350px;
    gap: 2rem
}

.create_opp_radio_step4 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

input[type="radio"] {
    cursor: pointer;

    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;

}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--primary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}


.step1_cont_header {
    margin-bottom: 0.5rem;
}