.companies_inst_main_comp {
    background-image: url("../../assets/images/company_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .company_inst_header {
    padding-top: 8rem !important;
    font-family: bold;
    font-weight: strong;
    font-size: 3rem;
    width: 50%;
    margin: auto;
    text-align: center;
  }
  
  .company_inst_1st_cont {
    display: flex;
    width: 550px;
    justify-content: space-between;
    margin: auto;
    margin-top: 2rem;
  }
  
  .green_color {
    color: var(--primary);
  }
  
  .blue_color {
    color: var(--primaryBlue);
  }
  
  .company_underline_blue {
    width: 262px;
    margin: auto;
    border-bottom: 5px solid var(--primaryBlue);
    border-radius: 25px;
  }
  
  .company_underline_green {
    width: 140px;
    margin: auto;
    border-bottom: 5px solid var(--primary);
    border-radius: 25px;
  }
  
  .company_inst_sub_header {
    cursor: pointer;
  }
  
  
  .company_inst_2nd_cont{
      width:80%;
      margin: auto;
      margin-top: 5rem;
  }
  
  .company_sub_header{
      font-family: bold;
      font-size: 2.5rem;
  }
  
  
  .company_inst_2nd_sub_cont{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  
  .comapny_inst_sub_1st{
      width: 45%;
  }
  .company_inst_2nd_sub_cont_title{
      font-size: 1.7rem;
      font-family: bold;
      margin-bottom: 0.8rem;
  }
  
  .company_inst_2nd_sub_cont_subtitle{
      font-size: 1.1rem;
      font-family: regular;
  }
  
  .company_2nd_sub_img{
      width: 45%;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.08) !important;
      border-radius: 20px;
  }
  
  .company_inst_3rd_cont{
      background-image: url("../../assets/images/mayor_background2.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 35px 50px;
      margin-top: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  
  .company_inst_3rd_cont_title{
      font-size: 1.7rem;
      font-family: bold;
      color: white;
      text-align: center;
  }
  
  .company_inst_3rd_cont_subtitle{
      color: white;
      font-size: 1.7rem;
      text-align: center;
      margin: auto;
      width:65%;
      margin-bottom: 2rem;
      
  }
  
  .company_inst_3rd_cont_span{
      color: white;
      margin: auto;
      font-size: 1.2rem;
      width: 55%;
  
      text-align: center;
  
  
  }
  
  .company_inst_3rd_cont_button{
      background-color: var(--primary);
      color:white;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 10px 25px;
      font-size: 1.2rem;
      border-radius: 15px;
  margin-top: 1.5rem;
  }
  
  .company_inst_4th_cont{
    width: 90%;
    margin: auto;
    margin-top: 10rem;  
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .company_sub_4th_header{
    font-family: bold;
    font-size: 2.5rem;
    width: 75%;
    text-align: center;
    margin: auto;
    line-height: 1.2;
    
  }
  
  .company_video{
    width: 75%;
    border-radius: 10px;
    margin-top: 2rem;
  
  }
  .company_inst_5th_cont{
   width: 70%;
    margin: auto;
    margin-top: 10rem;
  }
  
  .company_sub_5th_header{
    font-family: bold;
    font-size: 2.5rem;
    width: 65%;
    line-height: 1.2;
  
  }
  
  .company_underline{
      width: 140px;
    border-bottom: 10px solid var(--primaryBlue);
    border-radius: 25px;
    margin-top: 10px;
  }
  
  .company_sub_cont{
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  }
  
  .company_4th_sub_img{
    width: 45%;
  
    height: auto;
  }
  
  .company_4th_sub_cont_comp{
    width: 50%;
  }
  
  .company_4th_sub_cont_title{
  
    font-family: bold;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  
  .company_4th_sub_cont_subtitle{
    font-family: regular;
    font-size: 1.1rem;
  }
  
  
  .inst_1st_cont{
    display: flex;
    width: 75%;
    margin: auto;
    margin-top: 5rem;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .inst_1st_img{
  
    width: 50%;
  }
  
  .inst_1st_sub_cont{
    width: 53%;
  }
  
  .inst_1st_sub_title{
    font-size: 2.5rem;
    font-family: bold;
  }
  
  .inst_1st_underline{
    border-radius: 25px;
    border-bottom:8px solid var(--primaryBlue);
  margin: 10px 0 2.5rem 0;
    width:60px;
  }
  
  .inst_1st_list_element{
    
    align-items: center;
    display: flex;
    margin-bottom: 1rem;
  
  }
  
  .inst_1st_list_element img{
    width: 20px;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .inst_1st_list_text{
    font-size: 1rem;
    font-family: regular;
  }
  
  .inst_2nd_underline{
  
    border-radius: 25px;
    border-bottom:8px solid var(--primary);
  margin: 10px 0 2.5rem 0;
    width:150px;
  
    margin: 10px auto;
  }
  
  .inst_2nd_cont{
    margin-top: 10rem;
  }
  
  .inst_2nd_sub_title{
    font-size: 2.5rem;
    font-family: bold;
   text-align: center;
  
  }
  
  .inst_2nd_sub_cont{
    display: flex;
    width: 75%;
    margin: auto;
    justify-content: space-between;
  }
  
  
  .inst_2nd_sub_comp{
    background-color: white;
    padding: 30px 20px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 8%) !important;
    width: 330px;
    border-radius: 15px;
    margin: 0 10px;
    margin-top: 2rem;
  
  }
  
  .inst_2nd_cont_img{
    margin-bottom: 2rem;
    height: 35%;
  }
  
  .inst_2nd_cont_text{
    text-align: center;
    font-size: 1.1rem;
  }
  
  .inst_3rd_cont{
    margin-top: 10rem;
  }
  
  
  .inst_3rd_underline{
  
    border-radius: 25px;
    border-bottom:8px solid var(--primaryBlue);
  margin: 10px 0 2.5rem 0;
    width:150px;
  
    margin: 10px auto;
  }
  
  
  .inst_3rd_sub_cont{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  
  }
  
  .inst_3rd_sub_comp{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  margin-bottom: 5rem;
  
  }
  
  .inst_3rd_sub_comp_text{
    font-size: 1.3rem;
    text-align: center;
    width: 65%;
  }
  .inst_3rd_sub_comp img{
    width: 65%;
    margin-bottom: 1.5rem;
  }
  
  
  ::cue {
    color: red; /* Change the color to red */
    /* You can add other styles here as well */
  }
  @media only Screen and (max-width: 990px) {
    .company_inst_2nd_sub_cont{
      flex-direction: column;
    }
  
    .comapny_inst_sub_1st {
      width: 90%;
      margin-top: 2rem;
  
    }
  
    .company_sub_header{
      text-align: center;
    }
    .company_2nd_sub_img, .company_4th_sub_img{
      width: 70%;
      margin-top: 2rem;
    }
  
  
  
    .company_sub_5th_header{
      width: 90%;
      text-align: center;
      margin: auto;
    }
  
    .company_underline{
      margin: auto;
      margin-top: 1rem;
    }
  
    .company_sub_cont{
      flex-direction: column;
      align-items: center;
    }
  
    .company_4th_sub_cont_comp{
      width: 90%;
      margin-top: 1rem;
    }
  
    .company_inst_header{
      width: 90%;
    }
    .company_sub_4th_header{
      width: 90%;
    }
    .company_video{
      width: 85%;
    }
  
    .inst_1st_cont{
      flex-direction: column;
      align-items: center;
    }
  
    .inst_1st_img , .inst_1st_sub_cont{
      width: 90%;
      margin: auto;
    }
  
    .inst_2nd_sub_cont{
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  
  
  @media only Screen and (max-width: 600px) {
  
  
    .company_inst_1st_cont{
      width: 90%;
      flex-direction: column;
    align-items: center;
    }
  
  .company_inst_header{
    font-size: 2rem;
  }
    .company_sub_header{
      font-size: 2rem;
      width: 95%;
    }
  
    .company_inst_3rd_cont_title{
      font-size: 1.5rem;
    }
  
    .company_inst_3rd_cont_subtitle , .company_inst_3rd_cont_span{
      width: 90%;
      font-size: 1rem;
    }
  
    .company_inst_3rd_cont_button{
      font-size: 1rem;
    }
  
    .company_sub_4th_header{
      font-size: 1.5rem;
  
    }
  
    .company_sub_5th_header{
      width: 90%;
      font-size: 1.5rem;
    }
  
    .company_4th_sub_cont_title{
      font-size: 1.2rem;
    }
  
    .company_4th_sub_img{
  margin-top: 1rem;
  width: 90%;
    }
  
    .inst_2nd_sub_title, .inst_1st_sub_title{
      font-size: 1.5rem;
      text-align: center;
    }
    .inst_1st_underline{
      margin:10px auto;
    }
  
    .inst_3rd_sub_cont{
      flex-direction:column ;
      justify-content: center;
      align-items: center;
    }
  
    .inst_3rd_sub_comp{
      width: 90%;
    }
  
    .inst_3rd_sub_comp_text{
      font-size: 1rem;
      width: 80%;
    }
  }
  .company_inst_header_arabic{
    font-size: 3rem !important;
    font-weight: bold;
  }
  .company_underline_green_arabic{
    width: 80px;
  }
  .company_underline_blue_arabic{
    width: 175px;
  }
  .company_sub_header_arabic{
    font-size: 3rem;
    font-weight: bold;
  }
  .company_inst_2nd_sub_cont_title_arabic{
    font-size: 2rem;
    font-weight: bold;
  }
  .company_inst_2nd_sub_cont_subtitle_arabic{
  font-size: 1.5rem;
  
  }