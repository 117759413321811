.apply_step1 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.apply_step1 .apply_img {
    height: 160px
}

.user_type_options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85%
}

.user_type_option {
    display: flex;
    flex-direction: column;
}


.user_radio_comp {
    display: flex;
    align-items: center;
    gap: 0.5rem
}

input[type=radio].individual_radio,
input[type=radio].group_radio {
    height: 20px;
    width: 20px;
    accent-color: #858585;
}

.apply_step1 .dotter_spacer {
    display: block;
    border-bottom: dotted 5px var(--grey);
    margin: 1rem 0;
    width: 100%
}

.app_step1_next_button {
    width: 30%;
    align-self: flex-end;
}

.user_type_options input[type='radio']:checked:after {
    width: 12px;
    height: 12px;
    border-radius: 15px;
    top: 1px;
    left: 2px;
    right:2px;
    position: relative;
    background-color: var(--primary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
@media only screen and (max-width: 768px) {
    .app_step1_next_button_resp {
        width: fit-content;
        display: flex;
        align-items: center;
        align-self: flex-end;
    }

    .user_type_options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%
    }
    .apply_step1 .apply_img {
        width: 160px ;
        height: auto;
    }
    
}