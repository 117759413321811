.complete_step4_sectors_cont{
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    width: 100%;
    gap:2rem 1.5rem ;
    justify-content: flex-start;


}
.complete_step4_sector{
    display: flex;
    align-items: center;
    width: 165px;
}

.complete_step4_sector_details{
color: var(--primary);
font-size: 0.7rem;
cursor: pointer;
}
.complete_step4_sector input[type="checkbox"]{
    width: 25px;
    height: 25px;
    accent-color: var(--primary);
   border: 2px solid var(--primary);
   margin-inline: 0.5rem;
cursor: pointer;

}
.complete_step4_popup_header{
    font-family: bold;
}
.complete_step4_popup_body{
    margin-top: 2rem;
}

.complete_step4_popup_name{
    font-family: bold;
}

.complete_step4_sector  .body_text{

text-align: left;
align-items: flex-start !important;
justify-content: flex-start !important;
}

.complete_step4_sector  .modal-content{
margin-top: 10%;
}