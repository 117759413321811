.Main_mobile_header_ar {
  transition: all 2s ease-in-out;
  height: 200vh;
  width: 85%;
  background: #f8f8f8;
  position: fixed;
  z-index: 22;
  top: 0;
  right: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0px 0px 5px rgb(0 0 0 / 20%) !important;
}

@media (min-width: 1260px) {

  .Main_mobile_header,
  .Main_mobile_header_ar {
    display: none;
  }
}

@media (max-width: 1260px) {
  .navbar-nav {
    text-align: right;
  }

  .Main_mobile_header {
    transition: all 2s ease-in-out;
    height: 100vh;
    width: 85%;
    background: #f8f8f8;
    position: fixed;
    z-index: 22;
    top: 0;
    right: 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0px 0px 5px rgb(0 0 0 / 20%) !important;
    overflow: scroll;

  }

  .Main_mobile_header_ar {
    transition: all 2s ease-in-out;
    height: 100vh;
    width: 85%;
    background: #f8f8f8;
    position: fixed;
    z-index: 22;
    top: 0;
    right: 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0px 0px 5px rgb(0 0 0 / 20%) !important;
    overflow: scroll;
  }

  .navbar,
  .navbar-nav {
    color: #000000;
    font-size: 1.1em;
    padding-right: 1em;
    margin-top: 3rem;
    margin-bottom: 3rem;
    height: 500px;
    width: 300px;
  }

  .navbar,
  .navbar-nav,
  .nav-link:hover {
    color: #808080;
  }

  .logo_mobile {
    height: 100px;
    cursor: pointer;
  }

  .dotter_spacer {
    border-bottom: dotted 5px var(--grey);
    margin: 1rem 0;
  }

  .navbar-nav {
    width: 100%;
  }

  .nav-link {
    color: #000000;
    padding-right: 1em;
    text-decoration: none;
    font-size: 1.3rem;
    font-family: bold;
    white-space: nowrap;
    display: flex;
    align-items: flex-start;
  }
}

.close_butt {
  color: var(--pink);
}

.logo_container {
  display: flex;
}

.logo_container_ar {
  display: flex;
}

.close_container {
  display: flex;
  margin: auto;
  margin-right: 1.5rem;
  cursor: pointer;
}

.close_container_ar {
  margin-right: 8rem;
}

@media (max-width: 700px) {
  .Main_mobile_header_ar {
    transition: all 2s ease-in-out;
    height: 200vh;
    width: 65%;
    background: #f8f8f8;
    position: fixed;
    z-index: 22;
    top: 0;
    right: 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0px 0px 5px rgb(0 0 0 / 20%) !important;
  }

  .close_container_ar {
    margin-right: 5rem;
  }

}

@media (max-width:500px) {

  .close_container_ar {
    margin-right: 2rem;
  }

  .logo_container_ar {
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .nav-link {
    font-size: 1rem;
  }

  .logo_mobile {
    height: 70px;
  }
}

.language-selected-mobile {
  font-size: 1.2rem !important;
  color: var(--secondary);
  font-size: var(--px-14);
  text-transform: uppercase;
  font-family: var(--bold);
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}