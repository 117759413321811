.create_opp_step2_main_cont {
    display: flex;
    width: 90%;
    justify-content: space-between;
    min-height: 350px;



}

.create_opp_note_dark {
    color: var(--secondary);
    font-size: 0.8rem;
    margin-top: 5px;
    font-family: regular;
}


.create_opp_checkbox_main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

}

.step2_create_opp {
    display: flex;
    gap: 0.5rem
}

.step8_create_opp {
    display: flex;
    flex-direction: row;
    gap: 0.5rem
}


.create_opp_checkbox_main input[type="checkbox"] {
    width: 25px;
    height: 25px;
    accent-color: var(--primary);
    border: 2px solid var(--primary);
    margin-right: 0.5rem;
    cursor: pointer;

}

.step2_create_opp_name {
    font-family: regular;
}

.create_opp_note_loc {
    display: flex;
    align-items: center;
    cursor: pointer;

}

.create_opp_step1_cont .complete_profile_select {
    width: 100% !important;

}