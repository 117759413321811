.Main_opp_requests {
    margin-top: 5rem;
}

.opp_requests_filters_container {
    width: 330px;
}

.opp_requests_main_cont {
    display: flex;
    /* justify-content: space-between; */

}


.opp_requests_table_container {
    width: 75%;
    margin-top: 2rem;
    margin-left: 4rem;

}

.opp_requests_table_container_ar {
    width: 75%;
    margin-top: 2rem;
    margin-right: 4rem;
}

.opp_requests_star {
    width: 30px;
    height: 30px;
}

.opp_requests_start_info {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
}

.opp_requests_start_info_text {
    margin-left: 1rem;
    font-size: 1rem;
    width: 62%;
}

.opp_requests_start_info_text_ar {
    margin-right: 1rem;
    font-size: 1.2rem;
    width: 60%;
}

.opp_filters_comp_resp,
.filter_title_resp {
    display: none;
}

.opp_requests_card {
    width: 450px;
    background-color: white;
    padding: 15px 25px 5px 25px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
    border-radius: 10px;
    height: fit-content;
    margin-bottom: 2rem;
}

.opp_eval_requests_cards_container {
    width: 450px;
    margin-top: 2rem;
    background-color: white;
    padding: 15px 25px 5px 25px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
    border-radius: 10px;
    height: fit-content;
}




.opp_requests_card_level_logo {
    width: 30px;
    height: 30px;
    border-radius: 40px;
    border: 2px solid var(--grey);
}

.opp_requests_card_header {
    display: flex;
}

.opp_requests_card_text {
    margin-left: 1rem;


}

.opp_requests_card_text_title {
    font-size: 1.2rem;
    font-family: var(--bold);
}

.opp_requests_card_text_subtitle {
    margin-top: 10px;
}

.opp_requests_card_text_subtitle_2 {
    color: var(--pink);
    margin-top: 10px;
}

.action_logo {
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 0.5;

}

.action_logo:hover {
    opacity: 2;
}



.opp_requests_card_body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3rem;
    justify-content: space-around;
}

.opp_requests_card_body_action_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.opp_requests_card_body_action_text {
    margin-top: 10px;
    margin-bottom: 10px;
}

.opp_requests_cards_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;

}

.Main_opp_requests .upper_section_sub {
    display: flex;
    position: relative;
    line-height: 1;
}

.Main_opp_requests .notification-badge{
    top: -20px;
    right: -12px;
    height: 20px;
}

@media only screen and (max-width: 800px) {
    .filter_title_resp {
        display: block !important;
    }

    .opp_requests_filters_container {
        display: none;
    }

    .opp_filters_comp_resp {
        display: block !important;
        width: 330px;
        position: absolute;
        z-index: 2;
    }

    .opp_filters_comp {
        display: none;
    }

    .filter_title_resp {
        font-family: bold;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        padding: 15px;
        position: absolute;
    }

    .filters_close {
        position: absolute;
        display: block;
        color: var(--primary);
        display: flex;
        align-items: center;
        cursor: pointer;
        right: 0.5rem;
        top: 8px !important;
    }

    .filters_header {
        margin-top: 2rem;
    }

    .opp_main_sec {
        margin-top: 3rem;
    }

    .opp_requests_table_container {
        margin-left: 12rem;
    }

}

@media (max-width:650px) {
    .opp_requests_card_level_logo {
        width: 53px;
        height: 42px;
    }

    .opp_requests_card {
        width: 90%;
    }

    .opp_requests_cards_container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        align-items: center;
        justify-content: center;

    }

    .opp_requests_table_container {
        margin-top: 6rem;
        margin-left: 4rem;
        width: 100%;
    }

    .opp_requests_start_info_text {
        width: 100%;
    }

}



@media (max-width:360px) {
    .opp_requests_table_container {
        margin-top: 6rem;
        margin-left: 2rem;
    }

    .opp_filters_comp_resp {

        width: 100%;

    }
}

@media (max-width:320px) {
    .opp_requests_table_container {
        margin-top: 6rem;
        margin-left: 1rem;
    }

    .opp_requests_card {
        width: 250px;
    }

    .opp_requests_card_level_logo {
        width: 88px;
        height: 42px;
    }
}



.opp_eval_requests_container {
    width: 75%;
    margin: auto;
    margin-top: 4rem;
}

.opp_requests_card_button {
    width: 400px;
    border-radius: 12px;
    height: 40px;
    border: none;
    background-color: var(--primary);
    color: white;
    font-size: 1.2rem;
    cursor: pointer;

}

.opp_requests_card_button_container {
    margin-bottom: 2rem;
}

.opp_eval_card_level_logo {
    width: 50px;
    height: 38px;
    border-radius: 40px;
    border: 2px solid var(--grey);
}

.opp_eval_requests_cards_container_all {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}

@media (max-width:550px) {
    .opp_eval_requests_cards_container {
        width: 90%;
    }

    .opp_requests_card_button {
        width: 200px !important;
    }

    .opp_eval_card_level_logo {
        width: 76px;
        height: 40px;
    }
}

@media (max-width:280px) {
    .opp_requests_card_button {
        width: 120px !important;
    }

    .opp_eval_requests_cards_container {
        width: 100%;
    }

    .opp_eval_card_level_logo {
        width: 110px;
        height: 32px;
    }
}

.opp_requests_card_text_score {
    width: fit-content;
    background-color: var(--primary);
    border-radius: 8px;
    color: white;
    padding: 2px;
    font-size: 1rem;
}