.eval_cert_header {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.eval_cert_button {
  color: var(--secondary);
  background-color: white;
  border: 1px solid var(--primary);
  border-radius: 30px;
  outline: none;
  font-size: 1.1rem;
  padding: 0.2rem 1.5rem;
  cursor: pointer;
  min-width: 110px
}

.eval_cert_button:hover {
  background-color: var(--primary);
  color: white;
}

.eval_cert_active_btn {
  background-color: var(--primary);
  color: white;
}

.evaluation_container {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.evaluation_card {
  width: 400px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 15px;
  padding: 1.4rem 1rem;
  display: flex;
  text-decoration: none;
}

.evaluation_card_title {
  font-size: 1.2rem;
  font-family: bold;
  text-decoration: none;
  color: black;
}

.evaluation_record_img {
  width: 45px;
  height: 45px;
}

.evaluation_cont2 {
  align-self: flex-end;
}

.evaluation_cont1 {
  width: 80%;
}

.evaluation_card_title {
  font-family: bold;
}

.evaluation_card_date {
  margin-top: 2rem;
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 320px) {
  .evaluation_cont2 {
    align-self: center;
  }

  .evaluation_cont1 {
    width: 100%;
  }

  .evaluation_card {
    flex-direction: column;
  }

  .evaluation_container {
    width: 90%;
  }
}