.emailinput_master {
    border-radius: 8px;
    border: 2px solid var(--grey);
    width: 300px;
    height: 38px;
    padding-left: 17px;
}

.button_add_Master {
    border-radius: 50%;
    background-color: var(--primary);
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    margin-left: 0.5rem;
    cursor: pointer;
    margin-top: 4px;
}

.emailinput_master_error {
    color: var(--pink);
}

.Main_emailinput_master {
    display: flex;
    margin-top: 2rem;
}