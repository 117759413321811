.register_main_container {
  background-image: url("../../../src/assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 3rem;
  margin-bottom: -5rem !important;
}

.register_card_main {
  width: 460px;
  margin: 2rem auto;
  position: relative;
}
.register_card {
  position: relative;
  background-color: white;
  padding: 50px 20px;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.register_astro {
  position: absolute;
  top: 0;
  right: -190px;
  width: 220px;
  z-index: 0;
}

.register_image {
  width: 70px;
}


.register_image2 {
  height: 110px;
  margin-bottom: 1rem;
}

.register_input_cont {
  width: 90%;
  margin: auto;
}

.register_title {
  font-family: bold;
}
.register_subtitle {
  width: 70%;
}

.register_footer_title{
  font-size: 0.9rem;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
}
.register_footer_subtitle{
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  font-size: 0.9rem;
  margin-top: 0 !important
}
.register_button_cont,
.register_button_login_cont,
.register_button_create_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.register_button_cont button {
  width: 47%;
}
.register_button_login_cont button {
  width: 100%;
  margin-bottom: 1.5rem;
}

.register_button_create_cont button {
  width: 100%;
}

.register_note {
  margin-top: 1.5rem !important;
  font-size: 0.9rem;
}

.register_error{
  font-size: 0.8rem;
  color: var(--pink);
  margin-bottom: 1rem;
}

.register_sent{  
  margin-top: 0.2rem;
  font-size: 0.8rem;
  color: var(--primaryBlue);
  margin-bottom: 1rem;}

.register_otp_cont{
margin:3rem 0;
}
@media only Screen and (max-width: 850px) {
  .register_astro {
    display: none;
  }
}

@media only Screen and (max-width: 480px) {
  .register_card_main {
    width: 90%;
  }

  .register_button_cont {
    flex-direction: column;
  }

  .register_subtitle {
    width: 100%;
  }

  .register_button_cont button {
    width: 100%;
    margin-bottom: 1rem;
  }
}
