.apply_step7 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.apply_step7 .apply_img {
    width: 180px;
}

.apply_step7 .appointment_img {
    width: 20px;
    height: 20px;
    margin-top: 10px;
    margin-right: 10px;
}

.apply_step7 .dotter_spacer {
    display: block;
    border-bottom: dotted 5px var(--grey);
    margin: 1rem 0;
    width: 100%
}

.app_step7_next_button {
    width: 30%;
    align-self: flex-end;
}

.apply_step7 .apply_primary_button {
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    padding: 7px 12px;
    border: none;
    outline: none;
    cursor: pointer;
}

.app_step7_next_button,
.app_step7_apply_later_button {
    width: 50%;
    align-self: flex-end;
}

.app_step7_apply_later_button,
.app_step7_apply_later_button_resp {
    background-color: var(--pink) !important;
    margin-right: 20px;
    margin-left: 20px;
}

.apply_step7 .avalability_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%
}

.apply_step7 .dates_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:10px
}

.apply_step7 .dates_div_cont {
    display: flex;
    flex-direction: column;
}

.apply_step7 .grey_vertical_border {
    border-left: 2px solid var(--grey);
    min-height: 30px;
    position: relative;
    max-width: 100%;
}

.apply_step7 .frequency_div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.apply_step7 .font-14 {
    font-size: var(--px-14) !important
}

.apply_step7 .complete_step_footer_button {
    display: flex;
    justify-content: right;
    width: 100%;
}
.apply_step7 .div_buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
}
@media only screen and (max-width: 612px) {
    .app_step7_next_button_resp {
        width: fit-content;
        display: flex;
        align-items: center;
        align-self: flex-end;
    }
    .apply_step7 .avalability_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%
    }
    .apply_step7 .grey_vertical_border {
        display: none !important;
    }

}
@media only screen and (max-width: 644px) {
    .apply_step7 .div_buttons{
        width : 100%
    }
    }

