.blog_green_header {
    background-color: var(--primary);
    transition: all .5s;
    padding-top: 5.5rem;
}


.blog_green_header_container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 40px 0px;
    align-items: center;
}

.profile_title {
    text-align: center;
}

.grey_vertical_border {
    border-left: 2px solid var(--grey);
    min-height: 30px;
    position: relative;
    max-width: 100%;
}


.sub_menu {
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
}

.disabled-header-button {
    color: var(--grey);
}

.disabled-header-button:hover {
    color: var(--white);
}

.blog_green_header .dotter_spacer {
    display: none
}

@media (max-width: 768px) {

    .profile_title {
        text-align: unset;
    }

    .sub_menu {
        flex-direction: column;
    }

    .grey_vertical_border {
        display: none;
        min-height: 20px;
    }
    .blog_green_header .dotter_spacer {
        display: block;
        border-bottom: dotted 5px var(--grey);
        margin: 1rem 0;
        width:100%
    }
}