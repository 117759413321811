.Main_community_action {
    width: 100%;
    margin: auto;
}

.create_button_act {
    color: var(--primary);
    width: fit-content;
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.community_action_cards_container, .no_requests {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    flex-wrap: wrap;
}

.call_requests_main_cont {
    display: flex;

}

.call_requests_table_container {
    width: 75%;
    margin-left: 4rem;
}

.call_ongoing_table_container {
    width: 75%;
    margin:auto;
}

.community_action_cards_container2 {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    margin-top: 3rem;
}