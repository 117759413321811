#file-loader {
    padding: 5rem;
    text-align: center;
}

#file-loader h2 {
    margin-top: 1rem;
    font-family: 'Work Sans', sans-serif;
}

#file-loader svg {
    background-color: #FFFFFF;
}

#mask {
    fill: #F7F7F7;
}

#folder {
    animation: right-to-left 3s cubic-bezier(.8, 0, .2, 1);
    animation-iteration-count: infinite;
}

.main_after {
    margin-top: 6rem;
}

@keyframes right-to-left {
    0% {
        transform: translateX(144px);
    }

    33% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(0);
    }

    66% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-144px);
    }
}

#folder-base {
    fill: #AFABED;

}

#folder-top {
    fill: #EBEAFA;
}



#file-1 {
    fill: #FFFFFF;
    width: 80px;
    height: 40px;
    animation: inspect-file-1 3s ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes inspect-file-1 {
    0% {
        transform: translateY(0);
    }

    33% {
        transform: translateY(0);
    }

    39% {
        transform: translateY(-16px);
    }

    44% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

#file-2 {
    fill: #F7F7F7;
    width: 76px;
    height: 40px;
    animation: inspect-file-2 3s ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes inspect-file-2 {
    0% {
        transform: translateY(0);
    }

    44% {
        transform: translateY(0);
    }

    49% {
        transform: translateY(-16px);
    }

    55% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

#file-3 {
    fill: #EBEBEB;
    width: 72px;
    height: 40px;
    animation: inspect-file-3 3s ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes inspect-file-3 {
    0% {
        transform: translateY(0);
    }

    55% {
        transform: translateY(0);
    }

    60% {
        transform: translateY(-16px);
    }

    66% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}