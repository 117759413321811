.Main_ngo_feedbacks {
    width: 75%;
    margin: auto;
    margin-top: 8rem;
}

.volname_ngofeed {
    font-size: 0.9rem;
    color: var(--primary);
}

.volname_ngofeed_name {
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-family: var(--bold);

}

.ngo_feedbacks_body {
    margin-top: 4rem;
}

.ngo_feedbacks_header{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ngo_feedbacks_header_div{
    display: flex;
    flex-direction: column;
}
.ngo_feedbacks_body_header {
    font-size: 1.5rem;
    font-family: var(--bold);
    color: var(--primary);
}

.ngo_feedbacks_body_textarea {
    width: 70%;
    height: 250px;
    border: 2px solid var(--grey);
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 1rem;
    font-family: var(--regular);
    resize: none;
}

.ngo_feedbacks_body_name_cont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.scale_container_color {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.scale_container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.scale_container_text {
    font-size: 1.1rem;
    font-family: var(--regular);
}

.g1 {
    background-color: var(--dark);
}

.cq {
    color: var(--dark);
}

.g2 {
    background-color: var(--pink);
}

.cw {
    color: var(--pink);
}

.g3 {
    background-color: var(--dark-orange);
}

.ce {
    color: var(--dark-orange);
}

.g4 {
    background-color: var(--yellow);
}

.cr {
    color: var(--yellow);
}

.g5 {
    background-color: var(--primary);
}

.ct {
    color: var(--primary);
}

.marginss {
    margin-top: 2rem;
}

.title_body_cont {
    width: 100%;
}

.title_cont {
    margin-top: 2rem;
    font-family: var(--bold);
    font-size: 1.1rem;
}

.subtitle_cont {
    width: 50%;
    margin-top: 1rem;
}

.info_cont_feeds {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    margin-top: 2rem;
}

.circular_div {
    border-radius: 50%;
    border: 4px solid var(--dark);
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.circular_div0 {
    border-radius: 50%;
    border: 4px solid var(--dark);
    width: 35px;
    height: 35px;
    cursor: pointer;
}


.circular_div1 {
    border-radius: 50%;
    border: 4px solid var(--pink);
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.circular_div2 {
    border-radius: 50%;
    border: 4px solid var(--dark-orange);
    width: 65px;
    height: 65px;
    cursor: pointer;
}

.circular_div3 {
    border-radius: 50%;
    border: 4px solid var(--yellow);
    width: 80px;
    height: 80px;
    cursor: pointer;
}

.circular_div4 {
    border-radius: 50%;
    border: 4px solid var(--primary);
    width: 95px;
    height: 95px;
    cursor: pointer;
}

.ngo_feedbacks_body_textarea1 {
    width: 30%;
    height: 60px;
    border: 2px solid var(--grey);
    border-radius: 12px;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 1rem;
    font-family: var(--regular);
    resize: none;
}

.ngo_feedbacks_body_2 {
    margin-top: 3rem;
    display: flex;
    align-items: center;

}

.question_mark {
    color: var(--primary);
    cursor: pointer;
    margin-left: 10px;
}

.emojie_logo {
    width: 60px;
    height: 60px;
    cursor: pointer;

}

.emojie_logo_selected {
    width: 60px;
    height: 60px;
    opacity: 1;

}



.feedbacks_page_emojies_row_1_text_1 {
    margin-top: 2rem;
    font-size: 0.9rem;

}

.feedbacks_page_emojies_row_2 {
    opacity: 0.5;
}

.feedbacks_page_emojies_row_2_active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.feedbacks_page_emojies_row_2:hover {
    opacity: 1;
}


.feedbacks_page_emojies_row_2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.ngo_feedbacks_body_emojie_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 70%;
    margin-top: 4rem;
    gap: 1rem;
}

.ngo_feedbacks_footer {
    margin-top: 4rem;
    display: flex;
    justify-content: flex-end;
    width: 70%;
}

.ngo_feedbacks_footer_button {

    width: 20%;
    height: 40px;
    background-color: var(--primary);
    color: var(--white);
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-family: var(--bold);
    cursor: pointer;
}


.popup_body_header {
    font-size: 1.5rem;
    font-family: var(--bold);

}

.emojie_logo_selected {
    width: 70px;
    height: 70px;
    opacity: 1;
}

.popup_body_emoji_info_cont {
    margin-top: 2rem;
}

.popup_body {
    padding: 20px;
}

.popup_body_emoji_info {
    display: flex;
    align-items: center;

}

.popup_body_emoji_info_2 {
    margin-top: 3rem;
    display: flex;
    align-items: center;
}

.Main_ngo_feedbacks .modal .modal-content {
    width: 60%;

}

.popup_body_emoji_info_desc {
    margin-top: 10px;
}

.popup_body_emoji_info_title {
    margin-left: 1rem;
    font-family: var(--bold);
}

.feedbacks_page_emojies_row {
    width: 100px;
}

@media (max-width:760px) {
    .ngo_feedbacks_body_emojie_container {
        width: 100%;
    }
    .ngo_feedbacks_header{
        display: flex;
        flex-direction: column !important;
        justify-content: space-between;
    }
}

@media (max-width:450px) {
    .popup_body_emoji_info {
        flex-direction: column;
    }

    .popup_body_emoji_info_2 {
        flex-direction: column;
    }

    .popup_body_emoji_info_title {

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .ngo_feedbacks_footer_button {
        width: fit-content;
    }

    .ngo_feedbacks_body_textarea {
        width: 100%;
    }

    .Main_ngo_feedbacks .modal .modal-content {
        width: 90%;

    }

    .popup_body_emoji_info_title {
        margin-left: 0rem;
        font-family: var(--bold);
    }

    .popup_body_emoji_info_desc {
        text-align: center;
        margin-top: 20px;
    }
}