.complete_profile_main_cont {
  background-image: url("../../../src/assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 3rem;
  margin-bottom: -5rem !important;
}

.complete_profile_card_main {
  width: 800px;
  margin: 2rem auto;
  position: relative;
}
.complete_profile_card {
  position: relative;
  background-color: white;
  padding: 30px 25px 40px 25px;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  min-height: 550px;

}



.complete_profile_astro {
  position: absolute;
  top: 0;
  right: -130px;
  width: 150px;
  z-index: 0;
}

.complete_profile_title {
  font-family: bold;
  font-size: 1.5rem;
}
.complete_profile_sub_title {
  margin-top: -0.2rem;
  font-size: 1rem;
}

.complete_profile_input {
  background-color: white;
  border: 2px solid var(--border);
  border-radius: 10px;
  padding: 6px 15px;
  margin-bottom: 1rem;
  width: 30%;
}

.complete_profile_input:hover {
  border: 2px solid var(--secondary) !important;
}
.complete_profile_input ::placeholder {
  color: var(--border);
}
.complete_date_input {
  cursor: pointer;
  width: 100% !important;
}

.complete_profile_footer_slider{
  z-index: 0;
  margin-top: -1rem;
  width: 100%;
  height: 35px;
  background-color: var(--border);
  position: relative;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

}

.complete_profile_footer_slider_green{
  height: 100%;
  background-color: var(--primary);
  position: relative;
  z-index: 3;
  border-bottom-left-radius: 15px;
}

.complete_profile_footer_slider_green_ar{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 15px;


}
@media only screen and (max-width: 1070px) {
  .complete_profile_astro {
    display: none;
  }

  .complete_profile_card_main {
    width: 85%;
    margin: auto;
  }

  .complete_date_input {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .complete_profile_input,
  .complete_profile_select {
    width: 45% !important;
  }
  .note_msg{
    width: 100% !important;
  }
  .complete_step1_body1 .DatePicker {
    width: 45% !important;
  }

  .complete_date_input {
    width: 100% !important;
  }
  .complete_step9_textarea{
    width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .complete_profile_input,
  .complete_profile_select {
    width: 95% !important;
  }

  .complete_step1_body1 .DatePicker {
    width: 95% !important;
  }
  .complete_date_input,
  .complete_input_sub_header {
    width: 100% !important;
  }

  .complete_input_header {
    width: fit-content;
  }
  .complete_step1_body1 {
    justify-content: center !important;
  }

  .complete_step1_body2 {
    gap: 1rem !important;
  }

  .complete_step1_body3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .complete_step_1_next_button {
    width: 55% !important;
  }.inputs_inner{
  flex-direction: column !important;
}
  
}
