.complete_step2_main_body {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: space-between;
  gap: 1rem;
}

.complete_step2_occupation_cont {
  height: 100%;
}

.complete_field_input_cont {
  display: flex;
  align-items: baseline;
}

.complete_step2_main {
  display: flex;
  flex-direction: column;
}

.complete_step2_all_input {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.complete_field_input_cont {
  width: 30%;
}

.complete_input_fields {
  width: 100% !important;
}

.complete_step2_add {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  color: var(--primary);
}
.complete_step2_close {
  color: var(--pink);
  cursor: pointer;
}

.complete_step_footer_button {
  display: flex;
  justify-content: space-between;
}

.complete_step_right_footer {
  justify-content: flex-end;
  width: 70%;
  gap: 2rem;
  align-self: flex-end;
  display: flex;
}

.complete_step_2_next_button {
  width: 30%;
  align-self: flex-end;
}

.complete_step_right_footer_res,
.complete_step_right_footer_resp {
  display: none;
}

@media only screen and (max-width: 768px) {
  .complete_field_input_cont {
    width: 80%;
  }

  .complete_step2_main_body .complete_profile_select {
    width: 80% !important;
  }
  .complete_step_right_footer,
  .complete_step_1_next_button {
    display: none;
  }

  .complete_step_right_footer_resp {
    justify-content: flex-end;
    width: 90%;
    gap: 2rem;
    align-self: flex-end;
    display: flex;
  }

  .complete_step_2_next_button_resp {
    width: fit-content;
    display: flex;
    align-items: center;
    align-self: flex-end;
  }

  .complete_step2_main_body .complete_profile_select
  {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .complete_step2_main_body .complete_profile_select {
    width: 90% !important;
  }

  .complete_step2_all_input {
    justify-content: center;
  }

  .complete_field_input_cont {
    width: 95%;
  }
}
