.blog_card_main_cont{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-content: center;
    border: 1px solid var(--border);
    padding: 15px 5px;
    border-radius: 10px;
gap: 0.5rem;

}

.blog_card_header{
    display: flex;
    flex-direction: column;

}

.blog_card_image{
    width: 250px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.blog_card_date{
    font-size: 0.8rem;
    padding-left: 5px;

}

.blog_card_title{
font-family: bold;
}

.blog_card_readmore{
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--primary);
    font-size: 0.9rem;
    padding: 0;
    cursor: pointer;
    margin-top: -10px;
}
.blog_card_info{
    display: flex;
    flex-direction: column;
    padding-left: 5px;

    align-items: flex-start;
}
.blog_card_readmore:hover{

    text-decoration: underline;

}