.volunteerSpotLight_detail_info {
    width: 75%;
    margin: auto;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.volunteerSpotLight_detail_header {
    font-family: bold;
}

.volunteerSpotLight_img {
    height: 450px;
    object-fit: cover;
    border-radius: 10px;
}

.spotLightsDetails_sub_cont {
    padding-bottom: 1rem;
    margin-bottom: 1rem;

}

.spotLightsDetails_sub_cont_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 3px solid var(--pink);

}

.spotLightsDetails_sub_cont_title {
    font-family: bold;
    font-size: var(--px-18);
    padding : 12px 40px 12px 16px
}


.spotLightsDetails_icon_arrow {
    transition: all 0.6s ease-in-out;
    width:50px

}

.spotLightsDetails_rotate_arrow {
    transform: rotate(180deg);
    transition: all 0.5s ease-in-out;
}


.spotLightsDetails_list_check {

    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 0 0.2rem;

}

.questions_answers_container {
    width: 75%;
    margin: auto
}
.spotLightsDetails_container{
    margin:40px 0px
}