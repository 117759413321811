.home_main_section {
  position: relative;
  padding-top: 5rem;
  width: 100%;
  background-image: url("../../assets/images/mayor_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel_img {
  object-fit: cover;
}

.carousel_div {
  object-fit: cover;
  position: relative;
}

.carousel .slide .legend {
  position: absolute !important;
  bottom: 14px !important;
  /* top: 10% !important; */
  left: 12% !important;
  right: 10% !important;
  margin: auto !important;
  margin-left: 0 !important;
  width: 75% !important;
  border-radius: 10px !important;
  color: #fff !important;
  padding: 10px !important;
  font-size: 12px !important;
  text-align: center !important;
  background-color: transparent !important;
  opacity: 1 !important;
  font-size: 30px !important;
  font-family: var(--bold) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

.carousel .slide img {
  height: 600px;
  object-fit: cover;
}

.home_popup_body {
  font-size: 1.5rem;
  font-family: bold;
  text-align: center;
}

.carousel_button_home {
  font-size: 1rem !important;
  width: fit-content;
  height: 50px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  background-color: var(--pink);
  text-align: center;
  border-color: transparent;
  padding: 12px 15px;
  display: flex;
  margin-top: 2%;
  cursor: pointer;
}

.carousel_button_home:hover {
  background-color: #ec7f91;
}

.carousell_imgs {
  object-fit: cover;

  height: 88vh;
}

.carousel .control-dots {
  margin: 10px 0 !important;
  margin-left: 44% !important;
}

.homepage_section {
  margin: auto;
  margin-top: 5rem;
  padding: 0 50px;
  width: 75%;
}

.react-tel-input .form-control {
  height: 40px !important;
  border: 1px solid #767676 !important;
}

.imv_title {
  font-size: 2.5rem;
  font-family: var(--bold);
}

.horizontal_rw {
  width: 134px;

  margin-top: 2rem;
  border-bottom: 10px solid var(--primary);
  border-radius: 20px;
}

.imv_section2 {
  margin-top: 4rem;
  display: flex;
}

.green-number-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 3px solid var(--primary);
  background-color: var(--primary);
  font-family: var(--bold);
  padding: 16px;
  margin: 0px 8px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.text-container {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.img-section-big-1 {
  object-fit: contain;
  width: 100%;
  margin-left: 5rem;
}

.parags_container {
  width: 60%;
}

.img_section {
  margin: auto;
  margin-top: -6rem;
}

.imv_butt {
  width: fit-content;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  border-color: transparent;
  text-align: center;
  margin-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  color: white;
}

.green_color_home {
  background-color: var(--primary);
}

.pink_color {
  background-color: var(--pink);
}

.horizontal_rw_black {
  width: 100%;
  margin-top: 8rem;
  border-bottom: 2px solid black;
  border-radius: 20px;
}

.red-number-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 3px solid var(--pink);
  background-color: var(--pink);
  font-family: var(--bold);
  padding: 16px;
  margin: 0px 8px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.horizontal_rw_pink {
  width: 134px;
  margin: auto;
  margin-top: 2rem;
  border-bottom: 10px solid var(--pink);
  border-radius: 20px;
  margin-right: 28%;
}

.iog_section {
  margin-top: 5rem;
}

.iog_title {
  font-size: 2.5rem;
  font-family: var(--bold);
  display: flex;
  justify-content: flex-end;
}

.parags_container_iog {
  width: 50%;
  margin-left: 21%;
}

.iog_section2 {
  display: flex;
  margin-top: 5rem;
}

.img_section_iog {
  width: 45%;
  margin: auto;
  margin-top: -3rem;
}

.blue_color_iog {
  background-color: var(--primaryBlue);
}

.iog_butt {
  width: fit-content;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  border-color: transparent;
  text-align: center;
  margin-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  color: white;
}

.iog_buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 5rem;
}

.black-section {
  margin-top: 10rem;
}

.img-big {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 5rem;
  margin-left: 5rem;
}

.black-section-item-container {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  row-gap: 20px;
  gap: 1.5rem;
  flex-flow: row wrap;
}

.content1_card {
  background-color: var(--darker-blue);
  border-radius: 14px;
  padding: 40px 30px;
}

.content2_card {
  background-color: var(--pink);
  border-radius: 14px;
  padding: 40px 30px;
}

.bottom_row_content1 {
  background-color: var(--darker-blue);
  border-radius: 14px;
  padding: 40px 30px;
  width: 275px;
}

.bottom_row_content2 {
  background-color: var(--black);
  border-radius: 14px;
  padding: 40px 30px;
  width: 275px;
}

.bottom_row_content3 {
  background-color: var(--primary);
  border-radius: 14px;
  padding: 40px 30px;
  width: 275px;
}

.bottom_row_content4 {
  background-color: var(--pink);
  border-radius: 14px;
  padding: 40px 30px;
  width: 275px;
}

.img-big2 {
  width: fit-content;
  max-height: 100px;
  object-fit: contain;
}

.Contents_container1 {
  width: 560px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.bottom_row_cont {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  row-gap: 20px;
  flex-flow: row wrap;
  gap: 1rem;
}

.content1_bottom {
  font-size: 2rem;
  font-family: var(--bold);
  text-align: center;
  margin-top: 3rem;
  color: white;
}

.content1 {
  font-size: 2.5rem;
  font-family: var(--bold);
  text-align: center;
  margin-top: 10rem;
  color: white;
}

.content2 {
  font-size: 1rem;
  font-family: var(--bold);
  /* text-align: center; */
  /* margin-top: 10rem; */
  color: white;
}

.Contents_container {
  /* width: 20%; */
  align-items: center;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.bottom_row_cont .Contents_container {
  white-space: initial !important;
}

.black_section_button {
  margin: auto;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: var(--primary);
  color: white;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  border-color: transparent;
  text-align: center;
}

.home_section3 {
  margin-top: 6rem;
  position: relative;
}

.section3_container {
  font-family: var(--bold);
  color: var(--white);
  position: absolute;
  bottom: 50px;
  left: 0;
  padding: 0 17%;
  z-index: 5;
  font-size: 35px;
  text-align: center;
  text-shadow: 3px -3px 0px rgba(0, 0, 0, 0.5);
}

.img-section {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 500px;
}

.section3_hr {
  margin: auto;
  /* margin-top: 5rem; */
  /* position: absolute; */
  top: 50px;
  left: 45%;
  z-index: 5;
}

.black_section_button a {
  text-decoration: none;
  color: white;
}

.home_section4 {
  margin: auto;
  margin-top: 4rem;
  padding: 0 50px;
  width: 75%;
  display: flex;
}

.tile_section4 {
  font-size: 2.5rem;
  font-family: var(--bold);
}

.subtitle_section4 {
  font-size: 2rem;
  margin-top: 2rem;
  font-family: var(--bold);
  color: var(--primary);
}

.content_container_section4 {
  margin-top: 2rem;
  display: flex;
}

.s2-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  line-height: 1.6;
}

.s2-red-circle {
  border: 1px solid var(--primaryBlue);
  background-color: var(--primaryBlue);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 1px;
  display: flex;
  justify-content: center;
  margin-right: 8px;
}

.img_container_section4 {
  width: 40%;
  display: flex;
  align-items: end;
}

.section4_butt {
  width: fit-content;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  border-color: transparent;
  background-color: var(--primary);
  text-align: center;
  margin-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  color: white;
}

.Main_container_sec4 {
  width: 60%;
}

.img_section4 {
  height: 53%;
  width: 153%;
}

.green_carousell {
  background: var(--primary);
  padding: 20px 50px;
  margin-top: 5rem;
}

.green_carousell_title {
  color: var(--white);
  font-family: var(--bold);
  text-align: center;
  font-size: 2rem;
}

.green_carousell_hr {
  width: 134px;
  margin: auto;
  margin-top: 2rem;
  border-bottom: 10px solid white;
  border-radius: 20px;
  margin-bottom: 5rem;
}

.skills_section {
  margin: auto;
  margin-top: 4rem;
  padding: 0 50px;
  width: 75%;
}

.skills_title {
  font-size: 2rem;
  width: 50%;
  font-family: var(--bold);
  margin-bottom: 0.5em;
}

.skills_title_red {
  color: var(--pink);
}

.skills_container {
  display: flex;
}

.quizz-img {
  width: 70%;
  transform: translateY(-30%);
  margin-bottom: -15%;
}

.skills_img {
  display: flex;
  margin: auto;
  width: 40%;
}

.button_skill_section {
  width: fit-content;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  color: #fff;
  background-color: var(--primary);
  border-color: transparent;
  text-align: center;
  margin-top: 3rem;
  cursor: pointer;
}

.curve_section {
  margin-top: 7rem;
}

.curve_section_img {
  width: 100%;
}

.trusted_section {
  margin: auto;
  padding: 0 50px;
  width: 75%;
  margin-bottom: 5rem;
}

.trusted_title {
  font-size: 3rem;
  font-family: var(--bold);
  display: flex;
  justify-content: center;
}

.horzontal_trusted {
  width: 134px;
  margin: auto;
  margin-top: 2rem;
  border-bottom: 10px solid var(--pink);
  border-radius: 20px;
  margin-bottom: 5rem;
}

.trusted_img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px;
  margin: 10px;
  height: 200px;

  width: 200px;
  object-fit: contain;
}

.trusted_carousel_cont {
  display: flex;
  flex-flow: row wrap;
}

.trusted_carousel_img {
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 2%), 0 6px 20px 0 rgb(0 0 0 / 4%) !important;
  margin-bottom: 2rem;
}

.contribution_section {
  background: var(--pink);
  padding: 20px 0;
  margin-top: 5rem;
}

.contribution_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contribution_img_section {
  margin-left: 2rem;
}

.contribution_img {
  width: fit-content;
  height: 300px;
  margin-right: 4rem;
}

.contribution_content_container {
  width: 36%;
}

.contribution_title {
  font-size: 2rem;
  font-family: var(--bold);
  color: white;
  margin-bottom: 1rem;
}

.contribution_subtitle {
  font-size: 1.2rem;
  color: #fff;
  width: 90%;
}

.contribution_button {
  width: fit-content;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  color: #fff;
  background-color: var(--primary);
  border-color: transparent;
  text-align: center;
  margin-top: 3rem;
  cursor: pointer;
}

.getintouch_section {
  margin: auto;
  padding: 1px 51px;
  width: 75%;
  margin-top: 5rem;
  display: flex;
  margin-bottom: 5rem;
}

.getintouch_title {
  font-size: 1.5rem;
  font-family: var(--bold);
  color: var(--primary);
  margin-bottom: 1rem;
}

.getintouch_subtitle {
  font-size: 2rem;
  font-family: var(--bold);
  width: 100%;
}

.getintouch_img {
  margin-top: 2rem;
  height: 230px;
  object-fit: contain;
  margin-bottom: 2rem;
}

.phone_icon_getintouch {
  color: var(--pink);
  margin-left: 2rem;
}

.getintouch_context {
  margin-left: 1rem;
  font-family: var(--bold);
}

.phone_number_getintouch {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.getintouch_context2 {
  margin-left: 1.5rem;
  font-family: var(--bold);
}

.email_getintouch {
  margin-top: 0.5rem;
}

.getintouch_left_section {
  display: flex;
  width: 40%;
}

.text_field_getintouch {
  height: 40px;
  margin-top: 2rem;
  width: 300px;
}

::placeholder {
  color: var(--grey) !important;
}

.form_section {
  flex-flow: row;
  display: flex;
  width: 47%;
  margin: auto;
  margin-left: 4rem;
}

.getintouch_left_section {
  display: flex;
  flex-flow: column;
  margin: auto;
  /* padding-top: 9.4rem; */
  width: 40%;
  margin-top: 8rem;
  margin-left: 3rem;
}

.getintouch_left_section_ar {
  display: flex;
  flex-flow: column;
  margin: auto;
  /* padding-top: 9.4rem; */
  width: 40%;
  margin-top: 8rem;
  margin-right: 2rem;
}

.form_section_ar {
  flex-flow: row;
  display: flex;
  width: 75%;
  margin: auto;
}

.first_form_section_getintouch {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topic_section_contents {
  height: 40px;
  width: 300px;
}

.message_section {
  width: 300px;
  height: 188px;
  margin-top: 1.6rem;
}

.message_section_ar {
  width: 300px;
  height: 188px;
  margin-top: 3rem;
  margin-left: 1rem;
}

.form_butt {
  width: 170px;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  color: #fff;
  background-color: var(--pink);
  border-color: transparent;
  text-align: center;
  cursor: pointer;
}

.form_button_section {
  margin: auto;
  margin-top: 2rem;
  margin-left: 8rem;
}

.react-multi-carousel-item {
  position: relative !important;
  width: 512px;
  display: flex;
  margin: auto;
  justify-content: center;
}

.Main_logedin_vol_section {
  margin: auto;
  margin-top: 4rem;
  width: 75%;
  display: flex;
}

.right_section_vol {
  margin-left: 4rem;
  width: 55%;
}

.left_section_vol {
  width: 40%;
}

.opp_title {
  font-size: 2rem;
  font-family: var(--bold);
}

.opp_title_ar {
  font-size: 2.5rem;
  font-weight: bold;
}

.opp_button_cont {
  margin-top: 1rem;
  display: flex;
}

.opp_butt_recommended {
  padding: 10px;
  border-radius: 8px;
  background-color: var(--primary);
  color: white;
  border: none;
  cursor: pointer;
}

.opportunities_container {
  margin-top: 2rem;
}

.opp_subtitle {
  margin-top: 2rem;
  font-size: 1.5rem;
  font-family: var(--bold);
  width: 85%;
}

.opportunities_card_home {
  width: 100%;
  height: 480px;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.points_section {
  margin-top: 2rem;
  display: flex;
}

.hours_section {
  margin-top: 3rem;
  display: flex;
}

.opportunites_completed_section {
  margin-top: 3rem;
  display: flex;
}

.points_logo {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
}

.vol_name {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
}

.points_title {
  font-size: 1.2rem;
  font-weight: var(--bold);
}

.points_title_ar {
  font-size: 1.5rem;
  font-style: bold;
}

.view_profile_butt_ar {
  font-size: 1.2rem;
}

.vol_profile {
  width: 100%;
  height: 480px;
  border-radius: 15px;
  background-color: var(--primary);
  padding: 20px 20px;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.button_profile_cont {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2rem;
}

.right_section_vol_ar {
  margin-right: 4rem;
}

.content2_card_ar {
  margin-left: 0rem;
}

.points_logo_ar {
  margin-left: 1rem;
  margin-right: 0rem;
}

.view_profile_butt {
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  color: var(--primary);
  border: none;
  cursor: pointer;
}

.notification_navigation {
  padding: 10px;
  border-radius: 12px;
  background-color: var(--pink);
  color: white;
  border: none;
  cursor: pointer;
  justify-content: center;
}

.notification_button_container {
  margin-top: 1rem;
  display: flex;
}

@media (max-width: 1350px) {
  .left_section_vol {
    width: 50%;
  }

  .right_section_vol {
    width: 50%;
  }
}

@media (max-width: 1250px) {
  .carousel .slide .legend {
    font-size: 1.6rem !important;
    text-align: center !important;
  }

  .form_section {
    flex-flow: row wrap;
    margin: auto;
  }

  .getintouch_left_section {
    margin-top: 2rem;
    margin-left: 0rem;
  }
}

@media (max-width: 1200px) {
  .section3_container {
    padding: 0px 7%;
  }

  .getintouch_section {
    flex-direction: column;
  }

  .getintouch_left_section {
    padding-top: 2rem;
  }

  .form_section {
    margin-left: 15rem;
  }

  .getintouch_right_section {
    margin: auto;
    /* margin-left: 13rem; */
  }

  /* .green_carousell_contents{
        margin-left: 9rem;
    } */
}

@media (max-width: 1200px) {
  .bottom_row_cont {
    width: 100%;
  }

  .section3_container {
    padding: 0px 5%;
  }

  .img_section4 {
    height: 53%;
    width: 153%;
  }

  /* .green_carousell_texts{
        right: 0rem;
        margin: auto;
        left: 0rem;
        top: 5rem;
        width: 60%;
    }
    .green_carousell_subs{
        color: #fff;
    margin: auto;
    position: absolute;
    top: 63%;
    width: 46%;
    text-align: center;
    right: 10rem;
    } */

  .form_button_section {
    margin-left: 0rem;
  }

  .getintouch_subtitle {
    width: 600px;
  }
}

@media (max-width: 1120px) {
  .content2_card {
    margin-left: 0rem;
  }
}

@media (max-width: 1050px) {
  /* .quote-img{
        margin-left: 20%;

    } */
  .carousel .slide .legend {
    width: 100% !important;
    right: 0rem !important;
    left: 0rem !important;
    text-align: center !important;
  }

  /* .green_carousell_texts {
        right: 5%;
        margin: auto;
        top: 5rem;
        width: 38%;}
        .green_carousell_subs {
            color: #fff;
            margin: auto;
            position: absolute;
            top: 63%;
            width: 25%;
            text-align: center;
            right: 40%;} */
  .trusted_carousel_img {
    margin-left: 2rem;
  }

  .quizz-img {
    margin-bottom: 15rem;
    width: 100%;
  }
}

@media (max-width: 980px) {
  .bottom_row_cont {
    display: flex;
    justify-content: space-between;
  }

  .section3_container {
    padding: 0px 0%;
  }

  .img_section4 {
    height: 53%;
    width: 153%;
  }

  .home_section4 {
    display: block;
    margin: auto;
  }

  .Main_container_sec4 {
    width: 100%;
    margin-top: 4rem;
  }

  /* .green_carousell_texts{
        left: 6rem;
    } */
  .getintouch_section {
    width: 100%;
  }

  .getintouch_subtitle {
    width: 100%;
  }
}

@media (max-width: 970px) {
  .bottom_row_cont {
    justify-content: center;
    gap: 1.5rem;
  }
}

@media (max-width: 800px) {
  .Main_logedin_vol_section {
    flex-wrap: wrap;
  }

  .right_section_vol {
    width: 100%;
    margin-top: 2rem;
  }

  .left_section_vol {
    width: 100%;
    margin: auto;
    margin-left: 4rem;
  }

  .form_section_ar {
    flex-flow: column;
    display: flex;
    width: 75%;
    margin: auto;
    justify-content: center;
  }

  .getintouch_left_section_ar {
    margin-right: 0;
  }
}

@media (max-width: 790px) {
  .img-section-big-1,
  .img-section,
  .img_section_iog {
    display: none;
  }

  .parags_container {
    width: 100%;
  }

  .carousel .slide .legend {
    font-size: 1.5rem !important;
    text-align: center !important;
  }

  .homepage_section {
    width: 100%;
    margin: auto;
    margin-top: 3rem;
  }

  .iog_title {
    justify-content: flex-start !important;
  }

  /* .horizontal_rw_pink {
        margin-right: 68%;
    } */

  .parags_container_iog {
    margin-left: 0rem;
  }

  .iog_buttons {
    justify-content: flex-start !important;
  }

  .Contents_container {
    width: 250px;
    white-space: unset;
  }

  .home_section3 .img-section {
    display: inherit;
  }

  .section3_container {
    padding: 0px 5%;
    font-size: 2rem;
  }

  /* .quote-img{
    height: 330px !important;
} */
  /* .green_carousell_texts{
    left: 4% !important;
    top: 14% !important;
    width: 41% !important;
} */
  /* .quote-img {
    margin-left: 13% !important;
} */
  .tile_section4 {
    font-size: 2rem;
  }

  .subtitle_section4 {
    font-size: 1.5rem;
  }
}

@media (max-width: 820px) {
  /* .green_carousell_subs{
        display: none !important;
    } */
  .trusted_section {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .skills_title {
    width: 100%;
  }

  .skills_container {
    display: block;
  }

  .skills_img {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .getintouch_section {
    padding: 0 20px;
  }

  .getintouch_context2,
  .getintouch_context {
    margin-inline: 0;
  }

  .phone_number_getintouch2,
  .email_getintouch {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .getintouch_right_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .phone_icon_getintouch {
    margin-left: 0;
  }

  .home_section3 {
    display: none;
  }

  .home_section4 {
    width: 100%;
  }

  .skills_section {
    width: 100%;
  }

  .skills_title {
    width: 100%;
  }

  .skills_img {
    display: none;
  }

  .form_section {
    margin-left: 0rem !important;
  }

  .getintouch_left_section {
    margin-left: 0rem !important;
  }

  .carousel .slide .legend {
    font-size: 1.2rem !important;
    text-align: center !important;
  }

  .imv_butt {
    width: 200px;
  }

  .iog_butt {
    width: 200px;
  }

  .iog_section2 {
    display: flex;
    justify-content: center;
  }

  .parags_container_iog {
    width: 100%;
  }

  .iog_buttons {
    display: flex;
    justify-content: center !important;
    flex-flow: wrap;
  }

  .trusted_carousel_cont {
    display: block;
  }

  .black_section_button {
    width: 200px;
    justify-content: center;
  }

  .section4_butt {
    width: 200px;
    justify-content: center;
  }

  .button_skill_section {
    width: 200px;
    justify-content: center;
  }

  .Contents_container1 {
    flex-direction: column-reverse;
    width: 250px;
  }

  .content1 {
    margin-top: 2rem;
  }

  .content2_card {
  }

  .img-big {
    margin-bottom: 0rem;
    margin-left: 0rem;
  }

  .black-section-item-container {
  }

  .bottom_row_cont {
  }

  .opportunities_card_home {
    width: 100%;
    margin: auto;
  }

  .vol_profile {
    width: 306px;
  }

  .Main_logedin_vol_section {
    margin-left: 1rem;
  }

  .opp_title {
    font-size: 1.5rem;
  }

  .opp_subtitle {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .contribution_container {
    display: block;
  }

  .contribution_content_container {
    width: 100%;
  }

  .Contents_container {
    width: 250px;
  }
}

@media (max-width: 400px) {
  .contribution_container {
    display: block;
  }

  .contribution_title {
    margin-left: 1rem;
  }

  .contribution_subtitle {
    width: 80%;
    margin-left: 1rem;
  }

  .contribution_content_container {
    width: 100%;
  }

  .contribution_button {
    margin-left: 6rem;
  }

  /* .green_carousell_texts{
            width: 80% !important;
        }
        .green_carousell_subs{
            display: none !important;
        } */
  .iog_butt {
    width: 100%;
    white-space: nowrap;
  }

  .iog_buttons {
    display: flex;
    flex-wrap: wrap;
  }

  .parags_container {
    width: 100%;
  }

  .parags_container_iog {
    width: 100%;
  }

  .imv_title {
    font-size: 2rem;
  }

  .trusted_title {
    font-size: 2rem !important;
    white-space: unset !important;
  }

  .iog_title {
    font-size: 2rem !important;
    white-space: unset !important;
  }

  .getintouch_img {
    display: none;
  }

  .green_carousell {
    padding: 20px 30px;
  }

  .form_section_ar {
    width: 81%;
  }
}

.imv_title_arabic {
  font-size: 3rem;
  font-weight: bold;
}

.parags_container_arabic {
  font-size: 1.3rem;
}

.imv_butt_ar {
  font-size: 1.3rem;
}

.iog_title_ar {
  justify-content: flex-start;
  font-size: 3rem;
  font-weight: bold;
}

.horizontal_rw_pink_ar {
  margin: 0;
  margin-right: 0;
}

.parags_container_iog_ar {
  margin-left: 0;
  margin-right: 21%;
  font-size: 1.3rem;
}

.iog_buttons_ar {
  justify-content: flex-start !important;
  direction: ltr;
  margin-left: 5%;
}

.iog_butt_ar {
  font-size: 1.3rem;
}

.black_section_button_ar {
  font-size: 1.3rem;
}

.text_ar {
  font-size: 1.3rem;
  margin-right: 1rem;
}

.contribution_title_ar {
  font-size: 2.5rem;
  font-weight: bold;
}

@media (max-width: 550px) {
  .text-container {
    text-align: center;
  }

  .home_section4,
  .skills_section {
    padding: 0 20px;
  }

  .content1_card {
    text-align: center;
  }

  .imv_buttons,
  .iog_buttons,
  .section4_button {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }

  .iog_butt {
    width: 200px !important;
  }

  .imv_butt,
  .iog_butt,
  .section4_butt {
    margin-left: 0;
  }

  .text-container {
    flex-direction: column;
  }

  .contribution_container {
    display: block;
  }

  .contribution_content_container {
    width: 80%;
    margin: auto;
  }

  .contribution_img {
    display: none;
  }

  .contribution_button {
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 3rem;
  }

  .trusted_title {
    white-space: nowrap;
    font-size: 2rem;
  }

  .iog_butt {
    white-space: nowrap;
  }

  .parags_container_iog {
    width: 100%;
  }

  .parags_container {
    width: 100%;
  }

  .iog_title {
    font-size: 2rem;
    white-space: nowrap;
  }

  /* .green_carousell_contents{
                margin-left: 3rem !important;
            }
            .green_carousell_texts{
                left: 0rem !important;
                right: 0rem !important;
            } */
  .button_skill_section {
    display: flex;
    margin: auto;
    margin-top: 3rem;
    align-items: center;
  }
}

@media (max-width: 450px) {
  .carousel .slide .legend {
    font-size: 1rem !important;
    text-align: center !important;
  }

  .vol_profile {
    width: 232px;
  }

  .opp_title {
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  .black-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottom_row_cont {
    flex-flow: column;
    align-items: center;
  }

  .black-section-item-container {
    flex-flow: column;
    align-items: center;
  }

  .trusted_img {
    margin-left: 0rem;
  }

  .opportunities_card_home {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .iog_butt {
    width: 200px;
  }
}

@media (max-width: 350px) {
  .text_field_getintouch {
    height: 59px;
    width: 245px;
  }

  .topic_section_contents {
    height: 54px;
    width: 245px;
  }

  .message_section {
    width: 245px;
  }

  .react-tel-input .form-control {
    width: 245px !important;
  }

  .react-tel-input .flag-dropdown {
    height: 40px !important;
  }
}

@media (max-width: 300px) {
  .getintouch_img {
    display: none;
  }

  .text_field_getintouch {
    width: 200px;
  }

  .topic_section_contents {
    width: 200px;
  }

  .message_section {
    width: 200px;
  }

  .getintouch_left_section_ar {
    margin-top: 1rem;
  }

  .iog_title {
    font-size: 2rem;
  }

  .iog_butt {
    width: 400px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  .trusted_section {
    display: none;
  }

  .imv_butt {
    white-space: nowrap;
  }

  .Contents_container1 {
    width: 250px;
  }

  .bottom_row_cont {
    flex-direction: column;
  }
}

@media (max-width: 300px) {
  .Contents_container {
    width: 250px;
    white-space: unset;
  }

  .tile_section4 {
    font-size: 2rem;
  }

  .subtitle_section4 {
    font-size: 1.2rem;
  }
}

@media (max-width: 280px) {
  .text_field_getintouch {
    width: 220px;
    margin-top: 1rem;
  }

  .topic_section_contents {
    width: 228px;
  }

  .black-section {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .message_section {
    width: 228px;
  }

  .getintouch_section {
    margin-left: 7%;
  }

  .text_field_getintouch {
    height: 34px;
  }

  .getintouch_section {
    padding: 0 0;
  }

  .home_popup_body {
    font-size: 1rem;
  }

  .tile_section4 {
    font-size: 2rem;
    font-family: var(--bold);
  }

  .subtitle_section4 {
    font-size: 1.5rem;
  }

  .green_carousell {
    display: none;
  }

  .contribution_img {
    display: none;
  }

  .carousel .slide .legend {
    font-size: 12px !important;
    text-align: center !important;
  }

  .Contents_container {
    width: 250px;
  }

  .react-tel-input .form-control {
    width: 220px !important;
  }
}

.react-tel-input {
  direction: ltr !important;
}
