.create_opp_step6_main_cont {
    display: flex;
    flex-direction: column;
    width: 60%;
    min-height: 350px;
    gap: 2rem;
    margin-bottom: 1.5rem;
}

.create_opp_textarea {
    width: 80%;
    height: 110px;
    border-radius: 12px;
    resize: none;
    padding: 0.5rem
}

.jwLMgR {
    height: 110px !important;

}

.AGBTm,
.kmzlPf,
.kJSWfM,
.kIDvFR {
    height: 170px !important;
}

.kJSWfM {
    margin-bottom: 0rem !important;
}

.dGvKqr {
    border-radius: 12px !important;

}

.byOqXL {
    border-top-left-radius: 8px !important;


}