.create_opp_step10_cont{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    font-family: bold;


}

.step10_content{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    width: 70%; 
}
.maringQA{
    margin-bottom: 1.5rem !important;
}