.create_opp_all_input {
  display: flex;
  flex-direction: column;

}

.create_opp_input_cont {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.create_opp_input_cont .complete_step2_close {
  margin-top: 10px;
}

.center-span {
  display: flex;
  justify-content: center;
  /* Horizontally center content */
  align-items: center;
  /* Vertically center content */
  height: 100px;
  /* Set a fixed height to the span (adjust as needed) */
}

.center-content {
  text-align: center;
  /* Center text horizontally */
}

.italic-text {
  font-style: italic;
}

.view_it_link {
  color: #fff
}

.step12_main_container .body_text {
  height: 100%;
  margin: 0;
  font-size: 2rem;
  font-family: bold;
  text-align: center;
}

.step12_main_container .modal .modal-content {
  height: 80%;
  max-width: 100%;
}