.spotlight_main_cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4rem;
    margin-bottom: 50px;
}

.spotlight_image {
    flex: 1;
    height: 450px;
    width: 100%;
    object-fit: cover;
}
.spotlight_info{
    flex:1 
}
.spotlight_name{
    color: rgb(0, 0, 0);
    font-weight: bold;
    margin-bottom: 30px;
    font-size: var(--px-30);
}

.blog_card_readmore{
    cursor: pointer;
    color: var(--primary);
    font-weight: bold;
}
@media (max-width: 770px) {
    .spotlight_main_cont{
        flex-direction: column !important;
    }
}