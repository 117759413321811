.opp_requests_card_text_score {
    width: fit-content;
    background-color: var(--primary);
    border-radius: 8px;
    color: white;
    padding: 2px;
    font-size: 1rem;
    padding: 5px 10px;
}

.opp_requests_card_text_title {
    display: flex;
    gap: 2rem;
}