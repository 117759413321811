.profie_status_main_container {
    width: 100%;
    background-image: url("../../../assets/images/mayor_background.png");
    background-repeat: no-repeat;
    background-size: cover;
}


.top-left-profilecard {
    padding: 30px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1) !important;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    flex: 2 1;
}

.left-profilecard {
    margin-left: 20px;
}

.right-profilecard {
    margin-right: 20px;
}

.open_to_work_container {
    flex: 1 1;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
}

.card-info-hold-w {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.profile-status-card {
    width: 40%;
}

.profile-status-card-icon {
    width: 50px;
}

.cards_row1 {
    margin: auto;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    padding-top: 30px;
}

.open_to_work {
    padding: 30px;
    border-radius: 8px;
    height: 100%
}

.profile_name_style {
    font-weight: bold;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile_name {
    margin-left: 10px;
    margin-right: 10px;
}

.profile_points_style {
    font-weight: bold;
    font-size: 16px;
    margin-top: 30px
}

.font-16 {
    font-size: 16px
}

.font-27 {
    font-size: 27px
}

.bold-font {
    font-weight: bold
}

.checked-switch,
.unchecked-switch {
    border: '1px solid #fff'
}

.unchecked-switch .react-switch-bg {
    background: var(--primary) !important;
    border: 2px solid #fff !important
}

.unchecked-switch .react-switch-handle {
    background: #fff !important;
}

.checked-switch .react-switch-bg,
.checked-switch .react-switch-handle {
    background: var(--secondary) !important;
    border: 2px solid var(--grey) !important
}


.profie_status_main_container .example-track {
    background: #d0f0c0;
}

.cards_container {
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    perspective: 1000px;

}
.status_cards_container{
    justify-content:start !important
}

.cards_container_ngo {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    perspective: 1000px;

}



.profie_status_main_container .cards {
    background-color: var(--white);
    padding: 30px 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 8%) !important;
    width: 300px;
    border-radius: 15px;
    flex-wrap: wrap;
    margin: 5rem 20px 0 0px;


}

.cards2 {
    background-color: var(--white);
    padding: 30px 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 8%) !important;
    width: 300px;
    border-radius: 15px;
    flex-wrap: wrap;
    margin: 5rem 20px 0 0px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
    height:300px
}

.flip-card-inner {

    width: 300px;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

.cards_container:hover .cards2 {
    transform: rotateY(180deg);
}


.flip-card-front {
    position: absolute;
    width: 300px;
    display: flex;
    padding: 30px 20px;
    align-items: center;
    margin-top: -2rem;
    flex-direction: column;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

}

.flip-card-back {
    position: absolute;
    width: 300px;
    display: flex;
    padding: 30px 20px;
    align-items: center;
    margin-top: -3rem;
    flex-direction: column;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

}

.title_points {
    margin-bottom: 0 !important;
}

.popup_container_row_left_date {
    font-size: 2rem;
    font-family: var(--bold);
}

.popup_container_row {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.flip-card-back {

    transform: rotateY(180deg);
}

.profie_status_main_container .right-card {
    margin-right: 0px !important;
}

.profie_status_main_container .left-card {
    margin-left: 0px !important;
}

.cards_img {
    height: 100px;
    margin-bottom: 20px
}

.cards_desc {
    font-weight: bold;
    margin-bottom: 20px;
    width: 85%
}

.cards_desc_points {
    font-weight: bold;
    text-align: center
}

.cards_desc_points_next_level {
    width: 85%;
}

.organization_status_div {
    display: flex;
    flex-direction: column;
}

.word_div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.volunteer_circle_div {
    margin: 10px;
    color: var(--primary);
    font-size: 20px;
}

.org_name{
    font-size: 25px;
}
.opp_card_bottom_verified_icon {
    margin-left: 2rem;
}

.ngo_verified {
    display: flex;
    align-items: center;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

.ngo_cards_container .cards {
    width: 30% !important;
    align-items: center;
}

.ngo_name {
    font-size: 40px;
    display: flex;
    align-items: center;
}

.ngo_cards_container .cards_desc {
    width: auto !important
}

@media (max-width: 768px) {
    .card-info-hold-w {
        flex-direction: column;
        align-items: flex-start;
    }

    .profile-status-card {
        width: 100%;
    }

    .cards_row1 {
        flex-direction: column;
    }

    .cards_container {
        flex-direction: column;
    }

    .profie_status_main_container .cards {
        width: 100%;
        margin: 2rem 0px 0 0px
    }

    .cards2 {
        width: 100%;
        margin: 2rem 0px 0 0px;
        height:300px
    }


    .flip-card-front,
    .flip-card-back {
        position: absolute !important;

    }

    .cards_container:hover .cards2 {
        transform: rotateY(180deg);
    }

    .top-left-profilecard {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 0px;
        margin-right: 0px
    }

    .ngo_cards_container .cards {
        width: 100% !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .cards_row1 {
        width: 90%;
    }

    .cards_container {
        width: 90%;
    }
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}