.org_opp_header {
    display: flex;
    width: 700px;
    margin: auto;
    justify-content: space-between;
    margin-top: 2rem;

}
.underline_text{
    text-decoration: underline;
}
.create_button {
    color: var(--primary);
    width: fit-content;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    margin-left: 20%;
    margin-right: 20%;
}

.create_button_disables {
    color: var(--dark);
    width: fit-content;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    margin-left: 20%;
    margin-right: 20%;
    opacity: 0.5;

}

.create_button_disables_text {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
    margin-top: 5px;
    color: var(--pink);
}


@media only Screen and (max-width: 500px) {
    .org_opp_header {

        width: 90%;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
    }

    .create_button {
        margin: auto;
        margin-top: 2.5rem;


    }

    .opp_main_sec {
        margin-top: 2rem;
    }
}