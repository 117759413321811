.contact-page {
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/mayor_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.green-block {
  background-color: var(--primary);
  padding: 10px;
}

input::placeholder {
  font-family: Tahoma;
}

.green-block p {
  font-family: var(--bold);
  color: var(--white);
  font-size: var(--px-50);
  text-align: center;
  padding-top: 5%;
}

.contactUs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.big-img {
  margin: auto;
  width: 62%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 12px;
}

.phone-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  margin-bottom: 30px;
  background-color: #f0f0f0;
  border-radius: 10px;
  gap: 1rem;
}

.phone-img {
  margin: 0px 10px 0px 0px;
  height: 100px;
  padding-bottom: 2rem;
}

.phone-number {
  color: black;
  font-weight: bold;
  font-size: var(--px-20);
}

.mail-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  margin-bottom: 30px;
  gap: 5rem;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 100px;
}

.mail {
  height: 50px;
}

.blocks {
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: center;
}

.info {
  color: black;
  font-weight: bold;
  font-size: var(--px-20);
}

.pink-block {
  width: 100%;
  padding: 50px;
  background-color: rgb(240, 106, 128, 0.8);
  display: flex;
  margin: auto;
  justify-content: center;
}

.text_contactus_container {
  color: #f0f0f0;
  font-size: 40px;
  font-weight: bold;

  padding-bottom: 15px;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.input-fields {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  gap: 10px;
  height: 100%;
}

.input-fields ::placeholder {
  color: white;
  opacity: 0.8;
  font-size: 100%;
}

.box {
  height: 40px;
  width: 90% !important;
  background: inherit;
  font-size: var(--px-16);
  color: var(--secondary);
  border: 1px solid var(--border);
  box-shadow: none !important;
  outline: none;
  border-radius: 10px !important;
  padding-left: 1.5rem;
}

.button {
  width: 20%;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  border-color: transparent;
  text-align: center;
  color: var(--white) !important;
  border: 1px solid transparent !important;
  background-color: var(--primary) !important;
  margin-top: 20px;
}

.input-fields-arabic {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-right: 40%;
  gap: 20px;
}

.input-fields-arabic ::placeholder {
  color: white;
  opacity: 0.8;
  font-size: 20px;
}

.box-arabic {
  height: 40px;
  width: 90% !important;
  background: inherit;
  font-size: var(--px-16);
  color: var(--secondary);
  border: 1px solid var(--border);
  box-shadow: none !important;
  outline: none;
  border-radius: 10px !important;
  padding-right: 1.5rem;
}

.there {
  display: flex;
  justify-content: flex-start;
  width: 30%;
  margin-bottom: 12rem;
}

.fields_contact_container {
  display: flex;
  width: 60%;
  margin-top: 2rem;
}

@media only screen and (max-width: 980px) {
  .info {
    color: black;
    font-weight: bold;
    font-size: var(--px-13);
  }

  .phone-number {
    font-size: 15px;
  }

  .pink-block p {
    padding-left: 2rem;
  }
}

@media only screen and (max-width: 790px) {
  .info {
    color: black;
    font-weight: bold;
    font-size: 13px;
  }
}

@media only screen and (max-width: 760px) {
  .box {
    padding: 1rem;
  }

  .box-arabic {
    padding: 1rem;
  }

  .input-fields ::placeholder {
    color: white;
    opacity: 0.8;
    font-size: 12px;
  }

  .input-fields-arabic ::placeholder {
    color: white;
    opacity: 0.8;
    font-size: 15px;
  }

  .input-fields {
    width: 50%;
  }

  .input-fields-arabic {
    width: 50%;
  }

  .button {
    width: 40%;
  }
}

@media (max-width: 700px) {
  .pink-block {
    flex-direction: column;
  }

  .there {
    width: 100%;
    margin-bottom: 0rem;
  }

  .fields_contact_container {
    width: 100%;
  }

  .input-fields {
    width: 80%;
    align-items: center;
  }

  .button {
    width: 200px;
  }
}

@media only screen and (max-width: 580px) {
  .blocks {
    display: block;
    gap: 2rem;
    width: 100%;
    justify-content: center;
  }

  .phone-container {
    width: 50%;
    margin: auto;
  }

  .mail-container {
    width: 50%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 30px;
  }

  .input-fields-arabic ::placeholder {
    color: white;
    opacity: 0.8;
    font-size: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .box {
    padding: 1rem;
  }

  .box-arabic {
    padding: 1rem;
  }

  .input-fields ::placeholder {
    color: white;
    opacity: 0.8;
    font-size: 10px;
  }

  .input-fields-arabic ::placeholder {
    color: white;
    opacity: 0.8;
    font-size: 14px;
  }

  .input-fields {
    width: 50%;
  }

  .input-fields-arabic {
    width: 50%;
  }

  .pink-block p {
    padding-left: 0.7rem;
  }
}

@media (max-width: 370px) {
  .button {
    width: 100px;
  }
}
