.image_press{
	width: 100%;
	margin: auto;
	margin-top: 5rem;
    object-fit: cover;
   
}

.press_carousel_main a{
color: var(--secondary);
}

.first_container{
    width: fit-content;
    display: flex;
    position: relative;
}
.white_label{
    position: absolute;
    background: white;
    width: 90%;
    max-height: 90px;
    top: 70%;
    left: 5%;
    border-radius: 10px;
    padding: 10px 5px;
    overflow: auto;
}

.white_label::-webkit-scrollbar {
    display: none;
  }
.press_title{
    color: var(--primary);
    font-size: 1rem;
    margin-left: 1rem;
    
}
.press_name{
    font-family: bold;
    font-size :1rem ;
    margin-left: 1rem;
  
}
.green_hr{
    width: 45px;
    margin-left: 1rem;
    height:3px;
    background-color: var(--primary);
    border-radius: 4rem;
    opacity: 1;
    border: none;
    margin-top: 4px;
}

.press_carousel_main{
    width: 290px
}

