.quizz_div_1 {
    width: 75%;
    margin: auto;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
}

.quiz_text_container {
    width: 50%;

}

.quiz_text_container_1 {
    width: 80%;

}

.quiz_image_container {
    width: 45%;
}

.title_container {
    font-size: 2rem;
    font-family: var(--bold);
}

.title_container_1 {
    font-size: 2rem;
    font-family: var(--bold);
    width: 75%;
}

.title_container_2 {
    font-size: 3rem;
    font-family: var(--bold);
    width: 100%;
}

.text_subtext {
    font-size: 1rem;
    margin-top: 1rem;
    width: 85%;
    text-align: justify;

}

.text_subtext_1 {
    font-size: 1rem;
    margin-top: 2rem;
    width: 100%;
    text-align: justify;

}



.text_button_container {
    margin-top: 2rem;
}

.banner_image {
    width: 100%;
    border-radius: 8px;
}

.quizz_div_2 {
    width: 75%;
    margin: auto;
    margin-top: 6rem;
}

.pink_underline_quiz {
    margin-top: 2rem;
    width: 130px;
    border-bottom: 5px solid var(--pink);
    border-radius: 8px;
}

.quizz_div_3 {
    width: 75%;
    margin: auto;
}

.quiz_container {
    margin-top: 2rem;
}

.quiz_title {
    font-size: 1.5rem;
    font-family: var(--bold);
}

.label_radio {
    font-size: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    align-items: center;
}

.quiz_answers {
    margin-top: 2rem;
    display: flex;
    justify-content: inherit;
    text-align: justify;
    align-items: center;   
    font-family: var(--bold);

}

.quiz_next_button {
    width: 10%;
    margin-top: 2rem;
}

.quiz_opp_cont {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;

}

.quiz_subtext {
    margin-top: 1rem;
    width: 70%;
}

.quiz_btn {
    border-radius: 8px;
    background-color: var(--primary);
    color: white;
    font-size: 1rem;
    padding: 7px 12px;
    border: none;
    outline: none;
    cursor: pointer;
    width: 75%;
    margin-bottom: 8px;
}

.opp_quiz_card_body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.opp_quiz_card_body_action_container {
    display: flex;

    align-items: center;
    justify-content: center;
}

.opp_quizz_card_header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.opp_quizz_card_img {
    width: 170px;
    height: 100px;
    border-radius: 12px;
}

.opp_quizz_card_text_subtitle {
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.opp_quizz_cards_container {
    width: 350px;
}

.opp_quizz_card {
    width: 350px;
    background-color: white;
    padding: 15px 25px 5px 25px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
    border-radius: 10px;
    height: fit-content;

}

.opp_quizz_card_text_title {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-family: var(--bold);
}