.profile_info_container,
.profile_info_container_ar {
    margin: auto;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    padding-top: 30px;
}
.padding-top-10{
    padding-top: 10px;
}
.progress_container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;
}

.progress_circle {
    height: 120px;
    width: 120px
}

.button_profile,
.update_profile__btn {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    position: relative;
    font-size: var(--px-14);
    border-color: transparent;
    text-align: center;
    color: var(--white) !important;
    border: 1px solid transparent !important;
    background-color: var(--primary) !important;
    cursor: pointer;
}

.margin-20 {
    margin: 20px
}

.CircularProgressbar-text {
    text-align: center;
    transform: translate(-15px, 5px);
}

.input_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.row-fiels {
    width: 100%;
    padding: 10px
}

.profie_status_main_container input,
.profie_status_main_container select {
    width: 100%;
    height: 50px;
    background: var(--white);
    border-radius: 7px;
    font-size: var(--px-16);
    color: var(--secondary);
    border: 1px solid var(--border);
    box-shadow: none !important;
    outline: none;
    color: rgba(0, 0, 0, .25);
    padding: 4px 11px;
    opacity: 10
}

.profie_status_main_container .filter_checkbox{
    width: auto !important;
    height: auto !important;
}
.profie_status_main_container select option {
    color: rgba(0, 0, 0, .25) !important
}

.profie_status_main_container .radio_div {
    width: 100%;
    display: flex;
}


.icon-container {
    position: relative;
    display: inline-block;
  }
  
  .popicon:hover + .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  .popicon {
    cursor: pointer;
  }
  
  .tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    top: -30px; /* Adjust the position as needed */
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    white-space: nowrap;
  }
  
  .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }

  
  
.profie_status_main_container .radio_div input {
    height: 20px;
    width: 20px;
    margin: 0 4px 0 0;
}

input[type=radio].green-radio {
    accent-color: #858585;
}


.profile_info_container_ar .radio_div input {
    margin: 0 0 0 4px
}

.profie_status_main_container .radio_div span {
    margin: 0 20px 0 0
}

.profile_info_container_ar .CircularProgressbar-text {
    transform: translate(18px, 8px);
}


.input-icons i {
    position: absolute;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
}

.calendar-icon {
    padding: 10px;
    margin: 10px;
    min-width: 50px;
    text-align: center;
    height: 50px;
    background-image: url("../../../assets/images/calendar.png");
    background-repeat: no-repeat;

}

.right-50 {
    right: 50%;
}

.left-50 {
    left: 51%
}

.input-field {
    width: 100%;
    padding: 10px;
    text-align: center;
}
.profile_info_textarea{
    width: 100%;
    height: 250px;
    border-radius: 12px;
    resize: none;
    padding:1rem;
    border:1px solid var(--border)
}

.show_more_less_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-self: flex-end;
    align-items: end;
    padding: 10px;
  }

  .profie_status_main_container .css-1rhbuit-multiValue{
    display: flex !important;
}
.profie_status_main_container  .css-1s2u09g-control:hover {
    border-color: hsl(0, 0%, 80%)!important;
}
.date_from_to_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.date_from_to_div .date_to , .date_from_to_div .date_from {
    width: auto !important;
}
.multiselect_not_editable .css-xb97g8 ,.multiselect_not_editable .css-tlfecz-indicatorContainer ,.multiselect_not_editable .css-tj5bde-Svg{
    display: none !important;
}
.input_field_editable{
    color : var(--secondary) !important
}
.profile_info_container .DatePicker , .profile_info_container_ar .DatePicker{
    display: block !important;
}
.edit_profile_select .css-1s2u09g-control {
    background-color: white;
    border: 2px solid var(--border);
    border-radius: 10px;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .profile_info_container .complete_profile_6_textarea , .profile_info_container_ar .complete_profile_6_textarea{
    width:100% !important;
  }
  .profile_info_container .css-319lph-ValueContainer , .profile_info_container .css-6j8wv5-Input , 
  .profile_info_container_ar .css-319lph-ValueContainer , .profile_info_container_ar .css-6j8wv5-Input{
    height: 50px!important;
  }
  .profile_info_container .css-6j8wv5-Input , .profile_info_container_ar .css-6j8wv5-Input{
    margin: 0px !important
  }
  .select_field_editable_not_editable .css-qc6sy-singleValue{
    color: rgba(0, 0, 0, .25) !important
  }
  .hide_more{
    display: none;
  }
  .display_more{
    display: block;
  }
  .edit_profile_select .css-1pahdxg-control {
    background-color: white;
    border: 2px solid var(--border);
    border-radius: 10px;
    margin-bottom: 1rem;
    cursor: pointer;
  
  
  }
  .edit_profile_select .css-1pahdxg-control {
    background-color: white;
    border: 2px solid var(--border);
    border-radius: 10px;
    margin-bottom: 1rem;
    cursor: pointer;
  
  
  }
  
.edit_profile_select .css-tj5bde-Svg {
    color: var(--pink);
  }
  .button_profile:disabled{
    background-color: var(--pink) !important;
  }
  .profile_info_container .complete_step_2_next_button , .profile_info_container_ar .complete_step_2_next_button{
    width: 10% !important
  }

  .profile_info_container input[type='radio']:checked:after,  .profile_info_container_ar input[type='radio']:checked:after  {
    cursor: pointer;
    width: 15px !important;
    height: 15px !important;
    border-radius: 15px;
    top: -2px !important;
    left: -1px !important;
    right: -1px !important;
    position: relative;
    background-color:var(--primary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.css-5kkxb2-requiredInput-RequiredInput{
    opacity: 0 !important;
}
@media (max-width: 768px) {
    .progress_container {
        display: flex;
        flex-direction: column;
    }

    .margin-20 {
        margin: 20px 0px
    }

    .input_row {
        display: flex;
        flex-direction: column;
    }

    .profie_status_main_container .radio_div {
        display: flex;
        flex-direction: column;
    }

    .left-50 {
        left: 18%
    }

    .right-50 {
        right: 13%
    }

    .profile_info_container,
    .profile_info_container_ar {
        flex-direction: column;
    }

    .date_from_to_div{
        display: flex;
        flex-direction:column;
        justify-content: space-between;
    }
    .date_from_to_div .date_to , .date_from_to_div .date_from {
        margin-top: 1rem;
    }

}