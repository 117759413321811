.checkin_out_evaluation_container {
  margin-top: 5rem;
  padding: 40px 10px 40px 10px;
}

.check_in_out_top_div {
  margin-left: 20%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.check_in_out_top_div_ar {
  margin-right: 20%;
  margin-bottom: 20rem;
  display: flex;
  flex-direction: column;
}

.checkin_color {
  margin-top: 10px;
  color: var(--primary);
  font-size: 1rem;
}

.question_mark_evaluation {
  margin: 5px;
  margin-top: 0px;
}

.check_in_out_top_header {
  margin: 5px;
  color: #676767;
}

.check_in_out_top_header_div {
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.opp_type_div {
  margin: 10px;
}

.opp_type_div_calc {
  color: var(--primary);
  margin-left: 10px;
  margin-right: 10px;
}

.divider_line_solid {
  border: 1px solid var(--grey);
}

.green-color {
  color: var(--primary);
  margin-top: 20px;
}

.bold-font {
  font-family: bold;
}

.check_in_out_bottom_div {
  margin-left: 20%;
  margin-bottom: 2rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
}

.check_in_out_bottom_div_ar {
  margin-right: 20%;
  margin-bottom: 20rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
}

.check_in_out_bottom_img {
  width: 40%;
}

.check_in_out_bottom_evaluation {
  width: 50%;
}

.check_in_out_image {
  height: 300px;
  width: 400px;
}

.margin-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.margin-top-bottom {
  margin-top: 30px;
  margin-bottom: 50px;
}

.what_div {
  color: var(--primary);
  margin-top: 10px;
  margin-bottom: 30px;
  cursor: pointer;
  width: 40%;
  align-self: flex-end;
}

.evaluation_button {
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  border-color: transparent;
  text-align: center;
  color: white;
  font-family: bold;
  align-items: center;
  cursor: pointer;
  background-color: var(--primary);
  margin-left: 15px;
  margin-right: 15px;
}

.eval_div {
  display: flex;
  flex-direction: row;
}

.div_text {
  margin-top: 10px;
}

.popup_body_info_data {
  display: flex;
  flex-direction: column;

}

@media (max-width:450px) {
  .popup_body_info_data {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1247px) {
  .check_in_out_bottom_div {
    flex-direction: column;
  }

  .margin-30 {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 30px;
    width: 90%;
  }

  .check_in_out_bottom_evaluation {
    margin-top: 30px;
    width: 90%;
  }

  .eval_div {
    display: flex;
    flex-direction: column;
  }

  .evaluation_button {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
    width: 200px;
  }
}