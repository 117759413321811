.aboutus-page {
	width: 100%;
	background-image: url("../../assets/images/mayor_background.png");
	background-repeat: no-repeat;
	background-size: cover;
	margin-bottom: 10rem;
}

.video_aboutus {
	margin-top: 6rem;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	width: 65%;
}

.aboutus_img {
	width: 100%;
	height: 450px;
	border-radius: 25px;
}

.mission_icon {
	width: 20%;
	display: block;
	margin-right: auto;

}

.aboutUs_2nd_cont {
	margin: auto;
	margin-top: 5rem;
	width: 65%;
}

.interfere_aboutus {
	margin: auto;
	margin-top: 10rem;
	width: 80%;
}

.mission_aboutus {
	margin-top: 5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}



.mission_content_title {
	font-weight: bold;
	font-size: 2.5rem;
	font-family: bold;
	color: var(--pink);
	width: fit-content;
}

.mission_subtitle {
	color: var(--pink);
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-size: 1.5rem;
	font-family: regular;
	text-align: left;
	opacity: 1;
	width: fit-content;
}

.mission_content {
	font-family: regular;
	color: #2C2C2C;
	margin-top: 0.5rem;
	font-size: 1rem;
}

.mission_container {
	margin-left: 3rem;

}

.green_color {
	color: var(--primary) !important;
}

.color_pink {
	color: var(--pink) !important;
}

.pink_bg {
	background-color: var(--pink) !important;
}

.interfer_title {
	font-family: bold;
	font-size: 2.5rem;
	margin: auto;
	width: fit-content;
	margin-bottom: 2rem;
}

.interfere_logo {
	width: 20%;


}

.interfer_content_title {
	font-family: bold;
	font-size: 2rem;
	margin-bottom: 1rem;
	color: var(--pink);
}

.interfer_contents {
	width: 75%;
	margin-left: 3rem;
	text-align: justify;
}

.interfer_container {
	display: flex;
	width: 100%;
	align-items: flex-end;
	justify-content: space-between;
}

.interfer_parag {
	font-size: 1.5rem;
	font-family: regular;
	display: flex;
	width: 85%;
}

.content_parag {
	font-size: 1rem;
	width: 75%;
	margin-left: 0.5rem;
}

.interfer_parag2 {
	display: flex;
}

.checked_icon {
	width: 20px;
	height: 20px;
}

.interfer_parag3 {
	display: flex;
	margin-top: 1rem;
}

.button_interfer {
	border-radius: 5rem;
	height: 4rem;
	border: transparent;
	color: white;
	cursor: pointer;
	background-color: var(--primary);

}

.green-section {
	background: var(--primary);
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 10rem;
}

.gs-title {
	color: var(--white);
	font-family: var(--bold);
	text-align: center;
}

.gs-container {
	position: relative;
}

.gs-paragraph {
	font-family: var(--bold);
	color: var(--white);
	position: absolute;
	top: 50%;
	text-align: center;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
}

.quote-img {
	height: 400px;
}

.gs-name {
	position: absolute;
	color: var(--white);
	left: 50%;
	bottom: 4%;
	transform: translate(-50%, -50%);
}

.carousell_title {
	margin: auto;
	margin-top: 6rem;
	width: fit-content;
	font-size: 2.5rem;
	font-family: bold;
	color: var(--pink);

}

.horizontal_aboutus {
	width: 60px;
	margin: auto;
	height: 0.5rem;
	background-color: var(--primary);
	border-radius: 4rem;
	opacity: 1;
	border: none;
}

.title_1_carousell {
	margin: auto;
	margin-top: 3rem;
	font-family: var(--bold);
	width: fit-content;
	font-size: 1.5rem;

}

.carousel_img {
	width: 75%;
}

.carousel_item_aboutus {
	box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 0.1) !important;
	background-color: white;
	width: 55%;
	height: 375px;
	display: flex;
	border: 5px solid var(--pink);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px auto;
	padding: 50px;
	border-radius: 15px;
	opacity: 1;
}

.carousel_item_aboutus_2 {
	box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 0.1) !important;
	width: 60%;
	height: 375px;
	display: flex;
	/* border: 5px solid var(--pink); */
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px auto;
	padding: 50px;
	border-radius: 15px;
	opacity: 1;
}

.carousel_item_aboutus_3 {
	box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 0.1) !important;
	width: 60%;
	background-color: white;
	height: 375px;
	display: flex;
	border: 5px solid var(--primary);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px auto;
	padding: 50px;
	border-radius: 15px;
	opacity: 1;
}

.colorblue {
	background-color: var(--primaryBlue);

}

.colorgreen {
	background-color: var(--primary);
}

.carousell_aboutus .react-multiple-carousel__arrow {

	background: none !important;
}

.carousell_aboutus .react-multiple-carousel__arrow::before {
	color: var(--primary) !important;
	font-size: 30px !important;
	font-weight: 900 !important;
}

.carousell {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.bottom_div_aboutus {
	width: 50%;
	text-align: center;
	font-size: 1rem;
	font-family: bold;
}

.bottom_div_aboutus_2 {
	width: 50%;
	text-align: center;
	font-size: 1rem;
	font-family: bold;
	margin: auto;
}

.meet_the_team_component .react-multi-carousel-list {
	width: 100% !important;
	margin: auto;
	height: 100%;
}

.carousell_aboutus .react-multi-carousel-list {

	width: 50%;
	margin: auto;

}

.carousell_aboutus {
	margin-top: 10rem;
}

.singup_btn_aboutus {
	background-color: var(--pink);
	color: white;
	border: none;
	outline: none;
	margin: auto;
	padding: 10px 25px;
	border-radius: 10px;
	font-family: regular;
	font-size: 1.1rem;
	margin-top: 2rem;
	cursor: pointer;
	display: flex;
}

.singup_btn_aboutus_2 {
	/* background-color: var(--primaryBlue); */
	color: white;
	border: none;
	outline: none;
	margin: auto;
	padding: 10px 25px;
	border-radius: 10px;
	font-family: regular;
	font-size: 1.1rem;
	margin-top: 2rem;
	cursor: pointer;
	display: flex;
}

.green_title {
	color: var(--primary);

}

.tvc_press_title {
	font-size: 2.5rem;
	font-family: bold;
	margin: auto;
	width: fit-content;

}

.horizontal_press_section {
	width: 80px;
	margin: auto;
	height: 0.5rem;
	background-color: var(--pink);
	border-radius: 4rem;
	opacity: 1;
	border: none;
}

.buttons_tvc_press {
	margin: auto;
	margin-top: 7rem;

}

.tvc_press_section {
	margin: auto;
	margin-top: 10rem;
	width: 65%;
}

.tvc_press_btn {
	border: 2px solid var(--pink);
	background-color: white;
	cursor: pointer;
	margin-left: 1rem;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 1rem;
}

.tvc_first_row_img {
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-radius: 8px;
}

.image_press {
	height: 14rem !important;
	border-radius: 15px;
}

.image_press_2 {
	width: 22%;
	margin: auto;
	margin-left: 2rem;
	margin-top: 1.5rem;
}

.meet_the_team_component {
	margin: auto;
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 50rem;

}

.tvc_press_btn:hover {
	color: white;
	background-color: var(--pink);
}

.tvc_second_row_img {
	margin: auto;
	display: flex;
	/* flex-wrap: wrap; */
	width: 72%;
	justify-content: space-between;
	border-radius: 8px;
	margin-top: -4rem;
}

.meet_the_team_section {
	width: 85%;
	margin: 10rem auto 0rem auto;


}

@media (max-width:1100px) {
	.carousel_item_aboutus {
		height: 300px;
	}

	.carousel_img {
		width: 60%;
	}
}

@media (max-width:1060px) {
	.carousell_aboutus .react-multi-carousel-list {
		width: 80%;
	}

	.carousel_img {
		width: 65%;
	}
}

@media (max-width:747px) {
	.video_aboutus{
		width: fit-content;
	}
	.aboutus_img{
		object-fit: contain;
		height:fit-content;
		width:90%
	}
}
@media (max-width:420px) {

	.interfere_aboutus,
	.tvc_press_section {
		width: 95%;
	}

	.video_aboutus{
		width: fit-content;
	}
	.aboutus_img{
		object-fit: contain;
		height:fit-content;
		width:90%
	}
	.mission_icon {
		margin: auto;
		width: 50%;
	}

	.mission_aboutus {
		flex-direction: column;
	}

	.aboutUs_2nd_cont {
		width: 95%;
	}

	.mission_content_title,
	.mission_subtitle,
	.mission_content,
	.interfer_title,
	.interfer_parag,
	.interfer_content_title,
	.content_parag {
		text-align: center;
		margin: auto;
	}

	.interfer_content_title {
		margin-top: 2rem;
	}

	.mission_container,
	.interfer_contents {
		margin-left: 0rem !important;
	}

	.interfere_logo {
		display: none;
	}

	.interfer_contents {
		width: 100%;
	}

	.interfer_parag2 {
		width: 80%;
		margin: auto;
		margin-bottom: 1.5rem;
	}

	.tvc_press_title {
		text-align: center;
	}

	.tvc_press_btn {
		margin-bottom: 1rem;
	}

	.tvc_first_row_img {
		justify-content: center;
	}

}

@media (max-width:330px) {
	.quote-img {
		height: 275px;
	}

	.gs-name {
		display: none;
	}

	.carousel_img {
		width: 70px;
	}

	.carousel_item_aboutus {
		height: 230px;
	}

	.bottom_div_aboutus {
		width: 75%;
	}

	.buttons_tvc_press {
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		justify-content: center;
		margin-top: 3rem;
		margin-left: -1rem;
	}
}

/* Arabic version css */
.mission_container_arabic,
.interfer_contents_arabic {
	margin-right: 3rem;
}

.content_parag_arabic {
	margin-right: 0.5rem;
	font-size: 1.1rem !important;
}

.mission_content_title_arabic {
	font-size: 3rem !important;
	font-weight: bold;
}

.mission_subtitle_arabic {
	font-size: 2rem !important;
}

.mission_content_arabic {
	font-size: 1.5rem !important;
}

.interfer_parag_arabic {
	font-size: 2rem !important;
}

.carousell_title_arabic {
	font-size: 3rem !important;
	font-weight: bold;
}

.title_1_carousell_arabic {
	font-size: 2rem !important;
}

.bottom_div_aboutus_arabic {
	width: 55%;
	font-size: 1.5rem;
}

.tvc_press_title_arabic {
	font-size: 3rem;
	font-weight: bold;
}