.apply_step3 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.apply_step3 .apply_img {
    width: 160px;
}

.apply_step3 .text_field_getintouch {
    margin-top: 0
}

.apply_step3 .dotter_spacer {
    display: block;
    border-bottom: dotted 5px var(--grey);
    margin: 1rem 0;
    width: 100%
}

.app_step3_next_button,
.app_step3_apply_later_button {
    width: 50%;
    align-self: flex-end;
}

.app_step3_apply_later_button,
.app_step3_apply_later_button_resp {
    background-color: var(--pink) !important;
    margin-right: 20px;
    margin-left: 20px;
}

.apply_step3 .phone_number_input,
.apply_step3 .occupation_input,
.apply_step3 .fullName_input {
    background-color: white;
    border: 2px solid var(--border);
    border-radius: 10px;
    padding: 6px 15px;
    margin-bottom: 1rem;
    width: 50%;
}

.apply_step3 .occupation_input {
    padding: 0px 0px !important;
}

.phone_number_input:hover,
.fullName_input:hover {
    border: 2px solid var(--secondary) !important;
}

.apply_step3 .css-1s2u09g-control,
.apply_step3 .css-1pahdxg-control {
    border: 0px solid var(--secondary) !important;
}

.apply_step3 .css-1s2u09g-control:hover,
.apply_step3 .css-1pahdxg-control:hover {
    border: 0px solid var(--secondary) !important;
}

.phone_number_input ::placeholder,
.occupation_input ::placeholder,
.fullName_input ::placeholder {
    color: var(--border);
}

.apply_step3 .complete_step_footer_button {
    display: flex;
    justify-content: right;
    width: 100%;
}

.apply_step3 .div_buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
}


.apply_primary_button {
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    padding: 7px 12px;
    border: none;
    outline: none;
    cursor: pointer;
}

.inputs_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.apply_step3 .react-tel-input .form-control {
    width: 50% !important;
    border: 2px solid var(--border) !important;
    border-radius: 10px;
}

.apply_step3 .react-tel-input .flag-dropdown {
    border: 2px solid var(--border);
    border-radius: 10px 0 0 10px;
}

.apply_step3 .text_field_getintouch {
    margin-bottom: 1rem;
}

.font-14 {
    font-size: var(--px-14) !important;
}

.margin-bottom {
    margin-bottom: 8px;
}

@media only screen and (max-width: 768px) {

    .app_step3_next_button_resp,
    .app_step3_apply_later_button {
        width: fit-content;
        display: flex;
        align-items: center;
        align-self: flex-end;
    }

    .div_buttons {
        width: auto
    }
}

@media only screen and (max-width: 590px) {

    .apply_step3 .react-tel-input .form-control,
    .apply_step3 .occupation_input,
    .apply_step3 .fullName_input {
        width: 90% !important;
    }
}

@media only screen and (max-width: 644px) {
    .apply_step3 .div_buttons {
        width: 100%
    }
}

@media only screen and (max-width: 350px) {
    .apply_step3 .react-tel-input .flag-dropdown {
        height: 40px !important
    }
}