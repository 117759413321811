.apply_step4 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.apply_step4_sections {
    display: flex;
    flex-direction: row;
}

.apply_section1,
.apply_section2 {
    width: 100%
}

.apply_step4 .apply_img {
    width: 70px;
}

.apply_step4 .dotter_spacer {
    display: block;
    border-bottom: dotted 5px var(--grey);
    margin: 1rem 0;
    width: 100%
}

.app_step4_next_button,
.app_step4_apply_later_button {
    width: 50%;
    align-self: flex-end;
}

.app_step4_apply_later_button,
.app_step4_apply_later_button_resp {
    background-color: var(--pink) !important;
    margin-right: 20px;
    margin-left: 20px;
}


.apply_step4 .complete_step_footer_button {
    display: flex;
    justify-content: right;
    width: 100%;
}
.apply_step4 .div_buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
}




.apply_primary_button {
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    padding: 7px 12px;
    border: none;
    outline: none;
    cursor: pointer;
}

.inputs_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.apply_step4 .react-tel-input .form-control {
    width: 50% !important;
    border: 2px solid var(--border) !important;
    border-radius: 10px;
}

.apply_step4 .react-tel-input .flag-dropdown {
    border: 2px solid var(--border);
    border-radius: 10px 0 0 10px;
}

.apply_step4 .text_field_getintouch {
    margin-bottom: 1rem;
}

.group_name {
    margin-bottom: 20px;
    margin-top: 20px;
}

.green_color {
    color: var(--secondary)
}

.green-circle {
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 1px;
    display: flex;
    justify-content: center;
    margin-right: 8px;
}

.add_group_div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;
}

.add_group_inputs_div{
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}
.add_group_inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
}
.div_container{
    display: flex;
    flex-direction: row;
    width: 45%;
}

.apply_step4 .occupation_input,
.apply_step4 .fullName_input {
    background-color: white;
    border: 2px solid var(--border);
    border-radius: 10px;
    padding: 6px 15px;
    margin-bottom: 1rem;
    width: 90%;
}
.apply_step4 .occupation_input
{
    padding: 0px 0px !important;
}
.apply_step4 .css-1s2u09g-control,
.apply_step4 .css-1pahdxg-control {
  border: 0px solid var(--secondary) !important;
}

.apply_step4 .css-1s2u09g-control:hover,
.apply_step4 .css-1pahdxg-control:hover {
  border: 0px solid var(--secondary) !important;
}

.fullName_input:hover {
    border: 2px solid var(--secondary) !important;
}


.occupation_input ::placeholder,
.fullName_input ::placeholder {
    color: var(--border);
}
.block-div{
    display: none;
}
.out_of_div{
    margin-bottom: 8px;
}
@media only screen and (max-width: 768px) {

    .app_step4_next_button_resp,
    .app_step4_apply_later_button {
        width: fit-content;
        display: flex;
        align-items: center;
        align-self: flex-end;
    }

    .div_buttons {
        width: auto
    }
    .apply_step4_sections {
        display: flex;
        flex-direction: column;
    }
    .apply_section1, .apply_section2{
        width: 100%;
    }
    .div_container{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
}
@media only screen and (max-width: 644px) {
    .apply_step4 .div_buttons{
        width : 100%
    }
    }