.checkin_out_div {
    width: 75%;
    margin: auto;
    margin-top: 3rem;
}
.Main_check_div .upper_section_sub {
    display: flex;
    position: relative;
    line-height: 1;
}

.Main_check_div .notification-badge{
    top: -20px;
    right: -12px;
    height: 20px;
}