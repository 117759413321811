.inputs {
  margin-top: 1rem;
}
.inputs_inner {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.bottom_checkbox {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.bottom_checkbox input[type="checkbox"] {
  width: 25px;
  height: 25px;
  accent-color: var(--primary);
  border: 2px solid var(--primary);
  margin-inline: 0.5rem;

  cursor: pointer;
}

.main_card a {
  color: blue; /* Link color */
  text-decoration: none; /* Remove underline */
}

.main_card a:hover {
  color: red; /* Link color on hover */
  text-decoration: underline; /* Underline on hover */
}
