.opp_card_main_cont {
  width: 450px;
  background-color: white;
  padding: 0 35px 5px 35px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 10px;
  height: fit-content;
  margin-bottom: 2rem;
}

.opp_card_flags_cont {
  margin-top: -2px;
  margin-right: -20px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
}

.opp_card_flag_comp {
  height: 40px;
  height: auto;
  margin-right: 10px;
}

.ProgressBar {
  margin-top: 2rem;

}

.popicon {
  margin-left: 5px;
}

.ProgressBar_title {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ProgressBar_popUp_title {
  font-size: 2rem;
  font-family: var(--bold);
}

.Progresscont {
  display: flex;
  align-items: center;
}

.ProgressBar_popUp_body_color_text {
  margin-left: 1rem;
  font-family: var(--bold);
}

.ProgressBar_popUp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProgressBar_title {
  font-size: 1rem;

  font-family: bold;
  margin-bottom: 1rem;
}

.ProgressBar_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ProgressBardivs {
  background-color: var(--grey);
  height: 4px;
  width: 30px;
  border-radius: 8px;
}

.ProgressBardivs_color1 {

  border-radius: 50%;
  height: 15px;
  width: 15px;
}

.ProgressBardivs_color {

  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
}

.check {
  width: 30px;
}

.c1 {
  background-color: #FBF608;
}

.c2 {
  background-color: var(--primary);
}

.c3 {
  background-color: var(--primary);
}

.c4 {
  background-color: #01DEE6;
}

.c5 {
  background-color: #6B20FF;
}

.c6 {
  background-color: #FF6961;
}

.c7 {
  background-color: #C780E8;
}

.c8 {
  background-color: #9AD1A3;
}

.c9 {
  background-color: var(--dark-orange);
}

.opp_card_header_cont {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opp_card_header_image {
  width: auto;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.opp_card_header_info {
  margin-inline: 1rem;

}

.opp_card_header_title {
  font-size: 1rem;
  line-height: 1;
  font-family: bold;
}

.opp_card_save_btn_cont_cm {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row;

  width: 150px;
}

.opp_card_header_respond {
  color: var(--primary);
  font-family: regular;
  font-size: 0.8rem;
  margin-top: 10px;
}

.opp_card_info_cont {
  margin-top: 1.7rem;
}

.opp_card_info_cont {
  display: flex;
  flex-direction: column;
}


.opp_card_info_main,
.opp_card_info_main_div2 {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}

.opp_card_info_icon {
  color: var(--primary);
}

.opp_card_info_header {
  margin-left: 1rem;
  margin-right: 1.5rem;
}

.opp_card_info_title {
  font-size: 1rem;
  font-family: bold;
}

.opp_card_info_text {
  font-size: 1rem;
}


.opp_card_points {
  margin-top: 1.2rem;
}

.opp_card_points_title {
  font-family: bold;
  font-size: 1rem;
}

.opp_card_points_text {
  font-size: 1rem;
}

.button_full_width {
  width: 100% !important;
}

.opp_card_read_more {
  border: none;
  outline: none;
  background-color: var(--primary);
  color: white;
  width: 49%;
  margin: 1.2rem 0;
  border-radius: 10px;
  padding: 8px 0;
  cursor: pointer;
}

.opp_card_check_in_btn,
.opp_card_check_out_btn,
.opp_card_history_btn {
  border: none;
  outline: none;
  background-color: #fff;
  color: var(--primary);
  width: 49%;
  margin: 1.2rem 0;
  border-radius: 10px;
  border: 1px solid var(--primary);
  padding: 8px 0;
  cursor: pointer;
}

.opp_card_points {
  display: flex;
  flex-direction: row;
}

.points_value {
  display: flex;
  flex-direction: column;
}

.circle_shape {
  width: 30px;
  height: 25px;
  border-radius: 30px;
  margin-top: 30px;
  background-color: var(--primary);
}


/* YourComponent.css */

.circle-more-icon {
  font-size: 3rem;
  /* Adjust the font size as needed */
  color: var(--primary);
  /* Adjust the color as needed */
  cursor: pointer;
  /* Add other styles as needed */
}


/* YourComponent.css */

.pause-circle-icon,
.refresh-icon {
  font-size: 2rem;
  /* Adjust the font size as needed */
  color: var(--primary);
  /* Adjust the color as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
  border-radius: 50%;
  border: 2px solid var(--primary);
  /* Border color matching the icon color */
  cursor: pointer;
  /* Add other styles as needed */
}



.Pending-circle_shape {
  background-color: #FBF608;
}

.History-circle_shape {
  background-color: #9AD1A3;
}

.Checked-in-circle_shape {
  background-color: #01DEE6;
}

.Pending-Last-Checked-out-circle_shape,
.Pending-Checked-out-circle_shape {
  background-color: #C780E8;
}

.Checked-out-circle_shape {
  background-color: #FF6961;
}

.Evaluated-circle_shape {
  background-color: var(--dark-orange);
}

.Pending-Checked-in-circle_shape {
  background-color: #6B20FF;
  border: 1px solid #6B20FF;
}

.opp_status {
  padding: 15px;
  padding-top: 30px;
}

.readMore_container {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
}

.width-50 {
  width: 50%
}

.checkout_header,
.checkin_header {
  margin: 10px 20px;
}

.checkout_header_title1,
.checkin_header_title {
  font-size: 1.5rem;
  font-family: bold;
}

.evaluation_popup_title {
  font-size: 1rem;
  font-family: bold;
  text-align: center;
}

.checkin_popup_body {
  text-align: center;
}

.checkin_header_title {
  text-align: center;
}


.checkout_header_title2 {
  font-size: 1rem;
  margin-top: 8px;
}

.checkout_popup_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.last_checkout,
.not_last_checkout,
.checkin_ok {
  border: none;
  outline: none;
  background-color: var(--primary);
  color: #fff;
  width: 45%;
  margin: 1.2rem 11px;
  border-radius: 10px;
  padding: 8px 0;
  cursor: pointer;
}

.not_last_checkout {
  background-color: var(--pink);
}


.opp_card_check_out_btn:hover {
  color: var(--pink);
  border-color: var(--pink);
}


.opp_card_read_more:hover {
  background-color: var(--pink);
}

.opp_card_check_out_btn_disabled,
.opp_card_check_out_btn_disabled:hover {
  opacity: 0.6;
  color: #858585;
  border-color: #858585;
  cursor: default;
}

.opp_card_info_main_div_item {
  display: flex;
  flex-direction: row;
}

@media (max-width: 770px) {
  .opp_card_info_main_div2 {
    flex-direction: column;
    align-items: start;
  }

  .opp_card_info_main_div_item {
    margin-bottom: 1.2rem;
  }

  .last_checkout,
  .not_last_checkout {
    width: 100%
  }

  .last_checkout,
  .not_last_checkout,
  .checkin_ok {
    width: 100%;
  }

}

@media (max-width: 598px) {
  .checkout_popup_body {
    flex-direction: column;
  }
}