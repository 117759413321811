.faqs-page {
   align-items: center;
   height: 100%;
}


.faqs-green-block {
   background-color: var(--primary);
   padding: 10px;
}

.faqs-green-block p {
   font-family: var(--bold);
   color: var(--white);
   font-size: var(--px-50);
   text-align: center;
   padding-top: 5%;
}

.faqs-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
}

.faqs-big-img {
   margin: auto;
   width: 100%;
   margin-top: 2rem;
   margin-bottom: 2rem;
   border-radius: 12px;
}

.mapping-box {
   padding-bottom: 3rem;
   width: 75%;
   margin: auto;
   margin-right: 1rem;
}

.mapping-box-arabic {
   padding-bottom: 3rem;
   margin-right: 20%;
}

.img_container_faqs {
   width: 75%;
   margin: auto
}

.arrow_icon {
   cursor: pointer;
   color: var(--pink);
}

.arrow-icon-rotate {
   transform: rotate(180deg);
   cursor: pointer;
   color: var(--pink);
}

.line {
   display: flex;
   margin: unset !important;
   justify-content: space-between;
   width: 75%;
   border-bottom: 2px dashed rgb(226, 224, 224);
}

.line p {
   margin: unset !important;
   font-size: 20px;
   font-weight: bold;
}

.faqs_answer_container_ar {
   margin-top: 1rem;
   font-size: 25px;
   width: 70%;
}

.line-arabic {
   display: flex;
   margin: unset !important;
   justify-content: space-between;
   width: 75%;
   border-bottom: 2px dashed rgb(226, 224, 224);
}

.line-arabic p {
   margin: unset !important;
   font-size: 30px;
   font-weight: bold;
}

.faqs_answer_container {
   width: 70%;
   margin-top: 1rem;
}


.arrow-hover {
   animation: rotation 1s linear;
}

@keyframes rotation {
   from {
      transform: rotate(0deg);
   }

   to {
      transform: rotate(360deg);
   }
}
