.complete_step1_main_body {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: space-between;
}

.complete_step1_body1 {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.css-14el2xx-placeholder {
  color: var(--border) !important;
}

.complete_profile_select {
  width: 30%;
  padding-bottom: 1rem;
}

.complete_profile_select .css-1s2u09g-control {
  background-color: white;
  border: 2px solid var(--border);
  border-radius: 10px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.css-1s2u09g-control:hover,
.css-1pahdxg-control:hover {
  border: 2px solid var(--secondary) !important;
}

.complete_profile_select .css-1pahdxg-control {
  background-color: white;
  border: 2px solid var(--border);
  border-radius: 10px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.complete_profile_select .css-tj5bde-Svg {
  color: var(--pink);
}

.css-26l3qy-menu {
  margin-top: -1rem !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.complete_step1_body1 .DatePicker {
  width: 30%;
}

.complete_step1_location {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.complete_step1_body4 {
  margin-bottom: 1rem;
}

.complete_step1_body3 {
  margin-top: 1rem;
}

.complete_input_header {
  font-family: bold;
  font-size: 1rem;
  margin-bottom: 0;
}

.complete_input_header2 {
  font-family: bold;
  font-size: 1rem;
  margin-bottom: 0.5;
}

.complete_input_sub_header {
  font-size: 0.9rem;
  width: 43%;
  margin-bottom: 1rem;
}

.complete_step1_footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: flex-end;
}

.complete_step_1_next_button {
  align-self: flex-end;
  width: 20%;
}

.complete_step1_body2 {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-left: 10px;
}

.complete_radio_comp {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* 
.complete_radio_btn{
  margin-right: -2rem;
  cursor: pointer;
}

.complete_radio_btn_ar{
  margin-left: -2rem;


} */

.complete_step1_body1 .react-tel-input .form-control {
  width: -webkit-fill-available !important;
  border: 2px solid var(--border) !important;
  border-radius: 10px;
}

.complete_step1_body1 .react-tel-input {
  width: 30% !important;
  margin-bottom: 1rem !important;
}

.complete_step1_body1 .react-tel-input .flag-dropdown {
  border: 2px solid var(--border);
  border-radius: 10px 0 0 10px;
}

@media only screen and (max-width: 768px) {
  .complete_step1_body1 .react-tel-input {
    width: 45% !important;
  }
}

@media only screen and (max-width: 590px) {
  .complete_step1_body1 .react-tel-input {
    width: auto !important;
  }

  .complete_step1_body1 .react-tel-input .flag-dropdown {
    height: 40px !important;
  }
  .complete_step1_body1 .react-tel-input {
    width: 95% !important;
  }
}
