.donate-page {
    margin: auto;
    width: 60%;
    padding: 50px 0px;
}

.green-block {
    background-color: var(--primary);
    padding: 10px;
}

.green-block p {
    font-family: var(--bold);
    color: var(--white);
    font-size: var(--px-50);
    text-align: center;
    padding-top: 5%;
}

.donate-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.donate-info {
    width: 42%;
    display: flex;
    flex-direction: column;
}

.donate-image {
    width: 50%
}

.donate-info-title {
    font-size: 25px;
    font-family: var(--bold);
    color: var(--secondary);
}

.donate-image img {
    width: -webkit-fill-available;
    height: 200px;
    border-radius: 10px;
    margin-top: 20px;
}


.horizontal_line {
    width: 100px;
    height: 0.3rem;
    background-color: #4f60b7;
    border-radius: 4rem;
    opacity: 1;
    border: none;
}

.horizontal_line_pink {
    background-color: var(--pink) !important
}

.donate_now_btn {
    background-color: var(--primary);
    color: white;
    border: none;
    outline: none;
    padding: 5px 15px;
    border-radius: 10px;
    font-family: regular;
    font-size: 1rem;
    cursor: pointer;
    width: max-content
}

.more-donations {
    background-color: rgb(44, 44, 44);
}

.more-donation-content {
    margin: auto;
    width: 60%;
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.more-title {
    font-size: var(--px-30);
    color: var(--white);
    font-family: var(--bold);
    margin-bottom: 20px;
}

.more-body {
    color: var(--white);
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.donate_img_1 {
    width: fit-content;
    height: 200px;
    margin-top: 30px;
}

.donate_more_about_section {
    width: 50%
}

.donate_img_section {
    width: 30%
}

.donate_info_container {
    margin: auto;
    width: 62%;
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
    gap:1rem
}

.left_donate_info {
    background: var(--primary);
    display: flex;
    flex-direction: column;
    color: var(--white);
    padding: 40px;
    border-radius: 10px;
}

.right_donate_info {
    background: var(--pink);
    display: flex;
    flex-direction: column;
    color: var(--white);
    padding: 40px;
    border-radius: 10px;
}

.left_donate_info>img,
.right_donate_info>img {
    height: 150px;
    margin-bottom: 20px;
    width: 200px;
}


.left_donate_info>.donate_div_info_list,
.right_donate_info>.donate_div_info_list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.donate_div_info_list>.donate_list_info_item {
    list-style: none;
    margin: 0;
    padding: 0;
}

.donate_list_info_title {
    font-family: var(--bold);
}

.left_donate_info>h4,
.right_donate_info>h4 {
    font-size: var(--px-25);
    font-family: var(--bold);
    color: var(--white);
}

@media only screen and (max-width: 770px) {
    .donate-container {
        flex-direction: column;
    }

    .donate-info,
    .donate-image,
    .donate_more_about_section,
    .donate_img_section {
        width: 100%
    }

    .more-donation-content {
        display: flex;
        flex-direction: column;
    }

    .donate_info_container{
        flex-direction: column;
    }
}