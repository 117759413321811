.Main_header_section {
  height: 90px;
  display: flex;
  justify-content: space-between;
  transition: all 0.5s;
  box-shadow: 0px 14px 16px -10px rgba(0, 0, 0, 0.15) !important;
  z-index: 1000000;
  padding: 0 4rem;
  position: fixed !important;
  background-color: white;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.logo_main_header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo_main_header_ar {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo_main_header_ar > img {
  height: 60%;
  margin: auto 0;
}

.logo_main_header > img {
  height: 60%;
  margin: auto 0;
}

.header_section2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.header_link_main {
  color: var(--secondary);
  font-family: var(--bold);
  display: flex;
  margin: 10px 30px;
  font-size: var(--px-14);
  position: relative;
  height: fit-content;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
}

.header_section2_1 {
  display: flex;
}

.titles_org {
  font-size: 1rem;
}

.org_login {
  display: none;
}

.header_drop_down_login {
  z-index: 10005;
  top: 107%;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 12px -3px rgb(0 0 0 / 50%) !important;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  padding: 10px 10px;
  right: 0%;
  border-radius: 5px;
}

.header_link_main_login {
  color: var(--secondary);
  font-family: var(--bold);
  display: flex;
  font-size: var(--px-14);
  position: relative;
  height: fit-content;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
}

.header_link {
  color: var(--secondary);
  font-family: var(--bold);
  display: flex;
  margin: 14px 10px;
  font-size: var(--px-14);
  position: relative;
  height: fit-content;
  line-height: 1;
  cursor: pointer;
}

.header_link_login {
  color: var(--secondary);
  font-family: var(--bold);
  display: flex;
  font-size: 1rem;
  position: relative;
  height: fit-content;
  line-height: 1;
  cursor: pointer;
}

.horizontal_rw_black_login {
  width: 100%;
  border-bottom: 1px solid black;
  border-radius: 10px;
}

.Main_header_section > .controls {
  width: 100%;
  height: fit-content;
  margin: auto;
}

.hedar_drop_down {
  z-index: 10005;
  top: 95%;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.5) !important;
  width: fit-content;
  white-space: nowrap;
  padding: 10px 10px;
  left: -5%;
  border-radius: 5px;
}

.hedar_drop_down p {
  padding-bottom: 5px;
  border-bottom: 2px solid white !important;
}

.hedar_drop_down a {
  text-decoration: none !important;
}

.hedar_drop_down p:hover {
  border-bottom: 2px solid #6ab1f0 !important;
  color: var(--primary) !important;
}

.header_drop_down_login p {
  padding-bottom: 5px;
  border-bottom: 2px solid white !important;
}

.header_drop_down_login a {
  text-decoration: none !important;
}

.header_drop_down_login p:hover {
  border-bottom: 2px solid #6ab1f0 !important;
  color: var(--primary) !important;
}

.header_link p {
  margin: 0 !important;
}

.header_link_main p:hover {
  color: var(--primary);
}

.active-link {
  color: var(--primary);
}

.login_button_header {
  display: flex;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  border-color: transparent;
  text-align: center;
  color: white;
  font-family: bold;
  margin: 0px 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login_button1_header {
  display: flex;
  height: 40px;
  border-radius: 10px;
  position: relative;
  font-size: var(--px-14);
  border-color: transparent;
  text-align: center;
  color: white;
  font-family: bold;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: 16px;
  margin-right: 6px;
  margin-left: 0.5rem;
}

.pink {
  background-color: var(--pink);
}

.green {
  background-color: var(--primary);
}

.language-selected {
  color: var(--secondary);
  font-size: var(--px-14);
  text-transform: uppercase;
  font-family: var(--bold);
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.active-lang {
  color: var(--pink);
  margin-right: 10px;
  margin-left: 10px;
}

.translation_section {
  display: flex;
  margin-left: 1rem;
}

.choose_language {
  margin-top: 1rem;
}

.language-selected {
  font-size: 1rem;
}

@media (min-width: 1260px) {
  .header_burger_menu {
    display: none;
  }
}

@media (max-width: 1260px) {
  .header_link_main,
  .translation_section {
    display: none;
  }

  .header_burger_menu {
    display: block;
    margin-left: 0.3rem;
  }
}

.arabic_titles {
  font-size: 1.4rem;
  font-weight: 300;
}

.translation_section_arabic {
  margin-right: 5rem !important;
}

.header_burger_menu_ar {
  margin-right: 0.3rem;
}

@media (max-width: 600px) {
  .login_button_header p {
    display: none;
  }

  .login_button1_header p {
    display: none;
  }

  .Main_header_section {
    padding: 0 3.5rem;
  }

  .main_header_arabic {
    position: sticky !important;
  }
}

@media (max-width: 360px) {
  .logo_main_header_ar {
    display: none;
  }
}

@media (max-width: 280px) {
  .Main_header_section {
    padding: 4px 10px 10px 30px;
  }
}

.header_link_main_login .notification {
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
  color: black !important;
  background-color: white !important;
  padding: 0px !important;
  margin-top: 0px !important;
}

.notification-badge {
  position: absolute;
  top: -4px;
  right: -3px;
  background-color: var(--pink);
  color: white;
  font-size: 12px;
  border-radius: 50%;
  padding: 3px 6px;
  display: flex;
  align-items: center;
}
