.upper_section_green_bg {
    padding-top: 8rem !important;
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;

}

.upper_section_title {
    font-family: bold;
    color: white;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 0.1rem !important;

}

.upper_section_sub {
    color: var(--grey);
    text-align: center;
    font-size: 1.1rem;
    height: fit-content;
    margin: 0 !important;
    cursor: pointer;
    font-weight: bold;

}

.upper_section_sub_4 {
    color: gray;
    text-align: center;
    font-size: 1.1rem;
    height: fit-content;
    margin: 0 !important;
    /* cursor: pointer; */

}

.upper_section_subs_main {
    color: white;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem !important;
    width: 71%;
}

.upper_section_subs_main_ar {
    color: white;
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 1rem !important;
    width: 71%;
}

.active_section_sub {
    color: white !important;
}

.upper_secrtion_sub_main {
    margin-top: 0.5rem !important;
    display: flex;
    height: fit-content;
    align-self: center;
}

.upper_section_green_bg .dotter_spacer {
    display: none
}

@media (max-width: 768px) {
    .upper_section_green_bg .dotter_spacer {
        display: block;
        border-bottom: dotted 5px var(--grey);
        margin: 1rem 0;
        width: 100%
    }
}