.apply_step5 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.apply_step5 .apply_img {
    width: 160px;
}

.user_type_options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85%
}

.user_type_option {
    display: flex;
    flex-direction: column;
}


.user_radio_comp {
    display: flex;
    align-items: center;
    gap: 0.5rem
}

input[type=radio].individual_radio,
input[type=radio].group_radio {
    height: 20px;
    width: 20px;
    accent-color: #858585;
}

.apply_step5 .dotter_spacer {
    display: block;
    border-bottom: dotted 5px var(--grey);
    margin: 1rem 0;
    width: 100%
}

.app_step0_next_button {
    width: 30%;
    align-self: flex-end;
}

.location_cont{
    display: flex;
    flex-direction: row;
}

.apply_step5 .css-1s2u09g-control:hover,.apply_step5 .css-1pahdxg-control:hover {
   border: 1px solid var(--secondary) !important
}

.apply_step5 .complete_step_footer_button {
    display: flex;
    justify-content: right;
    width: 100%;
}
.apply_step5 .div_buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
}
@media only screen and (max-width: 768px) {
    .app_step0_next_button_resp {
        width: fit-content;
        display: flex;
        align-items: center;
        align-self: flex-end;
    }

    .user_type_options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%
    }
}
@media only screen and (max-width: 644px) {
    .apply_step5 .div_buttons{
        width : 100%
    }
    }