.apply_step6 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.apply_step6 .apply_img {
    width: 160px;
}


.apply_step6 .dotter_spacer {
    display: block;
    border-bottom: dotted 5px var(--grey);
    margin: 1rem 0;
    width: 100%
}

.app_step6_next_button {
    width: 30%;
    align-self: flex-end;
}

.apply_step6 .complete_step_footer_button {
    display: flex;
    justify-content: right;
    width: 100%;
}
.apply_step6 .div_buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .app_step6_next_button_resp {
        width: fit-content;
        display: flex;
        align-items: center;
        align-self: flex-end;
    }

}
@media only screen and (max-width: 644px) {
    .apply_step6 .div_buttons{
        width : 100%
    }
    }