.goals_main_cont{
    width: 130px;
    border: 2px solid lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 1rem 0;
    cursor: pointer;
}

.goals_main_cont:hover>.divider,.goals_main_cont:hover{
  border-color: var(--primary);
}

.goals_img_cont{
width: 60%;
display: flex;
justify-content: center;
padding-bottom: 5px;
}
.goals_img_cont img{
width: 80%;
margin: auto;
}

.divider {
    border-bottom: 1px solid lightgray;
    width: 100%;
    margin: 0 !important;
  }

  .goals_name{
    
    font-size: 0.8rem;
    width: 90%;
    margin: auto;
    text-align: center;
padding-top: 5px;
  }

  .active_goals{
    border: 4px solid var(--primary);

  }

  .active_goals .divider{
    border-bottom:  2px solid var(--primary);


  }