.Main_terms_section {
    padding-top: 5rem;
    justify-content: center;

}

.top_band {
    background-color: var(--primary) !important;
    padding: 40px 0;
    display: flex;
    justify-content: center;
}

.terms_title {
    font-size: 3rem;
    color: white;
}

.number_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: var(--primary);
    border: 3px solid var(--primary);
    font-family: var(--bold);
    padding: 16px;
    margin-right: 8px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.parag_container_terms {
    display: flex;
    margin-top: 1rem;
    margin-left: 10rem;
}

.text_lines {
    padding-right: 0.5rem;
    padding-top: 0.3rem;
    font-weight: 700;
    font-size: 1.2rem;
}

.terms_container {
    margin: auto;
    margin-top: 5rem;

}

.parag_container_terms_2 {

    margin-top: 1rem;
    margin-left: 10rem;
}

.title_terms_8_cont {
    display: flex;
}

.subs_context {
    font-size: 1.3rem;
    margin-top: 15px;
    font-family: var(--bold);
}

@media (max-width:400px) {
    .parag_container_terms , .parag_container_terms_2{
        margin-left: 0rem;
    }

    .parag_container_terms_2 {
        margin-left: 0rem;
    }
}

.parag_container_terms_ar {
    margin-left: 0rem;
    margin-right: 10rem;
}

.terms_title_ar {
    font-size: 3.5rem;
    font-weight: bold;
}

.text_lines_ar {
    font-size: 1.5rem;
}

@media (max-width:670px) {
    .parag_container_terms , .parag_container_terms_2{
        margin-left: 0rem;
    }
    .terms_container {
        margin: 20px;
        margin-top: 5rem;
    
    }
}

@media (max-width:550px) {
    .parag_container_terms_ar {
        margin-right: 0rem;
    }

}