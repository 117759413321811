.Main_view_div {
    margin: auto;
    margin-top: 8rem;
    width: 70%;
}

.opp_view_vol_info_container {
    width: 100%;
    background-color: white;
    padding: 15px 25px 5px 25px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
    border-radius: 10px;
    height: fit-content;
    margin-bottom: 2rem;
}

.opp_view_vol_info_container_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    font-family: var(--bold);
    text-align: center;
}

.opp_view_vol_info_container_subtitle {
    text-align: center;
}

.opp_view_vol_info_container_subtitle_2 {
    margin-top: 10px;
    display: flex;
}

.opp_view_vol_info_container_Row_1 {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 2px solid var(--primary);
    padding: 25px;
    gap: 2rem
}

.opp_view_vol_info_container_Row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-between;
    padding: 25px;
    gap: 2rem
}

.opp_view_vol_info_container_Row_3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 25px;
    gap: 2rem
}

.opp_previous_cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    cursor: pointer;
}

.opp_opp_view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;

}

.opp_img_container {
    border-radius: 50%;
    border: 3px solid var(--primary);
    width: 55px;
    display: flex;
    align-items: center;
}

.opp_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: auto;
}

/* .opp_view_vol_info_container_col1 {
    width: 200px;
} */
.opp_view_vol_info_container_col3 {
    display: flex;
    flex-direction: column;

    align-items: center;
}

.view_icon {
    width: 40px;
    height: 40px;
}

.opp_view_vol_info_container_col1{
    width: 200px;
}
.opp_conts_container {
    display: flex;
    flex-direction: row;
}

.opps_container {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.objective_container {
    margin-top: 1rem;
}

.objective_title {
    font-family: var(--bold);
    color: var(--pink);
    font-size: 1.2rem;
}

.objective_desc {
    margin-top: 10px;
}

.objective_desc_1 {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.opp_desc {
    color: var(--primary);
}

.opp_name {
    font-family: var(--bold);
    font-size: 1.3rem;

}

.opp_info {
    margin-left: 1rem;
}

.Previous_opp_section {
    margin-top: 4rem;

}

.opps_title {
    font-family: var(--bold);
    font-size: 1.3rem;
    color: var(--primary);
}

.opps_container_row {
    width: 300px;
    background-color: white;
    padding: 15px 25px 15px 25px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
    border-radius: 10px;
    height: fit-content;
    margin-bottom: 2rem;
}

@media (max-width: 750px) {
    .opp_view_vol_info_container_col1 {
        width: 100px;
    }


}

@media (max-width: 500px) {
    .opp_view_vol_info_container_col1 {
        width: 100%;

    }


}
@media (max-width: 446px) {
    .opps_container_row {
        width: 100%;

    }
    .opp_conts_container {
        display: flex;
        flex-direction: column;
    }
    .opportunities_card_home{
        padding: 0px 20px;
    }

}