.filters_main_cont {
  background-color: white;
  padding: 1.5rem 1.5rem;
  height: fit-content;
  width: 100%;
  box-shadow: 10px 0px 18px -3px rgba(114, 192, 149, 0.35) !important;
  transition: height 0.5s ease-in;
}

.filter_title {
  font-family: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.filter_main_icon {
  margin-inline: 0.5rem;
  color: var(--pink);
}

.filters_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters_clear {
  color: var(--primary);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filter_select_date {
  border: 2px solid var(--pink);
  background-color: transparent;
  padding: 0.3rem 0.7rem;
  border-radius: 20px;
  cursor: pointer;
}
.filter_select_date:hover {
  background-color: var(--pink);
  color: white;
}
.filters_close {
  display: none;
}
.filter_clear_icon {
  color: var(--primary);
  margin-right: 0.2rem;
}

.filers_main_underline {
  margin-top: 1rem;
  width: 60px;
  border-bottom: 5px solid var(--pink);
  border-radius: 20px;
}
.datepicker_cont {
  position: absolute !important;
  left: 90px !important;
  top: -80px !important;
}

.filters_container {
  margin-top: 2rem;
}

.filter_sub_cont {
  border-bottom: 2px solid var(--grey);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.rotate_arrow {
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;
}
.filter_sub_cont_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.filter_sub_cont_title {
  font-family: bold;
  font-size: 1rem;
}

.filter_icon_arrow {
  color: var(--pink);
  transition: all 0.6s ease-in-out;
}

.filter_list_check {
  display: flex;
  align-items: center;

  padding: 0 0.2rem;
}

.filter_list_comp {
  font-size: 0.9rem;
}

.filter_list_check input[type="checkbox"] {
  accent-color: var(--primary);
  cursor: pointer;
  margin-inline: 0.5rem;
  scale: 120%;
}

.filter_radio {
  margin-top: -3px;
}

.filter_list_check input[type="radio"] {
  cursor: pointer;

  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  margin-inline: 0.5rem;
}

.filter_list_check input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--primary);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
