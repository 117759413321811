.contact-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-container form input,
.contact-container form select,
.contact-container form textarea {
  width: 40%;
  border-radius: 8px;
  border: 0.125rem solid var(--primary);
  outline: var(--pink);
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
}

.contact-container form input:hover,
.contact-container form select:hover,
.contact-container form textarea:hover {
  border: 0.125rem solid var(--pink);
}

.contact-container form input::placeholder,
.contact-container form select::placeholder,
.contact-container form textarea::placeholder {
  color: var(--dark-grey);
}

.contact-container form select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  direction: ltr;
}

.contact-container form button {
  width: 40%;
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 0.5rem 0;
  border-radius: 12px;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.title-2 {
  margin-top: 5rem;
  font-family: bold;
  font-weight: strong;
  font-size: 2rem;
  text-align: center;
}

.pink-hr2 {
  width: 6rem;
  background-color: var(--pink);
  height: 6px;
  border-radius: 8px;
  border: none;
  margin-bottom: 2rem;
}



@media only Screen and (max-width: 768px) {

  .contact-container form input,
  .contact-container form select,
  .contact-container form textarea {
    width: 85%;
  }
}

.disabled_btn {
  background-color: grey !important;
}

.contact-error {
  color: var(--pink);
  text-align: center;
}

.contact-response {
  color: var(--primary);
  text-align: center;
}