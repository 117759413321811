.not_found_image {
  width:300px;
  max-width: 95%;

  padding-top: 8rem;
}


.not_found_div{

    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;

}