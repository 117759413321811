.master_list_check_cont {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.green_box {
  background-color: var(--primary) !important;
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.length_span {
  font-size: var(--px-12) !important;

  font-family: "regular" !important;
}
.quizz_cont {
  display: flex;
  width: 60%;
  margin: auto;
  margin-top: 10px;
  flex-direction: column;
}

 input.master_checkbox[type="checkbox"] {
    width: 25px;
    height: 25px;
    accent-color: var(--primary);
    border: 2px solid var(--primary);
    margin-right: 0.5rem;
    cursor: pointer;
}

.trans_box {
  background-color: transparent;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid var(--primary);
}

.line_trial {
  height: 30px;
  border-left: 2px solid var(--primary);
  margin-left: 10px;
  margin-top: -2px;
  margin-bottom: -2px;
}

.play_icon {
  width: 100px;
  height: 100px;
}

.master_close,
.master_title_resp {
  display: none;
}

.masterclass_body_second {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 900px) {
  .masterclass_body_left_div {
    position: absolute;
    max-width: 100%;
  }

  .masterclass_body_second {
    width: 90%;
    margin: auto;
    margin-top: 5rem;
  }

  .master_title_resp {
    font-family: bold;
    font-size: 1.2rem;
    align-items: center;
    display: block;
    padding: 15px;
    position: absolute;
  }

  .master_close {
    position: absolute;
    display: block;
    color: var(--primary);
    display: flex;
    align-items: center;
    cursor: pointer;
    right: 0.5rem;
    top: 8px !important;
  }
}

.react-player__preview {
  border-radius: 15px;
}

.about_master_class {
  margin-top: 5rem;
}

.about_master_header {
  font-size: 2rem;
  font-family: bold;
}

.about_master_underline {
  width: 100px;
  border-bottom: 5px solid var(--pink);
  border-radius: 5px;
}

.about_master_underline_green {
  width: 100px;
  border-bottom: 5px solid var(--primary);
  border-radius: 5px;
}

.about_master_info,
.about_master_train_info {
  margin-top: 1.5rem;
  font-size: 1rem;
  font-family: regular;
}



.master_train_div {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}

.master_train_image {
  width: 400px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.master_train_image_div {
  position: relative;
}

.master_train_info {
  bottom: 12px;
  color: white;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  width: 400px;
  padding: 10px 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.master_finish {
  color: var(--primary);
  text-align: initial;
  font-size: 1rem;
}

.trainer_link {
  text-decoration: none;
  color: white;
  cursor: pointer;
  margin-top: 0.5rem;
}

.trainer_link:hover {
  color: var(--pink);
}
