.sub_card_div {
    /* background-color: var(--pink); */
    border-radius: 20px;
    border :1px solid var(--dark-grey); 
    cursor: pointer;


}

.left_sub_card_div {
    cursor: pointer;


}
.main_sub_card_div{
    border :1px solid var(--dark-grey); 
    margin-top: 140px;
}

.second_main_sub_card_div{
    border-radius: 20px 0px 0px 20px;
}

.Main_sub_card_div{
    max-width: 500px;
}
.Main_sub_card_div .button {
    width: 30%;
    height: 40px;
    border-radius: 10px;
    position: relative;
    font-size: var(--px-14);
    border-color: transparent;
    text-align: center;
    color: var(--white) !important;
    border: 1px solid transparent !important;
    background-color: var(--primary) !important;
    margin-top: 20px;
    cursor: pointer;
}
.Main_sub_card_div .button:hover {
    background-color: var(--primary);
    color: white;
  }

.sub_card {
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height:140px;
    border-radius: 18px 18px 0 0;
}

.left_sub_card {
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 18px 18px 0 0;
}

.sub_card_content {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.second_sub_card_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.sub_card_price {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: initial;

}

.sub_card_price_price {
    font-family: var(--bold);
    color: var(--white);
    text-align: initial;
}

.sub_card_price_price input[type="radio"]{
    top:0px !important
}
.sub_card_price_text {
    font-size: 1rem;
    font-family: var(--bold);
    color: var(--white);

}

.sub_card_prices {
    padding: 20px;
}

.sub_card_title {
    font-size: 22px;
    color: var(--white);
    text-align: center;
    font-family: bold;
}

.sub_card_subtitle {
    font-size: 13px;
    color: var(--white);
    text-align: center;
}
.sub_card_heading {
    font-size: 20px;
    text-align: initial;
    font-family: bold;
}

.content_sub {
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 65px;
    align-items: center;
}
.currenry_style{
    font-size: 12px;
    color: #888;
    text-transform: capitalize;
    font-family: 'regular' !important
}
.Main_sub_card_div_cards .content_sub{
    justify-content: center;
}

.content_sub_text {
    font-size: 16px;
    font-family: var(--regular);
    color: #000;
    text-align: initial;
}


.subscription_card_header{
    font-family: bold;
}
.subscription_card_body{
    margin-top: 2rem;
}

.subscription_card_name{
    font-family: bold;
}

.subscribe_btn{
    margin-top: 10px;
}
/* .check_mark {
    color: var(--primary);

} */
.sub_card .footer-button-form{
    justify-content: center !important;
}

.sub_card_div:hover{
    scale: 1.05;
  }
@media screen and (max-width: 900px) {
    .subscription_cards_container{
        flex-direction: column;
    }
}
@media screen and (max-width: 465px) {
    .Main_sub_card_div .button {
        width: 100%;
    }
    .sub_card_div {
        width: 100%;
        height: auto;
    }
    .left_sub_card_div {
        width: 100%;
        height: auto;
    }

    .sub_card_content {
        width: 100%;
    }
    .second_sub_card_content{
        width: 100%;
    }
    .content_sub_text{
        font-size: 10px;
    }
}