

.blog_detail_info{
    width: 75%;
    margin: auto;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.blog_detail_img{

    height: 300px;
    object-fit: cover;
    border-radius: 10px;
}

.blog_detail_header{
    font-family: bold;
}

.blog_detail_by{
    margin-top: -45px;
    color: darkgray;
    font-family: bold;
    
}

.blog_detail_share{
    font-family: bold;
    display: flex;
    align-items: center;
    cursor: pointer;

}
.opp_detail_share_btn_cont{
    width: fit-content;
}
.blog_detail_share:hover{
    color: var(--primary);
}