.subscription_content {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
}

.subscription_headings {
  font-size: 1.3rem;
  font-family: var(--bold);
}

.subscription_span {
  color: var(--primary);
}

.underline_subs {
  width: 100px;
  height: 10px;
  border-radius: 8px;
  margin-top: 1rem;
  background-color: var(--pink);
}

.subscription_cards_container {
  margin: auto;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  width: 95%;
}

.subs_cont {
  margin-right: 10px;
}

.sub_card_content_master .content_sub_text {
  color: black;
  padding: 12px;
}




.Main_sub_card_div_cards .second_content_sub{
  justify-content: center;
}
.second_content_sub {
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 65px;
  align-items: center;
}
/* Styles for the TwoPartButton component */
.two-part-button {
  display: flex;
  justify-content: space-between;
}

.second_content_sub{
  border-bottom: unset !important;
}
.two-part-button button {
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  /* Blue background color */
  color: #fff;
  /* White text color */
  transition: background-color 0.3s ease;
}

.two-part-button button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}

.buttons_div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 30px;
}

.buttons_div span {
  cursor: pointer;
}

.inactive_switch_btn {
  color: #888;
}

.active_switch_btn {
  color: #000;
}

.left_side_btn {
  line-height: 1.2 !important;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0em;
  border-radius: 30px;
  padding: 0.8em 1.8em;
  background: #203f99;
  border-color: transparent;
  color: #ffffff !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 0.8rem;
}

.left_side_btn:hover,
.right_side_btn:hover {
  box-shadow: 0 0em 0em rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-color: #203f99;
  color: #203f99 !important;
}

.right_side_btn {
  line-height: 1.2 !important;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0em;
  border-radius: 30px;
  padding: 0.8em 1.8em;
  background: #3677be;
  border-color: #3677bd;
  color: #ffffff !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 0.8rem;
}

.notification-container {
  top: 90px !important;
  width: auto !important;
  max-width: 400px !important;
}

.sub_card_div .unchecked-switch .react-switch-bg {
  background: rgb(136, 136, 136) !important;
  border: 1px solid #000 !important
}

.left_sub_card_div .unchecked-switch .react-switch-bg {
  background: rgb(136, 136, 136) !important;
  border: 1px solid #000 !important
}

.subscription_bottom_container {
  border-radius: 20px;
  border: 1px solid var(--dark-grey);
  margin-top: 140px;

  margin: auto;
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  width: 95%;
}