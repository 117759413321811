
@media only screen and (min-width: 700px) {

.create_opp_step5_header .datepicker_cont{
    position: absolute !important;
    left: 90px !important;
    top:-120px !important;
    
}

.datepicker_cont2{
    position: absolute !important;
    left: 90px !important;
    top:-175px !important;
}

}
.create_opp_step5_main_cont{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 350px;
    gap:2rem

}
.create_opp_step5_main_cont .create_opp_step1_cont  .create_opp_input{
        width: 75%;
    }