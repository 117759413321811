.title_mtt{
    color: var(--primary);
    margin: auto;
    margin-top: 4rem;
    white-space: nowrap;
}
.team_name{
    margin: auto;
    font-family: bold;
    font-size: 1.5rem;
    white-space: nowrap;
}

.team_name2{
    margin-bottom: -2rem;
}
.team_photo{
    margin: auto;
    width: 250px;
    margin-top: 2rem;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
}

.team_logo{
margin: auto;
    color: var(--pink);
    margin-top: 2rem;
    cursor: pointer;
}
.Main_meettheteam_section{
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    width: 300px;
}
.team_content{
    margin: auto;
    font-size: 14px;
    color: #2C2C2C;
    text-align: center;
    width: 80%;
    height: 180px;
    margin-top: 2rem;
}
 
/* Container for the flip effect */
.flip-container {
    perspective: 1000px; /* Set perspective to create 3D effect */
    width: 200px; /* Adjust the width and height as needed */
    height: 250px;
  }
  
  /* Wrapper for the front and back of the card */
  .flipper {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d; /* Enable 3D transformation */
    transition: transform 0.5s; /* Add a transition for smooth flipping */
  }
  
  /* Style for the front of the card */
  .front {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden; /* Hide the backface of the element */
  }
  
  /* Style for the back of the card */
  .back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden; /* Hide the backface of the element */
    transform: rotateY(180deg); /* Initial state: flipped */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; 
  }
  
  /* Apply the flip effect on hover */
  .flip-container:hover .flipper {
    transform: rotateY(180deg); /* Rotate the card to reveal the back */
  }
  